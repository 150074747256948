import React, { Fragment, useState } from 'react';
import NGLogo from '../../assets/img/logo/NGLogo.png';

import { Popover, Transition } from '@headlessui/react';
import { useUserProfile } from 'profiles/queries/profileHooks';
import { logOut } from 'authentication/services/authentication';
import signOut from '../../assets/img/log-out.svg';
import user from '../../assets/img/user.svg';
import settingsIcon from '../../assets/img/settings.svg';
import { useHistory } from 'react-router-dom';
// import { isKhelo } from '../../utils/kheloConditions';

export default function Navbar() {
  const history = useHistory();

  const [close2, setClose2] = useState(false);
  const [hovered, setHovered] = useState(false);
  const userProfileQuery = useUserProfile();
  // const isKhelo = useShowKhelo();

  const userRole = userProfileQuery?.data?.message.primary_role;
  const userImage = userProfileQuery?.data?.message.image;
  return (
    <div className={`px-4 py-3 bg-blue-950 shadow-shadowBottom`}>
      <div className="max-w-fhd mx-auto w-full h-10 flex justify-between items-center">
        <img src={NGLogo} alt={'NG Logo'} />
        {userProfileQuery.isSuccess ? (
          <>
            <Popover className="relative mr-3.5">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={`
              ${open ? '' : 'text-opacity-90'}
              text-white group bg-orange-700 px-2 py-1.5 rounded-md inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span
                      data-cy="user_icon"
                      onClick={
                        open
                          ? () => {
                              close2 ? setClose2(false) : setClose2(true);
                            }
                          : () => setClose2(false)
                      }
                    >
                      <div
                        src={userImage}
                        alt="icon"
                        style={{
                          borderRadius: '50%',

                          background: `url('${userImage}')`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                        }}
                        className="w-7 h-7 md:w-8 md:h-8 lg:w-10 lg:h-10"
                      />
                    </span>
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel
                      className={`absolute z-10 w-60 lg:w-72 px-4 transform -translate-x-1/2 -left-8 md:-left-20 lg:-left-24 sm:px-0 lg:max-w-3xl ${
                        close2 ? 'hidden' : 'block'
                      }`}
                    >
                      <div className="overflow-hidden rounded-lg shadow-lg">
                        <div
                          className="relative flex-col gap-8 bg-white p-4"
                          onClick={() => setClose2(true)}
                        >
                          <div
                            onClick={() => history.push(`/${userRole}/profiles`)}
                            className={`cursor-pointer flex items-center transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                          >
                            <div className="flex items-center  justify-center flex-shrink-0 w-10 h-10 text-white ">
                              <img
                                src={user}
                                className="mt-1"
                                alt="user"
                                height="16px"
                                width="16px"
                              />
                            </div>
                            <div className="ml-4">
                              <p
                                data-cy="my_profile"
                                className="text-sm mt-1 font-medium text-gray-900"
                              >
                                My Profile
                              </p>
                            </div>
                          </div>
                          <div
                            onClick={() => history.push(`/${userRole}/profiles/account-details`)}
                            className={`flex items-center cursor-pointer transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                          >
                            <div className="flex items-center  justify-center flex-shrink-0 w-10 h-10 text-white ">
                              <img
                                src={settingsIcon}
                                className="mt-1"
                                alt="logout"
                                height="16px"
                                width="16px"
                              />
                            </div>
                            <div className="ml-4">
                              <p className="text-sm mt-1 font-medium text-gray-900">My Account</p>
                            </div>
                          </div>
                          <div
                            onClick={() => logOut(userProfileQuery?.data)}
                            className={`cursor-pointer flex items-center transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                          >
                            <div className="flex items-center  justify-center flex-shrink-0 w-10 h-10 text-white ">
                              <img
                                src={signOut}
                                className="mt-1"
                                alt="logout"
                                height="16px"
                                width="16px"
                              />
                            </div>
                            <div className="ml-4">
                              <p className="text-sm mt-1 font-medium text-gray-900">Logout</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </>
        ) : (
          <div
            onMouseLeave={() => setHovered(false)}
            onMouseEnter={() => setHovered(true)}
            onClick={() => history.push('/signin')}
            className={`px-3 py-3 cursor-pointer text-white underline`}
          >
            {/* {console.log(hovered)} */}
            <div>
              <p className="text-sm font-medium">Sign In</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
