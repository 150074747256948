import React from 'react';
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.

    return { hasError: true };
  }

  componentDidCatch(_error, _errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <this.props.fallbackRender></this.props.fallbackRender>;
    }

    return this.props.children;
  }
}
