import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KheloHeader from 'micro-site/Components/KheloHeader/KheloHeader';
import { useParams, useHistory } from 'react-router-dom';

const ExternalEmbed = () => {
  const params = useParams();
  const history = useHistory();
  return (
    <>
      <KheloHeader></KheloHeader>
      <a
        onClick={(e) => {
          e.preventDefault();
          history.goBack();
        }}
      >
        <div className="flex items-center cursor-pointer p-2">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="fa-xs text-gray-750 mr-3"
          ></FontAwesomeIcon>
          <p className="text-gray-750 font-bold">Go Back</p>
        </div>
      </a>
      <iframe src={params?.[0]} className="min-h-screen w-full"></iframe>
    </>
  );
};

export default ExternalEmbed;
