import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { useRef } from 'react';
import { useOutsideClick } from 'utils/HooksUtils';
import ShowMedia from './showMedia';

const Modal = ({ setModalClose, data }) => {
  const divRef = useRef(null);
  useOutsideClick(divRef, () => setModalClose(false));

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center z-20 bg-gray-450 backdrop-blur-2xl backdrop-brightness-150 ">
      <div
        className=" w-full max-h-90vh md:max-w-2/3 relative flex items-center justify-center"
        style={{ aspectRatio: '16/9' }}
      >
        <ShowMedia
          mediaData={data}
          className={'max-h-90vh object-contain border-5 border-white'}
          divRef={divRef}
        />
      </div>
    </div>
  );
};

export default Modal;
