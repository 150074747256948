import React from 'react';
import { render, hydrate } from 'react-dom';
import './index.css';
import 'lightgallery/css/lg-video.css';
import 'lightgallery/css/lightgallery.css';
import App from './App';
import { gtmInitialize } from 'utils/gtmConfigUtils';

const rootElement = document.getElementById('root');

// check to run gtm only on production
if (
  window.location.origin.replace('https://', '') === 'kiygharyana.in' ||
  window.location.origin.includes('khelo-dev.netlify.app')
)
  gtmInitialize();
window.onload = function () {
  // check again /public /score for sensitive urls like auto login
  if (
    window.location.pathname?.toLowerCase().includes('/public/score') &&
    window.location.pathname !== window.location.pathname.toLowerCase()
  )
    window.location.href = window.location.href.toLowerCase();
};
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
