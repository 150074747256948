import { useParams } from 'react-router-dom';
import { BuilderComponent } from '@builder.io/react';
import Loader from 'components/Loader';

export default function Embed({ customRoute }) {
  const params = useParams();
  return (
    <div className=" h-full w-full overflow-hidden">
      <BuilderComponent
        apiKey={process.env.REACT_APP_BUILDER_IO_API_KEY}
        entry={customRoute || params?.builderId}
        model="page"
      >
        <Loader />
      </BuilderComponent>
    </div>
  );
}
