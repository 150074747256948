import React from 'react';
import SelectList from 'components/select-list';
import SelectDate from '../select-date';

export default function index({ filters, fromProfileEvents, classes }) {
  const lastElement = filters.length;
  return (
    <div className="flex w-full md:w-auto">
      {filters.map((el, idx) => {        
        return el.type === 'dropdown' ? (
          <div className="w-full md:w-auto" key={idx}>
            <div className={`${fromProfileEvents ? 'text-gray-750' : 'text-gray-550'} text-sm`}>
              {el.title}
            </div>
            <SelectList
              fromProfileEvents={fromProfileEvents}
              selectedValue={el.selectedValue}
              setSelectedValue={el.setSelectedValue}
              placeholder={el.placeholder}
              listData={el.listData}
              hideBorder={true}
              key={idx}
              id={idx}
              lastElement={lastElement - 1}
              dataCy={el.dataCy}
              minWidth="90px"
            />
          </div>
        ) : (
          el.type === 'date' && (
            <SelectDate
              key={idx}
              id={idx}
              selectedValue={el.selectedValue}
              setSelectedValue={el.setSelectedValue}
              placeholder={el.placeholder}
              hideBorder={true}
              lastElement={lastElement - 1}
              dataCy={el.dataCy}
              minWidth="100px"
            />
          )
        );
      })}
    </div>
  );
}
