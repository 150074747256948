// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { useRef } from 'react';
import { useOutsideClick } from 'utils/HooksUtils';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const Modal = ({ setModalClose, link, type }) => {
  /*
  const initialSettings = {
    slidesToShow: 1,
    arrows: false,
    centerMode: false,
    className: 'center',
  };
*/
  // const sliderRef = useRef(null);
  const divRef = useRef(null);
  useOutsideClick(divRef, () => setModalClose(false));

  return (
    <div className="fixed max-h-screen inset-0 flex flex-col items-center justify-center z-20 bg-gray-450 backdrop-blur-2xl backdrop-brightness-150 ">
      <div
        className=" w-full max-h-2/3 md:w-2/3 relative"
        ref={divRef}
        style={{ aspectRatio: '16/9' }}
      >
        <div className="w-full h-full flex justify-center border-5 border-white bg-white">
          {/*
          <img
            src={Close}
            className="cursor-pointer absolute right-0 top-0"
            onClick={() => setModalClose(false)}
          />
*/}

          {/*
        <button
          onClick={() => {
            sliderRef.current && sliderRef.current.slickNext();
          }}
          className="inline-block absolute bg-white rounded-full h-8 w-8 shadow-lg z-10 transform -translate-y-1/2  top-1/2 cursor-pointer right-0"
        >
          <FontAwesomeIcon icon={faAngleRight} className="text-gray-450" />
        </button>
        <button
          onClick={() => {
            sliderRef.current && sliderRef.current.slickPrev();
          }}
          className=" inline-block  absolute left-4 bg-white rounded-full h-8 w-8 shadow-lg z-10 top-1/2 cursor-pointer transform -translate-y-1/2 -translate-x-1/2"
        >
          <FontAwesomeIcon icon={faAngleLeft} className="text-gray-450" />
        </button>
*/}

          {type === 'Video(youtube)' ? (
            <iframe
              className={'w-full h-auto md:w-auto md:h-full'}
              style={{
                aspectRatio: '16/9',
              }}
              src={link}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          ) : type === 'Video(vimeo)' ? (
            <iframe
              src={link}
              className={'w-full h-auto md:w-auto md:h-full'}
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default Modal;
