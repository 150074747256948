import React, { useState } from 'react';
import { getDateInDateMonth } from 'constants/DateFunctions';
import videoIcon from '../../assets/img/video_icon.svg';
import Modal from './AttractionsModal';

export default function AttractionCard({ data }) {
  const [modal, setModalVisible] = useState(false);

  return (
    <>
      <div className="w-full relative shadow-shadowPopup rounded-2xl overflow-hidden">
        <div className="h-56 relative">
          <img src={data?.cover_image} className="h-full w-full" alt="cover" />
        </div>
        <div className="bg-white px-4 pt-4 pb-3 relative w-full bottom-0 rounded-2xl min-h-32 -top-4">
          <div className="relative">
            <div className="flex justify-between items-end absolute -top-16 w-full">
              <p className="text-2xl text-white font-bold font-poppins">
                {getDateInDateMonth(data?.date_time?.replace(/-/g, '/'))}
              </p>
              {/* <p className="text-xs md:text-sm text-white">
                {data?.date_time?.split(' ')[1].slice(0, 5)}
              </p> */}
            </div>
          </div>
          <h4 className="text-gray-750 text-sm font-bold mb-2">{data?.event_attraction_name}</h4>
          <p className="text-gray-750 text-xs">{data?.description}</p>
          <div className="flex items-center justify-between">
            <p className="text-gray-450 text-xs mt-2 mb-3">{data?.hashtag}</p>
            <div className="flex gap-2 justify-between items-center">
              {data?.video_link ? (
                <div className="flex items-center gap-2 cursor-pointer">
                  <img src={videoIcon} alt="" />
                  <div onClick={() => setModalVisible(true)} className="text-xs text-gray-650">
                    Watch Video
                  </div>
                </div>
              ) : null}

              {data?.schedule_link ? <span className="text-gray-650">|</span> : null}
              {data?.schedule_link ? (
                <a
                  href={data?.schedule_link || window.location.origin}
                  target="_blank"
                  rel="noreferrer"
                  className="text-xs text-gray-650"
                >
                  View Schedule
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {modal ? (
        <Modal
          type={checkType(data?.video_link)}
          link={
            !data?.video_link?.toLowerCase()?.includes('youtube') ||
            !data?.video_link?.toLowerCase()?.includes('vimeo')
              ? 'https://www.youtube.com/embed/6stlCkUDG_s'
              : data?.video_link
          }
          setModalClose={() => setModalVisible(false)}
          linkOnly
        />
      ) : null}
    </>
  );
}
function checkType(link) {
  if (link.toLowerCase().includes('vimeo')) return 'Video(vimeo)';
  return 'Video(youtube)';
}
