import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentForm: 0,
  next: true,
  previous: false,
  columns: [],
};

export const modal = createSlice({
  name: 'modal',
  initialState: initialState,
  reducers: {
    currentFormNumber: (state, _action) => {
      state.currentForm = state.currentForm - 1;
    },
    nextForm: (state, _action) => {
      state.currentForm = state.currentForm + 1;
      state.currentForm > 0 ? (state.previous = true) : (state.previous = false);
    },
    previousForm: (state, _action) => {
      if (state.currentForm > 0) state.currentForm = state.currentForm - 1;
      state.currentForm === 0 ? (state.previous = false) : (state.previous = true);
    },
    selectedSportEvents: (state, action) => {
      state.push(action.payload);
    },
    addNewSport: (state, action) => {
      state.columns.push({
        index: action.payload.index,
        key: action.payload.key,
        image: action.payload.img,
        sportName: action.payload.sportName,
        sportCategory: action.payload.sportCategory,
        gender: action.payload.gender,
        ageGroup: action.payload.ageGroup,
        eventName: action.payload.eventName,
      });
    },
    deleteSport: (state, action) => ({
      ...state,
      /*
      columns: [
        ...state.columns.slice(0, action.payload),
        ...state.columns.slice(action.payload + 1),
      ],
      */
      columns: state.columns.filter((item) => item.index !== action.payload),
    }),
  },
});

export const { nextForm, previousForm, currentFormNumber, addNewSport, deleteSport } =
  modal.actions;
