import { useBreakPoint } from 'utils/HooksUtils';
import Header from './Header';

const GeneralSection = ({
  backgroundImage,
  center,
  title,
  header,
  mobileBackgroundImage,
  description,
  link,
}) => {
  const isLg = useBreakPoint('lg');
  return (
    <a href={link} target="_blank" rel="noreferrer">
      <section
        id="general-section"
        style={{
          backgroundImage: `url('${isLg ? backgroundImage : mobileBackgroundImage}')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
        className={`min-h-30-screen p-4 ${center ? 'flex justify-center items-center' : ''}`}
      >
        {title ? (
          <div className="flex justify-center">
            <Header {...title} align="center" />
          </div>
        ) : null}
        {header ? <div className="text-4xl">{header}</div> : null}
        <div dangerouslySetInnerHTML={{ __html: description }}></div>
      </section>
    </a>
  );
};

export default GeneralSection;
