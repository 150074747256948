import React, { useState } from 'react';

export default function Tabs({
  tabList,
  color,
  border,
  callback,
  isDetailedScoring = false,
  mobileDetailedScoring = false,
}) {
  const [selected, setSelected] = useState(0);

  const setTabs = (idx) => {
    setSelected(idx);
    tabList[idx]?.callback && tabList[idx].callback();
  };

  return (
    <div>
      <div
        className={`flex overflow-x-scroll no-scrollbar xl:overflow-x-hidden lg:mt-0 lg:mx-0 font-roboto 
      justify-between cursor-pointer bg-white text-center xs:border-b-1 md:rounded ${
        !isDetailedScoring && 'md:border-1'
      }`}
      >
        {tabList.map((el, idx) => {
          return (
            <a
              key={idx}
              data-cy="tabs"
              className={`min-w-max flex-grow py-2 px-4 ${
                !mobileDetailedScoring && 'mx-4'
              } select-none ${isDetailedScoring && 'text-sm'} 
                ${
                  selected === idx
                    ? `${color} ${border} border-b-4 font-bold  cursor-pointer `
                    : el.disabled
                    ? 'text-gray-450 border-gray-450 disabled opacity-50'
                    : 'text-gray-450 border-gray-450 cursor-pointer'
                }
              `}
              onClick={() => (el.disabled ? null : setTabs(idx))}
            >
              {el.name}
            </a>
          );
        })}
      </div>
      <div>{tabList[selected].component}</div>
    </div>
  );
}
