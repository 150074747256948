import { useState, useEffect } from 'react';
import KheloIndiaLogo from '../../../assets/img/logo/TN_1.png';
import khelologoEnglish from '../../../assets/img/logo/TN_2.png';
const HeaderLogo = () => {
  const [currentImg, setCurrentImg] = useState(KheloIndiaLogo);
  // const isLg = useBreakPoint('lg');
  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentImg(currentImg === KheloIndiaLogo ? khelologoEnglish : KheloIndiaLogo);
    }, 4000);
    return () => clearTimeout(timer);
  }, [currentImg]);
  return (
    <div>
      <img src={currentImg} className={'h-14 hidden lg:inline-block cursor-pointer'} alt={''} />
      {/* <img src={KheloIndiaLogo} className={'h-12 lg:hidden cursor-pointer'} alt={''} /> */}
    </div>
  );
};

export default HeaderLogo;
