import VenueCard from '../components/VenueCard';
import { useEffect, useState, useMemo } from 'react';
// import { individualData } from '../builderData';
import All from './All';
import VenueMobileCard from '../components/VenueMobileCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useVenues } from 'micro-site/queries/hooks';
import { useHistory, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Loader from 'components/Loader';

const Venues = () => {
  const windowSize = useWindowDimensions();
  const isMobile = windowSize.width < 768;
  const [venueData, setVenueData] = useState([]);
  const params = useParams();
  const tournamentId = params?.tournament_id;
  const history = useHistory();
  // const cityCode = [];
  const [curCity, setCurCity] = useState(params.city || 'All');
  const venueDataQuery = useVenues({
    tournament: params?.tournament_id,
  });
  const cityCode = useMemo(() => {
    if (!Array.isArray(venueDataQuery?.data)) return [];
    return [
      'All',
      ...((Array?.isArray(venueDataQuery?.data) &&
        venueDataQuery?.data?.map((city) => city.city)?.filter((el) => el)) ||
        []),
    ];
  }, [venueDataQuery.data]);
  const banner = useMemo(() => {
    if (Array.isArray(venueDataQuery?.data))
      return venueDataQuery?.data?.filter((el) => el.venue_page_banner)?.[0]?.venue_page_banner;
    return [];
  }, [venueDataQuery.data]);

  const time = useMemo(() => {
    if (!Array.isArray(venueDataQuery?.data)) return [];
    const temp = venueDataQuery?.data?.filter((el) => el.time);

    if (temp && Array.isArray(temp) && temp.length) return temp?.[0]?.time;
    return 3;
  }, [venueDataQuery.data]);

  const settings = {
    initialSlide: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: time * 1000,
    arrows: false,
    pauseOnHover: false,
  };
  const individualData = useMemo(() => {
    return (
      (Array?.isArray(venueDataQuery?.data) &&
        venueDataQuery?.data?.reduce((prevCity, currCity) => {
          if (currCity.city) return { ...prevCity, [currCity.city]: currCity.venue_details };
          return { ...prevCity };
        }, {})) ||
      {}
    );
  }, [venueDataQuery.data]);
  useEffect(() => {
    if (curCity !== 'All') setVenueData(individualData[curCity]);
  }, [curCity]);
  const clickHandler = (city) => {
    history.push(`/micro-site/${params?.tournament_id}/landing/venues`);
    if (city !== 'All') {
      history.push(`/micro-site/${params?.tournament_id}/landing/venues/${city}`);
    }

    setCurCity(city);
  };
  if (venueData?.isFetching) return <Loader />;
  return (
    <>
      {venueDataQuery?.isFetching ? (
        <Loader className={'h-40-screen'} />
      ) : (
        <>
          <Slider
            {...settings}
            className="w-full scheduleSlider md:block hidden override-slide-margin"
          >
            {banner?.map((image, i) => (
              <img className="m-auto  object-cover h-full" src={image?.image} key={i} />
            ))}
          </Slider>
          <Slider
            {...settings}
            className="w-full scheduleSlider block md:hidden override-slide-margin"
          >
            {banner?.map((image, i) => (
              <img className="m-auto  object-cover h-full" src={image?.image_mobile} key={i} />
            ))}
          </Slider>
        </>
      )}
      {/* {isMobile && curCity !== 'All' ? null : (
        <div className="px-6 mt-11 md:mt-24 pt-1 text-center mb-8">
          <h1 className="font-extrabold text-blue-kheloBlue font-montserrat md:text-4xl mb-2 text-lg">
            Venues
          </h1>
        </div>
      )} */}
      <div className="p-14 md:flex bg-blue-venueBg flex-wrap gap-2 justify-center hidden">
        {!isMobile && (
          <div className="w-full text-center mb-10">
            {cityCode.map((city) => (
              <span
                className={`mx-3 font-extrabold cursor-pointer text-purple-${
                  city === curCity ? 'venuefilterActive' : 'venuefilter'
                } text-${city === curCity ? '2xl' : '3xs'}`}
                key={city}
                onClick={() => clickHandler(city)}
              >
                {city}
              </span>
            ))}
          </div>
        )}
        {curCity === 'All' ? (
          <All
            cityList={cityCode}
            tournamentId={tournamentId}
            setCurCity={setCurCity}
            landingAllData={individualData}
          />
        ) : (
          venueData.map((el, i) => (
            <VenueCard
              key={i}
              {...el}
              name={el.venue}
              image={el.banner_pic}
              width={el.banner_pic_width || '48%'}
              landingAllData={individualData}
              redirectTo={`/micro-site/${tournamentId}/landing/venues/${curCity}/${el.venue}`}
            />
          ))
        )}
      </div>
      <div className="bg-blue-venueBg pt-2">
        {curCity !== 'All' && isMobile && (
          <div className="flex w-full text-center">
            <FontAwesomeIcon
              className="absolute m-1 mx-6 px-3 py-1 border-1 rounded-sm  text-blue-kheloBlue"
              icon={faAngleLeft}
              onClick={() => setCurCity('All')}
            />
            <p className="w-full text-blue-kheloBlue text-lg m-0 font-extrabold">
              {`${curCity} Venues`}
            </p>
          </div>
        )}
        <div
          className={`grid grid-cols-${curCity === 'All' ? '1' : '2'} gap-2 md:hidden px-6 py-8`}
        >
          {curCity === 'All' ? (
            <All
              cityCode={cityCode}
              tournamentId={tournamentId}
              setCurCity={setCurCity}
              isMobile
              landingAllData={individualData}
              cityList={cityCode}
            />
          ) : (
            venueData.map((el, i) => (
              <VenueMobileCard
                key={i}
                {...el}
                name={el.venue}
                image={el.banner_pic}
                redirectTo={`/micro-site/${tournamentId}/landing/venues/${curCity}/${el.venue}`}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Venues;
