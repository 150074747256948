import { realTimeStatus } from 'utils/constants';

const MatchCenterScoreCard = ({
  teamOne,
  teamTwo,
  scoreOne,
  scoreTwo,
  penaltyShootout: [psOne, psTwo] = [0, 0],
  isDraw,
  isWon,
  matchStatus,
}) => {
  return (
    <div className="flex gap-4 justify-between">
      <div className="flex flex-col gap-1 items-center w-16 md:w-28">
        <img
          src={teamOne?.image}
          alt="team-one"
          className="w-12 h-12 object-cover rounded-full mb-2"
        ></img>
        <div className="flex gap-2 font-bold items-center text-sm md:text-base">
          <span className="w-2 h-2 inline-block bg-blue-350 rounded-full flex-shrink-0"></span>
          {teamOne?.name}
        </div>
        {!isDraw && !realTimeStatus[matchStatus] ? (
          <div className="text-xxs md:text-xs text-gray-650 md:text-center  whitespace-nowrap md:whitespace-normal truncate">
            {teamOne?.is_won ? (
              <div
                className={
                  'font-roboto font-bold text-mini text-white px-1.5 py-0.5 rounded bg-green-550 w-content'
                }
              >
                <span className={'font-bold'}>WON</span>
              </div>
            ) : (
              <div
                className={
                  'font-roboto font-bold text-mini text-white px-1.5 py-0.5 bg-red-500 rounded'
                }
              >
                <span className={'font-bold'}>LOST</span>
              </div>
            )}
          </div>
        ) : null}
        <div className="text-xxs md:text-xs text-gray-650 text-center  whitespace-nowrap md:whitespace-normal truncate">
          {teamOne?.institution}
        </div>
      </div>
      <div className="font-bold text-lg md:text-3.5xl whitespace-nowrap flex flex-col gap-2 justify-center items-center">
        <span className="flex items-center">
          {psOne ? (
            <>
              {scoreOne - psOne}
              <span className="text-sm md:text-xl text-gray-450">({psOne})</span>
            </>
          ) : (
            scoreOne
          )}
          -
          {psTwo ? (
            <>
              {scoreTwo - psTwo}
              <span className="text-sm md:text-xl text-gray-450">({psTwo})</span>
            </>
          ) : (
            scoreTwo
          )}
        </span>
        {isDraw || realTimeStatus[matchStatus] ? (
          <div
            className={
              'font-roboto font-bold text-mini text-white px-1.5  rounded bg-blue-950 w-full text-center'
            }
          >
            <span className={'font-bold'}>{realTimeStatus[matchStatus] || 'DRAW'}</span>
          </div>
        ) : null}
      </div>
      <div className="flex flex-col gap-1 items-center w-16 md:w-28">
        <img
          src={teamTwo?.image}
          alt="team-two"
          className="w-12 h-12 object-cover rounded-full mb-2"
        ></img>
        <div className="flex gap-2 font-roboto font-bold items-center text-sm md:text-base">
          <span className="w-2 h-2 inline-block flex-shrink-0 bg-orange-orangeWarning rounded-full "></span>
          {teamTwo?.name}
        </div>
        {!isDraw && !realTimeStatus[matchStatus] ? (
          <div className="text-xxs md:text-xs text-gray-650 md:text-center  whitespace-nowrap md:whitespace-normal truncate">
            {teamTwo?.is_won ? (
              <div
                className={
                  'font-roboto font-bold text-mini text-white px-1.5 py-0.5 rounded bg-green-550 w-content'
                }
              >
                <span className={'font-bold'}>WON</span>
              </div>
            ) : (
              <div
                className={
                  'font-roboto font-bold text-mini text-white px-1.5 py-0.5 bg-red-500 rounded'
                }
              >
                <span className={'font-bold'}>LOST</span>
              </div>
            )}
          </div>
        ) : null}
        <div className="text-xxs   md:text-xs  text-gray-650 text-center whitespace-nowrap md:whitespace-normal truncate w-1/2 md:w-full">
          {teamTwo?.institution}
        </div>
      </div>
    </div>
  );
};

export default MatchCenterScoreCard;
