const columnVariants = {
  detailed: ({ name, jersey, team_image: teamImage, team, icon }, enableIcon, isLast) => (
    <div className={`flex gap-2 items-start ${!isLast ? 'flex-row-reverse' : ''}`}>
      {isLast && enableIcon ? <img src={icon} className="mt-1"></img> : null}
      {!isLast && enableIcon ? <img src={icon} className="mt-1"></img> : null}
      <div className="flex flex-col gap-1 ">
        <span className="font-bold text-sm md:text-base">{name}</span>

        <div className={`flex gap-2 items-center ${isLast ? 'justify-end' : ''}`}>
          {!isLast ? (
            <>
              <img
                src={teamImage}
                alt="team-logo"
                className="w-3 h-3 md:w-4 md:h-4 object-cover rounded-full"
              />
              <div className="text-xs">{team}</div>
            </>
          ) : (
            <>
              <div className="text-xs">{team}</div>
              <img
                src={teamImage}
                alt="team-logo"
                className="w-3 h-3 md:w-4 md:h-4 object-cover rounded-full"
              />
            </>
          )}
        </div>
      </div>
    </div>
  ),
  image: ({ image }) => (
    <img src={image} className="w-12 h-12 object-cover rounded-full" alt="athlete" />
  ),
};

const EventCard = ({
  heading,
  headingIcon,
  time,
  headingBG = 'bg-white',
  col1Type,
  col2Type,
  col1Data,
  col2Data,
  bottomBG = 'bg-white',
  headingColor = 'text-black',
  headingWeight = '',
  enableIcon,
}) => {
  console.log(col1Data, col2Data);

  return (
    <div className="my-4">
      {/* Top part */}
      <div
        className={`p-2 md:p-3 flex justify-between border-t-1 border-l-1 border-r-1 border-gray-300 rounded-t-lg text-sm  ${headingBG} ${headingColor}`}
      >
        <div className={`flex gap-2 items-center   ${headingWeight} md:text-base text-sm`}>
          <img src={headingIcon} />
          {heading}
        </div>
        {time}
      </div>
      {/* bottom part */}
      <div
        className={`p-2 md:p-3 flex justify-between ${bottomBG} border-1 border-gray-300 rounded-b-lg md:text-base text-sm`}
      >
        {columnVariants[col1Type](col1Data, enableIcon)}
        {columnVariants[col2Type](col2Data, enableIcon, true)}
      </div>
    </div>
  );
};

export default EventCard;
