import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import SelectList from 'components/select-list';
import Loader from 'components/Loader';
import 'components/formio/formio.css';
import 'constants/DrawEventForm/style.css';
import Table from '../../../Components/Table';
import { useShowKhelo } from 'utils/HooksUtils';
import { fullRankingTableKheloText, fullRankingTableSFAText } from 'data/noDataText';

export default function RankingTable({
  sport,
  setSport,
  categoryList,
  rankingTableListFullQueryData,
  columnsTable,
  setScreen,
}) {
  const isKhelo = useShowKhelo();

  return (
    <div className="md:mt-8 px-4 md:px-16">
      <div
        className="flex gap-2 cursor-pointer items-center mb-8"
        onClick={() => setScreen('main_page')}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
        <p className="font-bold">Result</p>
      </div>
      <div className="flex justify-between shadow-shadowBottom pb-2 items-center">
        <p className="text-2xl font-bold">Full Ranking Table</p>
      </div>
      <div className="flex justify-between mt-8">
        <div className="flex items-center gap-2.5">
          <div className="w-40">
            <SelectList
              selectedValue={sport}
              setSelectedValue={setSport}
              placeholder="Sport"
              listData={categoryList}
            />
          </div>
          {/* <div className="w-40">
                <SelectList
                  selectedValue={event}
                  setSelectedValue={setEvent}
                  placeholder="Event"
                  listData={categoryList}
                />
              </div>
              <div className="w-40">
                <SelectList
                  selectedValue={medal}
                  setSelectedValue={setMedal}
                  placeholder="Medal"
                  listData={categoryList}
                />
              </div>
              <div className="w-40">
                <SelectList
                  selectedValue={round}
                  setSelectedValue={setRound}
                  placeholder="Round"
                  listData={categoryList}
                />
              </div> */}
        </div>
        {/* <div className="flex gap-2 text-sm py-2.5 px-4 border-1 border-gray-350 rounded-lg items-center">
              <FontAwesomeIcon icon={faSearch} className="text-gray-450" />
              <input
                type="text"
                className="border-0 h-5 text-sm placeholder-gray-450"
                placeholder="Search by School"
              />
            </div> */}
      </div>
      {/* <div className="flex justify-between gap-6 mt-8">
            {pointsMedalsQueryData ? (
              <PointsCard
                data={pointsMedalsQueryData.data.individual_points}
                head="Individual Points"
              />
            ) : (
              <Loader />
            )}
            {pointsMedalsQueryData ? (
              <PointsCard data={pointsMedalsQueryData.data.team_points} head="Team Points" />
            ) : (
              <Loader />
            )}
          </div> */}
      <div className="mt-4">
        {rankingTableListFullQueryData.data &&
        rankingTableListFullQueryData.data.pages?.[0]?.data?.length > 0 &&
        Array.isArray(rankingTableListFullQueryData?.data?.pages?.[0]?.data) &&
        rankingTableListFullQueryData?.isFetchedAfterMount ? (
          <>
            {' '}
            <Table
              tableHeight={'800px'}
              columns={columnsTable}
              rows={rankingTableListFullQueryData.data}
              sport={sport}
              icon={true}
              iconAndText={true}
              isInfinite
              isInfiniteFetching={rankingTableListFullQueryData.isFetchingNextPage}
              fetchNext={rankingTableListFullQueryData?.fetchNextPage}
            />
          </>
        ) : rankingTableListFullQueryData.isFetching ? (
          <Loader />
        ) : (
          <div className="py-20 text-center w-full  font-roboto h-full">
            {isKhelo ? fullRankingTableKheloText : fullRankingTableSFAText}
          </div>
        )}
      </div>
    </div>
  );
}
