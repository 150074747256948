import React from 'react';
// import loaderLogo from 'assets/img/loader/loader.gif';

export default function Loader({ width = 16, height = 16, color = 'orange-900', text, className }) {
  return (
    <div
      className={
        `w-full h-full flex justify-center items-center ${text ? 'flex-col' : ''} ` + className
      }
    >
      {/* <img src={loaderLogo} className={`h-${height} w-${width} rounded-full`} /> */}
      <div
        className={`animate-spin rounded-full h-${height} w-${width} border-b-2 border-${color}`}
      ></div>
      {text ? <div className="mt-2 font-bold text-center">{text}</div> : null}
    </div>
  );
}
