import React from 'react';

import Loader from '../../../../components/Loader';
import watermark from 'watermarkjs';

const ShowMedia = ({
  mediaName,
  className,
  onClick,
  ImageOnly,
  divRef,
  fullImage,
  previewImage,
}) => {
  const mediaDetailsQuery = {
    data: {},
  };
  React.useEffect(() => {
    mediaDetailsQuery?.data?.image &&
      watermark([mediaDetailsQuery.data.image, '/watermark1.png'])
        .image(watermark.image.lowerRight(0.5))
        .load(['/watermark2.png'])
        .image(
          watermark.image.atPos(
            () => {
              return -30;
            },
            () => {
              return -30;
            },
            0.5
          )
        )
        .then((img) =>
          document.getElementById('forWatermark')
            ? document.getElementById('forWatermark').appendChild(img)
            : ''
        );
  }, []);

  return mediaDetailsQuery.isLoading ? (
    <Loader />
  ) : mediaDetailsQuery?.data?.type === 'Image' || ImageOnly ? (
    !fullImage ? (
      <img
        onContextMenu={(e) => e.preventDefault()}
        ref={divRef}
        src={ImageOnly && previewImage}
        alt={''}
        className={className}
        onClick={onClick}
      />
    ) : (
      <div
        id="forWatermark"
        onContextMenu={(e) => e.preventDefault()}
        ref={divRef}
        className={className}
        onClick={onClick}
      ></div>
    )
  ) : mediaDetailsQuery?.data?.type === 'Video(youtube)' ? (
    <iframe
      ref={divRef}
      className={'w-full h-auto md:w-auto md:h-full border-5 border-white'}
      style={{
        aspectRatio: '16/9',
      }}
      src={`https://www.youtube.com/embed/${mediaDetailsQuery?.data?.video_id}?autoplay=1`}
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  ) : mediaDetailsQuery?.data?.type === 'Video(vimeo)' ? (
    <iframe
      ref={divRef}
      src={`https://player.vimeo.com/video/${mediaDetailsQuery?.data?.video_id}?autoplay=1`}
      className={'w-full h-auto md:w-auto md:w-full border-5 border-white bg-white'}
      style={{
        aspectRatio: '16/9',
      }}
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
    />
  ) : null;
  /*
  return (
    <DetailItemLoader query={mediaDetailsQuery}>
      {(item) => {
        console.log({ item });
        // return data.media_details.map((media) => {
        // return <img src={data.image} alt={data.name} className={className} />;
        return <></>;
      }}
    </DetailItemLoader>
  );
*/
};

export default ShowMedia;
