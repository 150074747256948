import React from 'react';
import DetailItemLoader from '../DetailItemLoader';
import MatchCenterMobile from '../../profiles/Tournaments/MatchFixtures/components/MatchCenterMobile';
import { MatchCenterModal } from '../../profiles/Tournaments/MatchFixtures/components/MatchCenterModal';
import MatchCenterPage from '../../profiles/Tournaments/MatchFixtures/components/MatchCenterPage';
import Modal from 'react-modal';
import { useDsScoring } from '../../micro-site/queries/hooks';

const AdvancedDsComponent = ({ MatchId, closeModal, state, matchStatus }) => {
  Modal.setAppElement('#root');
  const query = useDsScoring({
    matchId: MatchId,
  });
  return (
    <Modal
      isOpen={state}
      bodyOpenClassName={'overflow-hidden no-scrollbar'}
      onRequestClose={() => {
        if (closeModal) closeModal();
      }}
      className={
        'p-0 h-screen w-full md:h-auto fixed md:w-1/2 md:left-1/2 md:top-1/2 transform md:-translate-x-1/2 md:-translate-y-1/2 border-none bg-white overflow-y-scroll md:rounded-lg no-scrollbar md:max-h-90-screen'
      }
      style={{
        overlay: {
          zIndex: 20,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
          WebkitOverflowScrolling: 'touch',
          outline: 'none',
        },
      }}
    >
      <DetailItemLoader queryData={query}>
        {({ data, refetch, isFetching }) => {
          return (
            <>
              {data?.component === 'Racket' ? (
                <>
                  <MatchCenterMobile
                    MatchCenterData={data}
                    closeModal={closeModal}
                    matchStatus={matchStatus}
                    refetch={refetch}
                    isFetching={isFetching}
                  />
                  <MatchCenterModal
                    data={data}
                    closeModal={closeModal}
                    matchStatus={matchStatus}
                    refetch={refetch}
                    isFetching={isFetching}
                  />
                </>
              ) : data?.component === 'Team' ? (
                <MatchCenterPage
                  data={data}
                  closeModal={closeModal}
                  matchStatus={matchStatus}
                  refetch={refetch}
                  isFetching={isFetching}
                />
              ) : null}
            </>
          );
        }}
      </DetailItemLoader>
    </Modal>
  );
};

export default AdvancedDsComponent;
