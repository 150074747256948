import React, { useRef } from 'react';
import gold from '../../../../assets/img/gold.svg';
import silver from '../../../../assets/img/silver.svg';
import bronze from '../../../../assets/img/bronze.svg';
import ListLoader from '../../../../components/ListLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import SelectList from 'components/select-list';
import Loader from 'components/Loader';
import 'components/formio/formio.css';
import 'constants/DrawEventForm/style.css';
import Table from '../../../Components/Table';
import Tabs from '../../../../components/Tab/index';
import MobileRankTable from '../../../Components/MobileRankingTable';
import WinnerCardList from '../../../Components/WinnerCardList';
import DrawEventForm from 'micro-site/Components/DrawEventForm.js';
import { useShowKhelo, useInfiniteScroll } from 'utils/HooksUtils';
import { getDateInMonthDateYear, getTimeIn12HourFormat } from 'constants/DateFunctions';
import { rankingTableText, recentWinnersText } from 'data/noDataText';
export default function MainPage({
  sport,
  setSport,
  categoryList,
  subSport,
  setSubSport,
  listEventData,
  rankingTableListPreviewQueryData,
  winnerTableQueryData,
  form,
  columnsTable,
  setScreen,
  handleDrawFormat,
  sportEventQueryData,
  winnerTableFullQueryData,
  winnersTableRef,
  rankingTableListFullQueryData,
}) {
  const isKhelo = useShowKhelo();
  const infiniteRef = useRef(null);
  useInfiniteScroll(
    infiniteRef,
    winnerTableFullQueryData.fetchNext,
    winnerTableFullQueryData.isFetching || winnerTableFullQueryData.isFetchingNextPage,
    true
  );
  const Points = [
    {
      label: 'Individual Points',
      medal: [
        {
          label: 'Gold',
          img: gold,
          count: '05',
        },
        {
          label: 'Silver',
          img: silver,
          count: '03',
        },
        {
          label: 'Bronze',
          img: bronze,
          count: '01',
        },
      ],
    },
    {
      label: 'Team Points',
      medal: [
        {
          label: 'Gold',
          img: gold,
          count: '15',
        },
        {
          label: 'Silver',
          img: silver,
          count: '10',
        },
        {
          label: 'Bronze',
          img: bronze,
          count: '05',
        },
      ],
    },
  ];
  const ResultTab = [
    // { name: 'Overview', component: <Overview /> },
    {
      name: 'Ranking Table',
      component: (
        <MobileRankTable
          Points={Points}
          selectedValue={sport}
          setSelectedValue={setSport}
          placeholder="Sport"
          listData={categoryList}
          sport={sport}
          Tabledata={rankingTableListFullQueryData}
          height="500px"
        />
      ),
    },
    {
      name: 'Winners List',
      component: (
        <WinnerCardList
          data={winnerTableFullQueryData}
          selectedValue={sport}
          setSelectedValue={setSport}
          placeholder="Sport"
          listData={categoryList}
          sport={sport}
          subSport={subSport}
          setSubSport={setSubSport}
          listEventData={listEventData}
        />
      ),
    },
  ];
  return (
    <div className="md:mt-8 px-4 md:px-16 max-w-fhd mx-auto">
      <div className="hidden md:block">
        <div className="flex justify-between shadow-shadowBottom pb-2 items-end">
          <p className="text-2xl font-bold">Ranking Table</p>
          {/* <div
            className={`flex gap-2 items-center cursor-pointer ${
              rankingTableListPreviewQueryData?.data?.pages.at(0)?.total_records < 8 ||
              !Array.isArray(rankingTableListPreviewQueryData?.data?.pages.at(0)?.data)
                ? 'hidden'
                : ''
            }`}
            onClick={() => setScreen('ranking_table')}
          >
            <p className="font-semibold">View Full Table</p>
            <FontAwesomeIcon icon={faAngleRight} className="w-3 h-3" />
          </div> */}
        </div>
        <div className="flex justify-between mt-8">
          <div className="flex items-center gap-2.5">
            <div className="flex gap-2.5">
              <SelectList
                selectedValue={sport}
                setSelectedValue={setSport}
                placeholder="Sport"
                listData={categoryList}
                sport={sport}
              />

              <h1 className="py-2 text-right">
                Last Updated On{' '}
                {getDateInMonthDateYear(rankingTableListFullQueryData?.data?.pages[0]?.last_update)}{' '}
                {getTimeIn12HourFormat(rankingTableListFullQueryData?.data?.pages[0]?.last_update)}
              </h1>
            </div>
            {/* <div className="w-40">
          <SelectList
            selectedValue={round}
            setSelectedValue={setRound}
            placeholder="Round"
            listData={categoryList}
          />
        </div> */}
            {rankingTableListPreviewQueryData?.data?.length && (
              <p className="text-xs text-gray-450">
                Showing Top{' '}
                {rankingTableListPreviewQueryData?.data?.length < 8
                  ? rankingTableListPreviewQueryData?.data?.length
                  : 8}{' '}
                {isKhelo ? 'States' : 'Schools'}
              </p>
            )}
          </div>
          {/* <div className="flex gap-2 text-sm py-2.5 px-4 border-1 border-gray-350 rounded-lg items-center">
        <FontAwesomeIcon icon={faSearch} className="text-gray-450" />
        <input
          type="text"
          className="border-0 h-5 text-sm placeholder-gray-450"
          placeholder="Search by School"
        />
      </div> */}
        </div>

        {!isKhelo && (
          <div className="hidden w-full py-6 md:flex flex-row gap-6">
            {Points.map((point, idx) => (
              <div key={idx} className="border rounded-lg w-1/2 p-6 flex flex-col gap-2.5">
                <p className="font-bold text-lg">{point.label}</p>
                <div className="flex flex-row w-full items-center">
                  {point.medal.map((med, index) => (
                    <div key={index} className="w-1/3 flex gap-2 items-center">
                      <div className="">
                        <img src={med.img} width="22px" height="22px" alt="medal" />
                      </div>
                      <div>
                        <p className="text-xs">{med.label}</p>
                        <p className="text-base font-bold">{med.count} Points</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="mt-4 hidden md:block">
          {rankingTableListPreviewQueryData.data &&
          rankingTableListPreviewQueryData?.data?.pages?.[0]?.data?.length > 0 &&
          Array.isArray(rankingTableListPreviewQueryData?.data?.pages?.[0]?.data) &&
          rankingTableListPreviewQueryData?.isFetchedAfterMount ? (
            <>
              <Table
                firstRow={<GrandTotalDesktop data={rankingTableListFullQueryData} />}
                columns={columnsTable}
                rows={rankingTableListPreviewQueryData?.data}
                iconAndText={true}
                icon={true}
                isInfinite
                isInfiniteFetching={rankingTableListPreviewQueryData.isFetchingNextPage}
                fetchNext={rankingTableListFullQueryData?.fetchNextPage}
                selectedResultSport={sport}
              />
            </>
          ) : rankingTableListPreviewQueryData.isFetching ? (
            <Loader />
          ) : (
            <p className="w-full text-center my-20 text-sm text-gray-750">{rankingTableText}</p>
          )}
        </div>

        {winnerTableQueryData?.data?.pages?.[0]?.data?.length > 0 && (
          <>
            <div className="flex justify-between shadow-shadowBottom pb-2 items-center mt-16 mb-8">
              <p className="text-2xl font-bold">Recent Winners</p>
              <div
                className={`flex gap-2 items-center cursor-pointer ${
                  winnerTableQueryData?.data?.length < 8 ? 'hidden' : ''
                }`}
                onClick={() => setScreen('winner_list')}
              >
                <p className="font-semibold">View All Winners</p>
                <FontAwesomeIcon icon={faAngleRight} className="w-3 h-3" />
              </div>
            </div>

            <div
              ref={infiniteRef}
              id="recentWinnersTable"
              className={`flex justify-start overflow-y-auto ${
                isKhelo ? 'bg-blue-kheloBlue400' : 'bg-purple-950'
              } text-white px-6 py-8 rounded-2xl gap-2`}
              style={{ maxHeight: '600px' }}
            >
              {winnerTableQueryData.data ? (
                winnerTableQueryData?.data?.pages?.[0]?.data?.length > 0 ? (
                  <ListLoader
                    queryData={winnerTableQueryData}
                    queryKey="id"
                    isInfinite
                    isInfiniteFetching={winnerTableQueryData.isFetching}
                  >
                    {({ item }) => {
                      return (
                        <div className="w-36 flex flex-col" key={item.athlete.sfa_id}>
                          <div className="flex flex-col items-center  pb-1">
                            <img
                              src={item.athlete.image}
                              alt="dp"
                              className="w-24 h-24 rounded-full overflow-hidden border-4 border-white relative"
                            />
                            <img
                              src={
                                item.medal === 'Gold'
                                  ? gold
                                  : item.medal === 'Silver'
                                  ? silver
                                  : bronze
                              }
                              alt="medalPlaceholder"
                              className="absolute w-auto h-8 mt-20 bg-white p-1 rounded-full"
                            />
                          </div>
                          <div style={{ flex: '1 1 auto' }}>
                            <div className="flex flex-col justify-between h-full ">
                              {' '}
                              {item.athlete.name.includes('Team') ? (
                                <p className="text-xs mt-6 font-bold text-center">
                                  {item.athlete.institution}
                                </p>
                              ) : (
                                <div>
                                  <p className="text-xs mt-6 font-bold text-center">
                                    {item.athlete.name}
                                  </p>
                                  <p className="text-xxs mt-0.5 opacity-60 text-center">
                                    {item.athlete.institution}
                                  </p>
                                </div>
                              )}
                              <div className="border-t-1 border-primary-50 pt-1">
                                <div className="flex flex-col items-center py-1 shadow-shadowBottomWhite">
                                  <p className="text-xxs opacity-60 text-center">
                                    {item.sport_event.sport}{' '}
                                    {item?.sport_event?.sub_category &&
                                      `- ${item?.sport_event?.sub_category}`}
                                  </p>
                                </div>
                                <div className="flex flex-col items-center pt-1">
                                  <p className="text-xxs opacity-60">
                                    {item.sport_event.gender} / {item.sport_event.age_group}
                                  </p>
                                </div>
                              </div>
                            </div>{' '}
                          </div>
                        </div>
                      );
                    }}
                  </ListLoader>
                ) : (
                  <div className="bg-purple-950 text-white text-center rounded-lg py-8 w-full">
                    {recentWinnersText}
                  </div>
                )
              ) : (
                <Loader />
              )}
            </div>
          </>
        )}

        <div className="mt-16 ">
          <div className="shadow-shadowBottom pb-3 mb-8 flex justify-between">
            <h1 className="text-2xl font-bold">View Detailed Results</h1>
            {/* <div
          className="flex gap-2 items-center cursor-pointer"
          onClick={() => setScreen('winner_list')}
        >
          <p className="font-semibold">View All Winners</p>
          <FontAwesomeIcon icon={faAngleRight} className="w-3 h-3" />
        </div> */}
          </div>
          <div className="draw-form">
            {/* <Form
              form={form}
              url={process.env.REACT_APP_API_URL}
              onSubmit={(schema) => handleDrawFormat(schema)}
            ></Form> */}
            <DrawEventForm
              handleDrawFormat={handleDrawFormat}
              customSubmitText={'View Results'}
              isLoading={sportEventQueryData.isLoading}
            />
          </div>
        </div>
      </div>
      <div className="md:hidden mb-4">
        <Tabs tabList={ResultTab} color="text-blue-350" border="border-blue-350" />
      </div>
    </div>
  );
}

export const GrandTotalDesktop = ({ data }) => (
  <tr>
    <td></td>
    <td></td>
    <td className="px-4 py-2.5 text-xs text-white">Grand Total</td>
    <td className="px-4 py-2.5 text-xs text-white text-center">
      {data?.data?.pages?.[0]?.data?.reduce((prev, current) => {
        return prev + current?.points?.gold;
      }, 0)}
    </td>
    <td className="px-4 py-2.5 text-xs text-white text-center">
      {data?.data?.pages?.[0]?.data?.reduce((prev, current) => {
        return prev + current?.points?.silver;
      }, 0)}
    </td>
    <td className="px-4 py-2.5 text-xs text-white text-center">
      {data?.data?.pages?.[0]?.data?.reduce((prev, current) => {
        return prev + current?.points?.bronze;
      }, 0)}
    </td>
    <td className="px-4 py-2.5 text-xs text-white text-center">
      {data?.data?.pages?.[0]?.data?.reduce((prev, current) => {
        return prev + current?.points?.points;
      }, 0)}
    </td>
  </tr>
);

export const GrandTotalMobile = ({ data }) => (
  <tr>
    <td className="px-4 py-2.5 text-xs text-white">Grand Total</td>
    <td className="px-4 py-2.5 text-xs text-white">
      {data?.data?.pages?.[0]?.data?.reduce((prev, current) => {
        return prev + current?.points?.gold;
      }, 0)}
    </td>
    <td className="px-4 py-2.5 text-xs text-white">
      {data?.data?.pages?.[0]?.data?.reduce((prev, current) => {
        return prev + current?.points?.silver;
      }, 0)}
    </td>
    <td className="px-4 py-2.5 text-xs text-white">
      {data?.data?.pages?.[0]?.data?.reduce((prev, current) => {
        return prev + current?.points?.bronze;
      }, 0)}
    </td>
    <td className="px-4 py-2.5 text-xs text-white">
      {data?.data?.pages?.[0]?.data?.reduce((prev, current) => {
        return prev + current?.points?.points;
      }, 0)}
    </td>
  </tr>
);
