import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
// import Tabs from '../../../../components/Tab';
import { realTimeStatus } from 'utils/constants';
import LightGallery from 'lightgallery/react';

export const showFinalHideFinal = {
  judo: { sets: false },
  wrestling: { sets: false },
  boxing: { sets: false },
  fencing: { sets: false, team: { sets: true } },
  basketball: { sub_category: { '3x3': false } },
};
export const sumOfPoints = (Arr) => {
  let sum = 0;
  Arr.forEach((el) => {
    sum += el.Points;
  });
  return sum;
};
export const MatchCenterModal = ({ closeModal, data, matchStatus, refetch, isFetching }) => {
  const hideFinalFlag = data?.is_team
    ? showFinalHideFinal[data.sport?.toLowerCase()]?.team
    : showFinalHideFinal[data?.sport?.toLowerCase()];
  return (
    <div className={'flex-col h-full w-full hidden md:flex'} onClick={(e) => e.stopPropagation()}>
      <div className={'bg-gray-100 px-7 md:py-4 border-b-1 border-gray-300 flex justify-between'}>
        <h1 className={'font-bold font-roboto text-md text-black200 md:mt-1'}>
          {data?.sport} {data?.sub_title}&nbsp;({data?.match_id}){' '}
          <i
            onClick={refetch}
            className={`fa fa-refresh ${
              isFetching ? 'fa-spin' : ''
            } button-icon-right cursor-pointer`}
          ></i>
        </h1>
        <div className={'bg-gray-100'}>
          {console.log(data?.score_sheet?.slice(-4)?.toLowerCase() === '.pdf', 'aaa')}
          {data?.score_sheet && (
            <span className="inline-block mr-5">
              {data?.score_sheet?.slice(-4)?.toLowerCase() !== '.pdf' ? (
                <LightGallery
                  enableDrag={false}
                  enableSwipe={false}
                  counter={false}
                  licenseKey={process.env.REACT_APP_LIGHT_GALLERY_LICENSE_KEY}
                  speed={500}
                  iframeHeight="100vh"
                  iframeMaxWidth="100vw"
                  download={false}
                >
                  <div
                    data-lg-size="1406-1390"
                    className="gallery-item text-sm"
                    data-src={data?.score_sheet}
                  >
                    <a> (View Scoresheet)</a>
                  </div>
                </LightGallery>
              ) : (
                <a href={data?.score_sheet} rel="noreferrer" target={'_blank'}>
                  {' '}
                  (View Scoresheet)
                </a>
              )}
            </span>
          )}
          <button
            className={
              'font-roboto bg-gray-750 text-white rounded-lg px-3 p-1 font-medium text-smd'
            }
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </div>

      {realTimeStatus[matchStatus] ? (
        <div
          className={
            'font-roboto font-bold text-xxs mt-2 text-white px-1.5 py-0.5 rounded bg-blue-950 w-content w-32 mx-auto text-center'
          }
        >
          <span className={'font-bold'}>{realTimeStatus[matchStatus]}</span>
        </div>
      ) : null}
      <div className={'flex flex-col flex-grow p-5 gap-3 justify-start md:mt-3'}>
        <div className={'flex items-center justify-between'}>
          <AthleteInformationCard
            data={data.Athlete1}
            isDraw={data?.is_draw}
            scoringType={data?.scoringType}
            matchStatus={matchStatus}
            isPenaltyEnabled={data?.isPenaltyEnabled && data?.penalty.find((el) => el)}
            penaltyWon={data?.penalty?.[0] > data?.penalty?.[1]}
            isPenaltyDraw={data?.penalty?.[0] === data?.penalty?.[1]}
          />
          <div className={'text-sm font-roboto'}>vs</div>
          <AthleteInformationCard
            data={data.Athlete2}
            isReverse
            isDraw={data?.is_draw}
            scoringType={data?.scoringType}
            matchStatus={matchStatus}
            isPenaltyEnabled={data?.isPenaltyEnabled && data?.penalty.find((el) => el)}
            penaltyWon={data?.penalty?.[1] > data?.penalty?.[0]}
            isPenaltyDraw={data?.penalty?.[0] === data?.penalty?.[1]}
          />
        </div>
        <div
          className={
            'bg-gray-100 rounded-sm text-center text-sm font-roboto bg-gray-125 text-gray-650 py-1 md:mt-3'
          }
        >
          {realTimeStatus[matchStatus] ? (
            <>
              Current Score:
              {data.Athlete1.sets.length > 1 || data.Athlete2.sets.length > 1
                ? data.scoringType === 'PointBased'
                  ? sumOfPoints(data.Athlete1.sets)
                  : data.Athlete1.wonIn
                : data.Athlete1.sets?.[0]?.Points}{' '}
              {data?.isPenaltyEnabled && data?.penalty.find((el) => el)
                ? `(${data?.penalty?.[0]})`
                : ''}
              -
              {data.Athlete1.sets.length > 1 || data.Athlete2.sets.length > 1
                ? data.scoringType === 'PointBased'
                  ? sumOfPoints(data.Athlete2.sets)
                  : data.Athlete2.wonIn
                : data.Athlete2.sets?.[0]?.Points}
              {data?.isPenaltyEnabled && data?.penalty.find((el) => el)
                ? `(${data?.penalty?.[1]})`
                : ''}
            </>
          ) : !data.is_draw || (data?.isPenaltyEnabled && data?.penalty.find((el) => el)) ? (
            <>
              {data?.Athlete1?.isWon
                ? data?.Athlete1?.name?.split('(')?.[0]
                : data?.Athlete2?.name?.split('(')?.[0]}{' '}
              Beat{' '}
              {data?.Athlete2?.isWon
                ? data?.Athlete1?.name?.split('(')?.[0]
                : data?.Athlete2?.name?.split('(')?.[0]}{' '}
              by{' '}
              {data.Athlete1.sets.length > 1 || data.Athlete2.sets.length > 1
                ? data.scoringType === 'PointBased'
                  ? Math.max(sumOfPoints(data.Athlete1.sets), sumOfPoints(data.Athlete2.sets))
                  : Math.max(data.Athlete1.wonIn, data.Athlete2.wonIn)
                : Math.max(data.Athlete1.sets?.[0]?.Points, data.Athlete2.sets?.[0]?.Points)}
              {data?.isPenaltyEnabled && data?.penalty.find((el) => el)
                ? `(${Math.max(data?.penalty?.[1], data?.penalty?.[0])})`
                : ''}
              -
              {data.Athlete1.sets.length > 1 || data.Athlete2.sets.length > 1
                ? data.scoringType === 'PointBased'
                  ? Math.min(sumOfPoints(data.Athlete1.sets), sumOfPoints(data.Athlete2.sets))
                  : Math.min(data.Athlete2.wonIn, data.Athlete1.wonIn)
                : Math.min(data.Athlete1.sets?.[0]?.Points, data.Athlete2.sets?.[0]?.Points)}
              {data?.isPenaltyEnabled && data?.penalty.find((el) => el)
                ? `(${Math.min(data?.penalty?.[1], data?.penalty?.[0])})`
                : ''}
            </>
          ) : (
            <>
              The Match Ended in a Draw with{' '}
              {data.Athlete1.sets.length > 1 || data.Athlete2.sets.length > 1
                ? data.scoringType === 'PointBased'
                  ? Math.max(sumOfPoints(data.Athlete1.sets), sumOfPoints(data.Athlete2.sets))
                  : Math.max(data.Athlete1.wonIn, data.Athlete2.wonIn)
                : Math.max(data.Athlete1.sets?.[0]?.Points, data.Athlete2.sets?.[0]?.Points)}
              -
              {data.Athlete1.sets.length > 1 || data.Athlete2.sets.length > 1
                ? data.scoringType === 'PointBased'
                  ? Math.min(sumOfPoints(data.Athlete1.sets), sumOfPoints(data.Athlete2.sets))
                  : Math.min(data.Athlete2.wonIn, data.Athlete1.wonIn)
                : Math.min(data.Athlete1.sets?.[0]?.Points, data.Athlete2.sets?.[0]?.Points)}
            </>
          )}
        </div>

        {(showFinalHideFinal[data.sport.toLowerCase()]?.sub_category?.[data.sub_category] ===
          undefined ||
          showFinalHideFinal[data.sport.toLowerCase()]?.sub_category?.[data.sub_category]) &&
        (hideFinalFlag === undefined || hideFinalFlag.sets) &&
        (data.Athlete1.sets.length > 1 || data.Athlete2.sets.length > 1) ? (
          <>
            {' '}
            <h4 className={'font-roboto font-bold text-smd text-black200 md:mt-4'}>Final Score</h4>
            <div className={`w-full overflow-x-scroll  rounded-lg border-1 p-2 flex justify-start`}>
              <div className="flex mx-auto">
                {data.Athlete1.sets.length > 1 ? (
                  data.Athlete1.sets.map((set, index) => (
                    <div className={'w-32 flex items-center flex-col gap-2 py-1'} key={index}>
                      <div
                        className={
                          'text-sm font-bold border-b-1 w-full py-1 px-2 flex justify-left items-center gap-1 ml-6'
                        }
                      >
                        {`${set.name.charAt(0).toUpperCase()}${set.name.substring(1)}`}
                        <FontAwesomeIcon icon={faCaretDown} className={'text-gray-250 ml-2'} />
                      </div>
                      <div className="flex flex-col gap-2 w-full text-center ml-10">
                        <div
                          style={{ background: data.Athlete1.color }}
                          className={
                            'p-1 text-sm rounded-md text-white font-medium font-roboto w-8 md:my-1.5'
                          }
                        >
                          {data.Athlete1.sets[index]?.Points}
                        </div>
                        <div
                          style={{ background: data.Athlete2.color }}
                          className={
                            'p-1 text-sm rounded-md text-white font-medium font-roboto w-8 md:my-1.5'
                          }
                        >
                          {data.Athlete2.sets[index]?.Points}
                        </div>
                      </div>
                    </div>
                  ))
                ) : data.Athlete1.sets.length === 1 ? (
                  <>
                    <div
                      className={'font-bold font-sm font-roboto flex justify-center items-center'}
                    >
                      {data.Athlete1.sets[0].name}
                    </div>
                    <div className={'flex gap-4 justify-center items-center'}>
                      <div
                        className={'py-1 text-white rounded-lg font-bold font-roboto px-4'}
                        style={{ background: data.Athlete1.color }}
                      >
                        {data.Athlete1.sets?.[0]?.Points}
                      </div>
                      <div
                        className={'text-white font-bold font-roboto rounded-lg px-4 py-1'}
                        style={{ background: data.Athlete2.color }}
                      >
                        {data.Athlete2?.sets[0]?.Points}
                      </div>
                    </div>
                  </>
                ) : null}
                {data?.isPenaltyEnabled && data?.penalty.find((el) => el) ? (
                  <div className={'w-32 flex items-center flex-col gap-2 py-1'}>
                    <div
                      className={
                        'text-sm font-bold border-b-1 w-full py-1 px-2 flex justify-left items-center gap-1 ml-6 truncate'
                      }
                    >
                      {`Penalty Shootout`}
                      <FontAwesomeIcon icon={faCaretDown} className={'text-gray-250 ml-2'} />
                    </div>
                    <div className="flex flex-col gap-2 w-full text-center ml-10">
                      <div
                        style={{ background: data.Athlete1.color }}
                        className={
                          'p-1 text-sm rounded-md text-white font-medium font-roboto w-8 md:my-1.5'
                        }
                      >
                        {data.penalty?.[0]}
                      </div>
                      <div
                        style={{ background: data.Athlete2.color }}
                        className={
                          'p-1 text-sm rounded-md text-white font-medium font-roboto w-8 md:my-1.5'
                        }
                      >
                        {data.penalty?.[1]}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {/* {data.Athlete1.sets
              .map((keys) =>
                Object.keys(keys).some((el) => el !== 'Points' && el !== 'name' && el !== '')
              )
              .find((el) => el) ? (
              <>
                <h1 className={'font-bold text-smd font-roboto text-black200 md:mt-4'}>
                  Sets Details
                </h1>
                <div className={'flex flex-col'}>
                  {data.Athlete1.sets.length > 1 ? (
                    <Tabs
                      tabList={data.Athlete1.sets.map((item, index) => {
                        return {
                          name: `${item.name.charAt(0).toUpperCase()}${item.name.substring(1)}`,
                          component: <PointsGraph index={index} data={data} />,
                        };
                      })}
                      border="border-black200"
                      isDetailedScoring={true}
                    />
                  ) : data.Athlete1.sets.length === 1 ? (
                    <PointsGraph index={0} data={data} />
                  ) : null}
                </div>
              </>
            ) : null} */}
          </>
        ) : null}
      </div>
    </div>
  );
};

export const PointsGraph = ({ index, data }) => {
  return (
    <div className={'flex flex-col gap-1 py-1'}>
      {Object.keys(data.Athlete1.sets[index]).map(
        (key, i) =>
          key !== 'name' && (
            <GraphListItem
              key={i}
              data={{
                Athlete1: data.Athlete1.sets[index][key],
                Athlete2: data.Athlete2.sets[index][key],
                key,
                Athlete1Color: data.Athlete1.color,
                Athlete2Color: data.Athlete2.color,
              }}
            />
          )
        /*
                                                <>
                                                  {JSON.stringify(data.Athlete1.sets[index])}
                                                  <br />
                                                </>
                                        */
      )}
    </div>
  );
};

export const GraphListItem = ({ data }) => {
  const max = Math.max(data.Athlete1, data.Athlete2, 15) || 1;
  return (
    <div className={'flex gap-1 justify-center mt-2'}>
      <div className="w-1/3 bg-gray-200 rounded-md flex justify-end">
        <div
          className={`text-xxs md:text-xs font-bold font-roboto text-blue-100 text-center leading-none rounded-r-md h-full flex items-center justify-end ${
            (data.Athlete1 / max) * 100 === 100 && 'rounded-l-md'
          }`}
          style={{ width: `${(data.Athlete1 / max) * 100}%`, background: data.Athlete1Color }}
        >
          <div
            className={`px-1 ${data.Athlete1 === '0' ? 'text-black200 font-bold' : 'text-white'}`}
          >
            {data.Athlete1}
          </div>
        </div>
      </div>
      <div
        className={'w-30 md:w-1/3 text-xs md:text-sm text-center truncate font-roboto'}
        style={{
          fontWeight: 500,
        }}
      >
        {data.key}
      </div>
      <div className="w-1/3 bg-gray-200 rounded-md flex justify-start">
        <div
          className={`text-xxs md:text-xs font-bold font-roboto text-blue-100 text-center leading-none rounded-l-md h-full flex items-center justify-start ${
            (data.Athlete2 / max) * 100 === 100 && 'rounded-r-md'
          }`}
          style={{ width: `${(data.Athlete2 / max) * 100}%`, background: data.Athlete2Color }}
        >
          <div
            className={`px-1 invert ${
              data.Athlete2 === '0' ? 'text-black200 font-bold' : 'text-white'
            }`}
          >
            {data.Athlete2}
          </div>
        </div>
      </div>
    </div>
  );
};

export const AthleteInformationCard = ({
  data,
  isReverse = false,
  isCompact = false,
  noColor = false,
  isFontSmall = false,
  isDraw = false,
  scoringType,
  matchStatus,
  isPenaltyEnabled,
  penaltyWon,
  isPenaltyDraw,
}) => {
  return (
    <div
      className={`flex justify-start items-center lg:gap-4 ${
        isCompact ? 'gap-1' : 'gap-2'
      } flex-grow ${isReverse ? 'flex-row-reverse' : ''}`}
    >
      {!noColor && <div className={'w-2 h-2 rounded-full'} style={{ background: data.color }} />}
      <img className={'w-10 h-10 rounded-full'} src={data.image} alt={''} />
      <div className={`${!isCompact && isFontSmall && 'flex w-full items-center'}`}>
        <div
          className={`flex flex-col flex-grow gap-1 justify-between ${
            isReverse ? 'items-end' : 'items-start'
          }`}
        >
          <div
            className={`font-roboto ${
              isFontSmall ? 'text-sm font-medium text-black200' : 'text-smd font-bold text-black200'
            }`}
          >
            {isCompact ? data.name.split(' ')[0] : data.name}
          </div>
          {/* {!isCompact ? ( */}
          {/*  <div className={'font-roboto text-xs text-gray-650'}>{data.institution}</div> */}
          {/* ) : null} */}
          {isCompact ? null : (
            <>
              {realTimeStatus[matchStatus] ? null : isDraw &&
                (isPenaltyDraw || !isPenaltyEnabled) ? (
                <div
                  className={
                    'font-roboto font-bold text-mini text-white px-1.5 py-0.5 rounded bg-blue-950 w-content'
                  }
                >
                  <span className={'font-bold'}>DRAW</span>
                </div>
              ) : data.isWon || penaltyWon ? (
                <div
                  className={
                    'font-roboto font-bold text-mini text-white px-1.5 py-0.5 rounded bg-green-550 w-content'
                  }
                >
                  <span className={'font-bold'}>WON</span>
                </div>
              ) : (
                <div
                  className={
                    'font-roboto font-bold text-mini text-white px-1.5 py-0.5 bg-red-500 rounded'
                  }
                >
                  <span className={'font-bold'}>LOST</span>
                </div>
              )}
              <div className={'font-roboto text-xs text-gray-650'}>{data.institution}</div>
            </>
          )}
        </div>
        {isCompact
          ? null
          : isFontSmall && (
              <div
                style={{ background: data.color }}
                className={
                  'text-center pt-1 w-8 h-8 text-sm rounded-md text-white font-bold font-roboto'
                }
              >
                {scoringType !== 'PointBased'
                  ? data.sets.length > 1
                    ? data.wonIn
                    : data.sets?.[0]?.Points
                  : data?.sets?.reduce((prev, curr) => prev + curr.Points, 0)}
              </div>
            )}
      </div>
    </div>
  );
};
