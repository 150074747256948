export function roundOfTwoDigits(amount) {
  if (amount) return Math.floor((amount || 0) * 100) / 100;
  return 0;
}

export function roundOfToDigit(amount, n) {
  if (amount) return Math.floor(((amount || 0) * Math.pow(10, n)) / Math.pow(10, n));
}

export function roundOfToInt(amount) {
  if (amount) return Math.floor(amount || 0);
  return 0;
}
