import Header from './Header';
import Tabs from 'components/Tab';
import { calculateConditions } from './index';
import infoIconGrey from '../../../assets/img/info_iconGrey.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useShowKhelo } from 'utils/HooksUtils';
import { useParticipantsDetails, useDsScoring } from 'micro-site/queries/hooks';
import { sumOfPoints } from 'profiles/Tournaments/MatchFixtures/components/MatchCenterModal';
const Draws = ({ expandedView, setExpandedView, opponent, setOpponent, userData }) => {
  const isKhelo = useShowKhelo();

  const participants = useParticipantsDetails({ match: expandedView?.match?.match });
  const query = useDsScoring({
    matchId: expandedView?.match?.match,
  });
  if (participants.isFetching || query?.isFetching) return null;
  const {
    opponent_1: opponent1,
    opponent_2: opponent2,
    is_team_sport: isTeam,
  } = participants?.data || {};
  const data = query?.data;
  const opponent1ADS =
    data?.Athlete1.mp_id === opponent1?.[0]?.mp_id ? data?.Athlete1 : data?.Athlete2;
  const opponent2ADS =
    data?.Athlete2.mp_id === opponent2?.[0]?.mp_id ? data?.Athlete2 : data?.Athlete1;

  const score1 =
    (opponent1ADS.sets.length > 1 || opponent2ADS.sets.length > 1
      ? data.scoringType === 'PointBased'
        ? sumOfPoints(opponent1ADS.sets)
        : opponent1ADS.wonIn
      : opponent1ADS.sets?.[0]?.Points) +
    '' +
    (+data?.isPenaltyEnabled && data?.penalty.find((el) => el) ? `(${data?.penalty?.[0]})` : '');

  const score2 =
    (opponent1ADS.sets.length > 1 || opponent2ADS.sets.length > 1
      ? data.scoringType === 'PointBased'
        ? sumOfPoints(opponent2ADS.sets)
        : opponent2ADS.wonIn
      : opponent2ADS.sets?.[0]?.Points) +
    '' +
    (data?.isPenaltyEnabled && data?.penalty.find((el) => el) ? `(${data?.penalty?.[1]})` : '');

  const opponentsData = {
    opponent_1: opponent1,
    opponent_2: opponent2,
  };
  const draw = data?.is_draw;
  return (
    <div className="max-w-3xl mx-auto bg-white">
      <Header
        hideTabs
        variant={'sport'}
        ageGroup={expandedView.sport_event.age_group}
        eventName={expandedView.sport_event.sport}
        time={expandedView.match.time}
        venue={expandedView.match.venue}
        gender={expandedView.sport_event.gender}
        img={expandedView.sport_event.sport_image}
        userData={userData}
      />
      {!isTeam ? (
        <Tabs
          color={`${isKhelo ? 'text-blue-kheloBlue' : 'text-purple-550'}`}
          border={`${isKhelo ? 'border-blue-kheloBlue' : 'border-purple-550'}`}
          tabList={[
            { name: 'Opponent 1', component: <></>, callback: () => setOpponent('opponent_1') },
            { name: 'Opponent 2', component: <></>, callback: () => setOpponent('opponent_2') },
          ]}
        />
      ) : null}
      <div className="p-4 bg-white">
        <div
          className="font-bold flex gap-2 items-center my-2 cursor-pointer"
          onClick={() => setExpandedView(null)}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          Draws and Schedules
        </div>
        {!isTeam ? (
          <div className="flex flex-col items-center gap-2 shadow-shadowBottom pb-4">
            <div
              className={`w-30 h-30 rounded-full border-2 ${
                !draw
                  ? opponentsData?.[opponent]?.[0]?.is_won
                    ? 'border-green-450'
                    : 'border-red-350'
                  : ''
              }
              relative`}
            >
              <img src={opponentsData?.[opponent]?.[0]?.is_won} />
              <div
                className={`bottom-0 absolute ${
                  !draw
                    ? opponentsData?.[opponent]?.[0]?.is_won
                      ? 'bg-green-450'
                      : 'bg-red-350'
                    : ''
                } py-0.5 px-1.5 font-bold flex items-center fit-content justify-center text-xxs rounded text-white transform -translate-x-1/2 translate-y-1/2 left-1/2 whitespace-nowrap`}
              >
                {!draw
                  ? calculateConditions(
                      opponentsData?.[opponent]?.[0]?.is_won,
                      expandedView.match.match_status,
                      draw
                    )
                  : ''}
              </div>
            </div>
            <div className="py-0.5 px-1 flex gap-2 rounded bg-gray-300 mt-5 text-xxs text-gray-450 font-semibold">
              <img src={infoIconGrey} className="" />
              {expandedView?.match?.match_status}
            </div>
            <h3 className="text-lg font-bold">{opponentsData?.[opponent]?.[0]?.name}</h3>
            <p className="font-xs text-gray-650">{opponentsData?.[opponent]?.[0]?.institution}</p>
          </div>
        ) : null}
        <h2 className="font-bold text-lg mt-4">Score</h2>
        <hr className="w-16 md:hidden mx-0 my-0 bg-gray-750 h-0.5 md:h-1 mt-1" />
        {!isTeam ? (
          <div className={`flex-col mt-4 rounded-lg overflow-hidden ${'bg-active-schedule'}`}>
            <MobileScore
              name={opponentsData?.opponent_1?.[0]?.name}
              score={score1}
              won={opponentsData?.opponent_1?.[0]?.is_won}
              image={opponentsData?.opponent_1?.[0]?.image}
              draw={draw}
            />
            <MobileScore
              name={opponentsData?.opponent_2?.[0]?.name}
              score={score2}
              won={opponentsData?.opponent_2?.[0]?.is_won}
              image={opponentsData?.opponent_2?.[0]?.image}
              draw={draw}
            />
          </div>
        ) : (
          <>
            {' '}
            <div className={`flex-col mt-4 rounded-lg overflow-hidden ${'bg-active-schedule'}`}>
              <div className="border-b-1 border-gray-650 relative">
                <div className="absolute transform -translate-y-1/2 top-1/2 right-4">
                  {opponent1?.[0]?.athletes?.length ? (
                    <p className="text-white text-xs">
                      <span className="opacity-60">Score</span>{' '}
                      {score1 !== 'NA' && score1 !== undefined && score1 !== 'undefined'
                        ? score1 || '-'
                        : '-'}
                    </p>
                  ) : null}
                </div>
                {opponent1?.[0]?.athletes?.map((player, i) => (
                  <MobileScore
                    key={i}
                    name={player.name}
                    hideBorder
                    hideScore
                    score={score1}
                    won={opponent1?.[0]?.is_won}
                    image={player.image}
                    draw={draw}
                  />
                ))}
              </div>
              <div className="relative">
                <div className="absolute transform -translate-y-1/2 top-1/2 right-4">
                  {opponent2?.[0]?.athletes?.length ? (
                    <p className="text-white text-xs">
                      <span className="opacity-60">Score</span>{' '}
                      {score2 !== 'NA' && score2 !== undefined && score2 !== 'undefined'
                        ? score2 || '-'
                        : '-'}
                    </p>
                  ) : null}
                </div>
                {opponent2?.[0]?.athletes?.map((player, i) => (
                  <MobileScore
                    key={i}
                    hideBorder
                    hideScore
                    name={player.name}
                    score={score2}
                    won={opponent2?.[0]?.is_won}
                    image={player.image}
                    draw={draw}
                  />
                ))}
              </div>
            </div>
          </>
        )}

        {isTeam ? (
          <>
            <div className="mt-4"></div>
            <Tabs
              color={`${isKhelo ? 'text-blue-kheloBlue' : 'text-purple-550'}`}
              border={`${isKhelo ? 'border-blue-kheloBlue' : 'border-purple-550'}`}
              tabList={[
                { name: 'Opponent 1', component: <></>, callback: () => setOpponent('opponent_1') },
                { name: 'Opponent 2', component: <></>, callback: () => setOpponent('opponent_2') },
              ]}
            />
            <div className="mt-8 grid grid-cols-2 gap-4 md:flex md:flex-wrap md:gap-8 md:items-center md:justify-between">
              {opponentsData[opponent]?.[0]?.athletes?.map((player, i) => (
                <div className="flex flex-col items-center gap-2" key={i}>
                  <div
                    className={`w-18 h-18 rounded-full border-2 ${
                      !draw
                        ? opponentsData[opponent]?.is_won
                          ? 'border-green-450'
                          : 'border-red-350'
                        : ''
                    }
              relative`}
                  >
                    <img src={player?.image} />
                    <div
                      className={`bottom-0 absolute ${
                        !draw
                          ? opponentsData[opponent]?.[0].is_won
                            ? 'bg-green-450'
                            : 'bg-red-350'
                          : ''
                      } py-0.5 px-1.5 font-bold flex items-center fit-content justify-center text-xxs rounded text-white transform -translate-x-1/2 translate-y-1/2 left-1/2 whitespace-nowrap`}
                    >
                      {!draw
                        ? calculateConditions(
                            opponentsData[opponent]?.[0].is_won,
                            expandedView.match.match_status,
                            draw
                          )
                        : ''}
                    </div>
                  </div>
                  <div className="py-0.5 px-1 flex gap-2 rounded bg-gray-300 mt-1 text-xxs text-gray-450 font-semibold">
                    <img src={infoIconGrey} className="" />
                    {player.sfa_id}
                  </div>
                  <h3 className=" font-bold">{player?.name}</h3>
                  <p className="text-xs text-gray-650">{player.institution}</p>
                </div>
              ))}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
function MobileScore({ name, score, won, image, display, draw, hideBorder, hideScore }) {
  const isKhelo = useShowKhelo();
  return (
    <div
      className={`py-4 px-4 flex gap-2 ${isKhelo && 'bg-gray-750'} text-white items-center ${
        hideBorder ? '' : 'border-t-1'
      } border-gray-625`}
    >
      {' '}
      <img
        className={`w-6 h-6 rounded-full border-2 ${
          !draw ? (won ? 'border-green-450' : 'border-red-350') : ''
        } relative`}
        src={image}
        // style={{
        //   background: `url('${image}')`,
        //   backgroundSize: 'cover',
        //   backgroundPosition: 'center',
        //   backgroundRepeat: 'no-repeat',
        // }}
      />
      <div className="flex justify-between w-full ">
        <p className="text-sm">{name}</p>
        {!hideScore ? (
          <p className="text-white text-xs">
            <span className="opacity-60">Score</span>{' '}
            {score !== 'NA' && score !== undefined && score !== 'undefined' ? score || '-' : '-'}
          </p>
        ) : null}
      </div>
    </div>
  );
}

export default Draws;
