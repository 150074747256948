import React, { useRef } from 'react';
import Loader from '../../../../components/Loader';
import ShowMedia from './showMedia';
import ListLoader from '../../../../components/ListLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import GalleryCard from '../../../Components/GalleryCard';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import GalleryCardMobile from '../../../Components/GalleryCardMobile';
import { noMediaText } from 'data/noDataText';

const SectionSliderMesh = ({
  sectionName,
  redirectTo,
  previewType,
  previewCount,
  sectionFolders,
  hideViewAll,
  hideSectionName,
  parentClasses,
}) => {
  const history = useHistory();
  const sliderRef = useRef(null);
  const settings = {
    className: 'center',
    centerMode: true,
    // slidesToShow: 2,
    initialSlide: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          className: 'center',
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          className: 'center',
          centerMode: true,
          centerPadding: '10%',
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          className: 'center',
          centerMode: true,
          centerPadding: '10%',
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={parentClasses || 'mt-10 md:mt-16'}>
      {!hideSectionName ? (
        <div className="flex justify-between items-center md:shadow-shadowBottom md:mb-6 md:py-2 pt-2">
          {!hideSectionName ? (
            <h3 className="text-base md:text-2xl font-bold text-gray-750 normal-case md:uppercase font-poppins">
              {sectionName}
            </h3>
          ) : null}
          {!hideViewAll ? (
            <div
              className="flex text-xs md:text-base gap-1 md:gap-2 items-center text-gray-750 font-semibold cursor-pointer border-1 border-gray-750 rounded md:rounded-none md:border-0 px-2 py-1 md:px-0 md:py-0"
              onClick={() => history.push(`${redirectTo}/${sectionName}`)}
            >
              <p>View All</p>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          ) : null}
        </div>
      ) : null}
      {!hideSectionName ? (
        <hr className="w-16 md:hidden mx-0 my-0 md:w-60 bg-gray-750 h-0.5 md:h-1 mt-1 md:mt-6" />
      ) : null}

      {previewType === 'Mesh' ? (
        <>
          <div className="hidden lg:flex mt-6 justify-center flex-wrap gap-1.5">
            <ListLoader
              queryData={sectionFolders}
              queryKey={'name'}
              showLimited
              limit={previewCount}
              EmptyMessage={noMediaText}
            >
              {(item, index) => {
                // console.log(item.data);
                // return <></>;

                return (
                  <GalleryCard
                    key={index}
                    item={item.data}
                    redirectTo={`${redirectTo}/${item?.data?.name?.split('-')?.[0]}/${
                      item?.data?.name?.split('-')?.[1]
                    }`}
                  />
                );
              }}
            </ListLoader>
          </div>

          <div className="mt-6 justify-center flex flex-wrap gap-1.5 lg:hidden">
            <ListLoader
              queryData={sectionFolders}
              queryKey={'name'}
              showLimited
              limit={30}
              EmptyMessage={noMediaText}
            >
              {(item, idx) => (
                <GalleryCardMobile
                  key={idx}
                  item={item.data}
                  redirectTo={`${redirectTo}/${item?.data?.name?.split('-')?.[0]}/${
                    item?.data?.name?.split('-')?.[1]
                  }`}
                />
              )}
            </ListLoader>
          </div>
        </>
      ) : (
        <div className="mt-6 main-slider">
          <div className="relative">
            <button
              onClick={() => {
                sliderRef.current && sliderRef.current.slickNext();
              }}
              className="hidden md:inline-block absolute -right-4 bg-white rounded-full h-8 w-8 shadow-lg z-10 transform -translate-y-1/2  top-1/2 cursor-pointer"
            >
              <FontAwesomeIcon icon={faAngleRight} className="text-gray-450" />
            </button>
            <button
              onClick={() => {
                sliderRef.current && sliderRef.current.slickPrev();
              }}
              className="hidden md:inline-block  absolute left-0 bg-white rounded-full h-8 w-8 shadow-lg z-10 top-1/2 cursor-pointer transform -translate-y-1/2 -translate-x-1/2"
            >
              <FontAwesomeIcon icon={faAngleLeft} className="text-gray-450" />
            </button>
            <Slider
              ref={sliderRef}
              slidesToShow={
                sectionFolders.isLoading
                  ? 1
                  : sectionFolders.data.length >= 3
                  ? 3
                  : sectionFolders.data.length
              }
              {...settings}
            >
              {sectionFolders.isLoading ? (
                <Loader />
              ) : (
                sectionFolders.data.map((data, index) => {
                  return (
                    <div
                      key={index}
                      onContextMenu={(e) => e.preventDefault()}
                      className="card-bg cursor-pointer w-full h-52 md:h-60 md:mx-4 carousel-item relative rounded-lg overflow-hidden"
                      onClick={() =>
                        history.push(
                          `${redirectTo}/${data?.name?.split('-')?.[0]}/${
                            data?.name?.split('-')?.[1]
                          }`
                        )
                      }
                    >
                      <ShowMedia
                        className="w-full h-full main_img block m-auto object-cover rounded-lg"
                        mediaName={data.preview_image}
                        previewImage={data.preview_image}
                        ImageOnly
                      />
                      <div className="flex flex-col md:items-end md:flex-row  justify-between absolute inset-0 p-4 md:p-8 bg-gradient-to-b from-transparent to-gray-900">
                        <div>
                          <h4 className="text-white text-xs md:text-lg font-bold mt-1">
                            {data.folder_name}
                          </h4>
                          <p className="text-white text-xxs md:text-xs md:mt-1"></p>
                        </div>
                        <p className="text-white text-xs md:text-sm md:hidden">View More</p>
                      </div>
                    </div>
                  );
                })
              )}
            </Slider>
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionSliderMesh;
