import { useEffect, useRef } from 'react';

// modern version backported from  https://www.npmjs.com/package/react-jotform-embed to reduce size

export default function JotformEmbed(props) {
  const iframeStyle = props.styles || {
    width: '100%',
    minHeight: '100vh',
  };
  const iframeRef = useRef(null);
  function handleIframeMessage(e) {
    if (!e.data.split) {
      return;
    }
    const args = e.data.split(':');
    const formId = args[2];
    const iframe = iframeRef.current;
    if (!!iframe && (!formId || props.src.split('?')[0].endsWith(formId))) {
      switch (args[0]) {
        case 'scrollIntoView':
          iframe.scrollIntoView();
          break;
        case 'setHeight':
          iframe.style.height = `${args[1]}px`;
          break;
        case 'collapseErrorPage':
          if (iframe.clientHeight > global.innerHeight) {
            iframe.style.height = `${global.innerHeight}px`;
          }
          break;
        case 'reloadPage':
          global.location.reload();
          break;
      }
      const isJotForm = e.origin.indexOf('jotform') > -1;
      if (isJotForm && 'contentWindow' in iframe && 'postMessage' in iframe.contentWindow) {
        const urls = {
          docurl: encodeURIComponent(global.document.URL),
          referrer: encodeURIComponent(global.document.referrer),
        };
        iframe.contentWindow.postMessage(JSON.stringify({ type: 'urls', value: urls }), '*');
      }
    }
  }

  useEffect(() => {
    if (global.addEventListener) {
      global.addEventListener('message', handleIframeMessage, false);
    } else if (global.attachEvent) {
      global.attachEvent('onmessage', handleIframeMessage);
    }
    return () => {
      if (global.removeEventListener) {
        global.removeEventListener('message', handleIframeMessage, false);
      } else if (global.detachEvent) {
        global.detachEvent('onmessage', handleIframeMessage);
      }
    };
  }, []);

  return (
    <iframe
      ref={iframeRef}
      style={iframeStyle}
      className={props.className}
      src={props.src}
      frameBorder={0}
      scrolling={props.scrolling ? 'yes' : 'no'}
    />
  );
}
