import { useState, useEffect } from 'react';
import DrawEventForm from 'micro-site/Components/DrawEventForm';
import { useSportEvent } from 'micro-site/queries/hooks';
import Header from './Header';
import { useHistory, useParams } from 'react-router-dom';
const Draws = ({ headerProps }) => {
  const [params, setParams] = useState('');
  const actualParams = useParams();
  const history = useHistory();
  const sportEventQueryData = useSportEvent({
    tournament: actualParams?.tournament_id,
    sport: params?.sport,
    gender: params?.gender,
    age_group: params?.age_group,
    sport_category: params?.sport_category,
    sub_category: params?.sub_category,
  });
  const handleDrawFormat = (schema) => {
    setParams(schema);
  };
  useEffect(() => {
    sportEventQueryData.refetch();
  }, [params]);
  if (sportEventQueryData.isFetched === true && params) {
    try {
      history.push(
        `/public/score/${sportEventQueryData.data?.[0].digital_scoring_format?.toLowerCase()}/${encodeURIComponent(
          sportEventQueryData.data?.[0].sport_event
        )}`
      );
    } catch (error) {
      console.debug(error.message);
    }
  }

  return (
    <div className="mt-16 px-4">
      <div className=" pb-3 mb-4 flex justify-center">
        <Header {...headerProps} />
        {/* <div
    className="flex gap-2 items-center cursor-pointer"
    onClick={() => setScreen('winner_list')}
  >
    <p className="font-semibold">View All Winners</p>
    <FontAwesomeIcon icon={faAngleRight} className="w-3 h-3" />
  </div> */}
      </div>
      <div className="draw-form">
        {/* <Form
        form={form}
        url={process.env.REACT_APP_API_URL}
        onSubmit={(schema) => handleDrawFormat(schema)}
      ></Form> */}
        <DrawEventForm
          handleDrawFormat={handleDrawFormat}
          customSubmitText={'View Results'}
          isLoading={sportEventQueryData.isLoading}
        />
      </div>
    </div>
  );
};

export default Draws;
