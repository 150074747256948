import { useInfiniteQuery, useQuery } from 'react-query';
import { getUserProfile } from 'authentication/services/authentication';
import {
  canShowDigitalScoring,
  getAccommodationDetails,
  getAchievementFilters,
  getAchievements,
  getAdvanceDs,
  getAllSportEventsUnderTournamentList,
  getCoachVerificationStatus,
  getContingentDetails,
  getContingentFilters,
  getContingentList,
  getDashboardsList,
  getInstitutionVerificationStatus,
  getMatchAnalytics,
  getMatchDetailsBySportEvent,
  getMatchFixtureDates,
  getMatchFixturesList,
  getMedalWinnerBySportEvent,
  getNotificationsList,
  getProfileBillingInformation,
  getProfileOverview,
  getProfileRank,
  getProfileVideos,
  getSportEventList,
  getSportListByTournamentId,
  getSportVideoId,
  getSubmittedFormData,
  getTeamConfimationForm,
  getTeamDetails,
  getTeamMatchFixtures,
  getTeamMedalWinners,
  getTeamPlayersList,
  getTeamUnderSportEventList,
  getTournamentDetails,
  getTournamentEventsList,
  getTournamentList,
  getProfileDetailsId,
  getCateringLog,
  getTournamentRankStats,
  getAthleteStats,
  getTeamStats,
} from '../services';
import QUERY_KEYS from './queryKeys';
import { getHome } from 'micro-site/services';

export const useUserProfile = (_params, enabled = true) => {
  return useQuery(QUERY_KEYS.LOGGED_IN_USER_PROFILE, () => getUserProfile(), {
    enabled,
    retry: false,
  });
};

export const useTournamentDetails = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_TOURNAMENTS_DETAILS, params.tournamentId],
    () => getTournamentDetails(params),
    {
      retry: false,
    }
  );
};

export const useAccommodationDetails = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_ACCOMMODATION_DETAILS, params.user_id],
    () => getAccommodationDetails(params),
    {
      retry: false,
    }
  );
};

export const useTournamentList = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_TOURNAMENTS_LIST, params.active],
    () => getTournamentList(params.active),
    {
      retry: false,
    }
  );
};

export const useNotificationsList = () => {
  return useQuery([...QUERY_KEYS.GET_NOTIFICATIONS_LIST], () => getNotificationsList(), {
    retry: false,
  });
};

export const useTournamentsEventsList = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_TOURNAMENT_EVENTS_LIST, params.tournament_id],
    () => getTournamentEventsList(params),
    {
      retry: false,
    }
  );
};

export const useMatchDetailsBySportEvent = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_MATCH_DETAILS_BY_SPORT_EVENT, params.sportEvent],
    () => getMatchDetailsBySportEvent(params),
    {
      retry: false,
    }
  );
};

export const useMedalWinnerBySportEvent = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_MEDAL_WINNER_BY_SPORT_EVENT, params.sportEvent],
    () => getMedalWinnerBySportEvent(params),
    {
      retry: false,
    }
  );
};

export const useSportListByTournamentId = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_SPORT_LIST_BY_TOURNAMENT_ID, params.tournamentId],
    () => getSportListByTournamentId(params),
    {
      retry: false,
    }
  );
};

export const useSportEventList = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_SPORT_EVENT_LIST, params.tournamentId, params.sport],
    () => getSportEventList(params),
    {
      retry: false,
    }
  );
};

export const useMatchFixtureDates = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_MATCH_FIXTURE_DATES, params.tournamentId, params.institution],
    () => getMatchFixtureDates(params),
    {
      retry: false,
    }
  );
};

export const useMatchFixturesList = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_MATCH_FIXTURE_LIST, params.tournamentId, params.institution, params.date],
    () => getMatchFixturesList(params),
    {
      retry: false,
    }
  );
};

export const useContingentList = (params) => {
  return useInfiniteQuery(
    [
      ...QUERY_KEYS.GET_CONTINGENT_LIST,
      params.tournamentId,
      params.sport,
      params.gender,
      params.age_group,
      params.verification_status,
      params.designation,
      params.type,
      params.sub_category,
      params.limit,
      params.sfa_id,
    ],
    ({ pageParam = 0 }) => getContingentList(params, pageParam),
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages?.length * params?.limit < lastPage?.total_records)
          return pages?.length * params?.limit;
        return undefined;
      },
      retry: false,
    }
  );
};

export const useContignentDetails = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_CONTINGENT_DETAILS, params.id],
    () => getContingentDetails(params),
    {
      retry: false,
    }
  );
};

export const useAllSportEventsUnderTournament = (params) => {
  return useQuery(
    [
      ...QUERY_KEYS.GET_ALL_SPORT_EVENTS_UNDER_TOURNAMENT_LIST,
      params.tournament_id,
      params.gender,
      params.age_category,
      params.sport,
      params.sport_category,
    ],
    () => getAllSportEventsUnderTournamentList(params),
    {
      retry: false,
    }
  );
};

export const useProfileOverview = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_PROFILE_OVERVIEW, params.id],
    () => getProfileOverview(params),
    {
      retry: false,
    }
  );
};

export const useTeamsUnderSportEventList = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_TEAM_UNDER_SPORT_EVENT_LIST, params.sport_event],
    () => getTeamUnderSportEventList(params),
    {
      retry: false,
    }
  );
};

export const useProfileRank = (params) => {
  return useQuery([...QUERY_KEYS.GET_PROFILE_RANK, params.id], () => getProfileRank(params), {
    retry: false,
  });
};

export const useTeamDetails = (params) => {
  return useQuery([...QUERY_KEYS.GET_TEAM_DETAILS, params.team_id], () => getTeamDetails(params), {
    retry: false,
  });
};

export const useTeamPlayersList = (params) => {
  return useQuery(
    [
      ...QUERY_KEYS.GET_TEAM_PLAYERS_LIST,
      params.sport_event,
      params.verification_status,
      params.team_id,
    ],
    () => getTeamPlayersList(params),
    {
      retry: false,
    }
  );
};

export const useTeamMedalWinners = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_TEAM_MEDAL_WINNERS, params.sport_event],
    () => getTeamMedalWinners(params),
    {
      retry: false,
    }
  );
};

export const useTeamMatchFixtures = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_TEAM_MATCH_FIXTURES, params.sport_event],
    () => getTeamMatchFixtures(params),
    {
      retry: false,
    }
  );
};

export const useTeamConfirmationForm = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_TEAM_CONFIRMATION_FORM, params.team_id],
    () => getTeamConfimationForm(params),
    {
      retry: false,
    }
  );
};

export const useSubmittedFormData = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_FORM_DATA, params.team_id],
    () => getSubmittedFormData(params),
    {
      retry: false,
    }
  );
};

export const useCoachVerficationStatus = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_COACH_VERIFICATION_STATUS, params.tournament_id],
    () => getCoachVerificationStatus(params),
    {
      retry: false,
    }
  );
};
export const useInstitutionVerificationStatus = (_params) => {
  return useQuery(
    [...QUERY_KEYS.GET_INSTITUTION_VERIFICATION_STATUS],
    () => getInstitutionVerificationStatus(),
    {
      retry: false,
    }
  );
};

export const useProfileBillingDetails = (_params) => {
  return useQuery(
    [...QUERY_KEYS.GET_PROFILE_BILLING_DETAILS],
    () => getProfileBillingInformation(_params),
    {
      retry: false,
    }
  );
};

export const useSportVideoId = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_SPORT_VIDEO_ID, params.matchId],
    () => getSportVideoId(params),
    {
      retry: false,
    }
  );
};

export const useShowDigitalScoring = ({ tournamentId }) => {
  return useQuery([...QUERY_KEYS.GET_DIGITAL_SCORING_SHOW, tournamentId], () =>
    canShowDigitalScoring({ tournamentId })
  );
};

export const useMatchAnalytics = ({ matchId }) => {
  return useQuery([...QUERY_KEYS.GET_MATCH_ANALYTICS, matchId], () =>
    getMatchAnalytics({ matchId })
  );
};

export const useAchievements = (params) => {
  return useQuery([...QUERY_KEYS.GET_ACHIEVEMENTS, params.medal, params.tournament], () =>
    getAchievements(params)
  );
};

export const useAchievementFilters = () => {
  return useQuery([...QUERY_KEYS.GET_ACHIEVEMENT_FILTERS], () => getAchievementFilters());
};

export const useProfileVideos = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_PROFILE_VIDEOS, params.sports],
    () => getProfileVideos(params),
    {
      retry: false,
    }
  );
};

export const useDashboardList = (params) => {
  return useQuery([...QUERY_KEYS.GET_DASHBOARD_LIST], () => getDashboardsList(params), {
    retry: false,
  });
};

export const useAdvanceDs = (params) => {
  return useQuery([...QUERY_KEYS.GET_ADVANCE_DS, params.matchId], () => getAdvanceDs(params), {
    retry: false,
  });
};

export const useContingentFilters = (params) => {
  return useQuery([...QUERY_KEYS.GET_CONTINGENT_FILTERS, params.tournamentId, params.type], () =>
    getContingentFilters(params)
  );
};

export const useProfileId = (params) => {
  return useQuery([...QUERY_KEYS.GET_PROFILE_ID, params.id], () => getProfileDetailsId(params));
};

export const useCateringId = (params) => {
  return useQuery([...QUERY_KEYS.GET_CATERING_LOG, params.id], () => getCateringLog(params));
};

export const useHome = (params) => {
  return useQuery([...QUERY_KEYS.GET_HOME, params.tournament], () => getHome(params));
};

export const useTournamentRankingStats = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_TOURNAMENT_RANKING_STATS, params.tournament, params.sport],
    () => getTournamentRankStats(params)
  );
};

export const useTournamentAthleteStats = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_TOURNAMENT_ATHLETE_STATS, params.tournament, params.sport],
    () => getAthleteStats(params)
  );
};
export const useTournamentTeamStats = (params) => {
  return useQuery([...QUERY_KEYS.GET_TOURNAMENT_TEAM_STATS, params.tournament, params.sport], () =>
    getTeamStats(params)
  );
};
