import Slider from 'react-slick';
import Header from './Header';
import styles from './photos.module.css';
import { Link, useParams } from 'react-router-dom';
import { useBreakPoint } from 'utils/HooksUtils';
const Photos = ({ heading, data }) => {
  const isLg = useBreakPoint('lg');
  const params = useParams();
  const settings = isLg
    ? {
        dots: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    : {
        dots: true,
        infinite: false,
        rows: 3,
        slidesPerRow: 1,
      };
  return (
    <div className="px-4 lg:px-16 py-12 bg-blue-homeSection">
      <div className="flex justify-center lg:justify-start">
        <Header {...heading} align={isLg ? 'left' : 'center'} borderWidth={'50%'} />
      </div>
      <div className="h-6 lg:h-12"></div>
      <Slider {...settings} className={isLg ? `photos-home-slider` : ''}>
        {data?.map((el, i) => (
          <PhotoCard {...el} key={i} />
        ))}
      </Slider>
      <Link to={`/micro-site/${params.tournament_id}/landing/gallery`}>
        <div className="underline flex justify-end w-full mt-2">View All</div>
      </Link>
    </div>
  );
};

export default Photos;

function PhotoCard({ img, heading, description, link, mobile_img: mobileImg }) {
  const isLg = useBreakPoint('lg');
  return (
    <Link to={link} className="lg:h-full w-full lg:w-auto inline-block h-30">
      <div
        className={`font-montserrat text-blue-navBg bg-white hover:bg-blue-photoCards flex lg:flex-col lg:pb-4 grow-0 shrink-0 h-full cursor-pointer mb-4 ${styles.container}`}
        style={{ maxWidth: isLg ? '250px' : '100%' }}
      >
        <img
          className="object-cover w-1/2 lg:w-full"
          style={{ height: isLg ? '180px' : '100%', minHeight: isLg ? '104px' : '' }}
          src={isLg ? img : mobileImg}
        />
        <div>
          <div
            className="mt-5 mb-3 mx-3 bg-white flex"
            style={{ width: '140px', borderColor: 'rgb(227, 232, 255)', borderWidth: '1.5px' }}
          >
            <div
              className="shrink-0 bg-orange-kheloOrange grow-1 mr-2 "
              style={{ width: '5px' }}
            ></div>
            <div className="font-extrabold text-xxs lg:text-base">{heading}</div>
          </div>
          <div className="uppercase overflow-hidden mx-3 font-semibold text-sm lg:text-sm ">
            {description}
          </div>
        </div>
      </div>
    </Link>
  );
}
