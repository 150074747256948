import { useBreakPoint } from 'utils/HooksUtils';
import Header from './Header';

const Partners = ({
  heading,
  mobile_datawith_link: mobileData,
  web_data_with_link: webData,
  backgroundImage,
  mobileBackgroundImage,
}) => {
  const isLg = useBreakPoint('lg');
  const data = isLg ? webData : mobileData;
  return (
    <div
      className={`pt-15 px-6 lg:px-32 flex flex-col gap-4 lg:gap-8 items-center justify-center`}
      style={{
        backgroundImage: isLg ? `url('${backgroundImage}')` : `url('${mobileBackgroundImage}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Header {...heading} borderWidth="100%"></Header>
      <div className="my-10 flex justify-center  gap-4 lg:gap-24 flex-wrap w-full">
        {data?.map((el, i) => (
          <a href={el.link} key={i} target="_blank" rel="noreferrer">
            <img key={i} className="h-16 lg:h-32" src={el.image} />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Partners;
