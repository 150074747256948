/**
 * File download util to access file over URL and download it locally
 * @param {string} url // URL to access file
 * @param {string} fileName // File name given to downloaded file
 */
export const fileDownload = async (url, fileName) => {
  fetch(url, {
    method: 'GET',
  })
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
};
/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}

/**
 *
 * @param {{[x:string]:string|number}} params
 */
export function genQueryParams(params) {
  const temp = Object.keys(params)
    .map((param) => encodeURIComponent(param) + '=' + encodeURIComponent(params[param]))
    .join('&');
  if ((Array.isArray(temp) && temp.length > 0) || temp) {
    return '?' + temp;
  }
  return '';
}
