import { Popover, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
// import Bell from '../../assets/img/bell.svg';
import signOut from '../../assets/img/log-out.svg';
// import Notifications from '../../profiles/Components/Notifications';
import settingsIcon from '../../assets/img/settings.svg';
import { Link, useHistory } from 'react-router-dom';
import menuIcon from '../../assets/img/menu.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faBus, faCopy } from '@fortawesome/free-solid-svg-icons';
import supportIcon from '../../assets/img/supportIcon.svg';
import CrossIcon from '../../assets/img/cross.svg';
import { useUserProfile } from 'profiles/queries/profileHooks';
import Collection from '../../assets/img/collection_icon.svg';
import { logOut } from 'authentication/services/authentication';
import ProfileIcon from '../../assets/img/user.svg';
import NavigateCard from '../../act/components/NavigateCard';
import SFALogo from '../../assets/img/SFALogo.svg';
import KheloLogo from '../../assets/img/logo/khelo_logo_white.png';
import BillingIcon from '../../assets/img/PaymentBlack.svg';
import { useShowKhelo } from 'utils/HooksUtils';
import KheloHeader from 'micro-site/Components/KheloHeader/KheloHeader';
// import { isKhelo } from '../../utils/kheloConditions';

// import user from '../../assets/img/user.svg';
export default function Navbar() {
  // const [close, setClose] = useState(false);
  const [close2, setClose2] = useState(false);
  const userProfileQuery = useUserProfile();
  const userRole = userProfileQuery?.data?.message.primary_role;
  const userImage = userProfileQuery?.data?.message.image;
  const history = useHistory();
  const isKhelo = useShowKhelo();

  const [sideMenuStatus, setSideMenuStatus] = useState(false);

  // const notificationListQueryData = useNotificationsList();

  const toggleHandler = (status) => {
    if (status) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
    setSideMenuStatus(status);
  };
  if (isKhelo) return <KheloHeader />;
  if (window.location.pathname.split('/')[2] === 'registration')
    return (
      <div
        className={`font-roboto ${
          window.location.pathname.split('/')[2] !== 'registration' ? 'hidden' : 'block'
        }`}
      >
        <div
          className={`${
            isKhelo ? 'bg-blue-kheloBlue' : 'bg-blue-950'
          } flex justify-between items-center md:px-10 md:py-3 px-6`}
        >
          <div
            className="flex justify-center items-center cursor-pointer"
            onClick={() => {
              history.push('/');
            }}
          >
            <img src={isKhelo ? KheloLogo : SFALogo} alt="logo" className="lg:block h-10" />
          </div>

          <Popover className="relative mr-3.5">
            {({ open }) => (
              <>
                <Popover.Button
                  data-cy="user_icon"
                  className={`
              ${open ? '' : 'text-opacity-90'}
              text-white group px-2 py-1.5 rounded-md inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span
                    onClick={
                      open
                        ? () => {
                            close2 ? setClose2(false) : setClose2(true);
                          }
                        : () => setClose2(false)
                    }
                  >
                    <div
                      style={{
                        borderRadius: '50%',

                        background: `url('${userImage}')`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                      }}
                      className="w-7 h-7 md:w-8 md:h-8 lg:w-10 lg:h-10"
                    />
                  </span>
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel
                    className={`absolute z-10 w-60 lg:w-72 px-4 transform -translate-x-1/2 -left-8 md:-left-20 lg:-left-24 sm:px-0 lg:max-w-3xl ${
                      close2 ? 'hidden' : 'block'
                    }`}
                  >
                    <div className="overflow-hidden rounded-lg shadow-lg">
                      <div
                        className="relative flex-col gap-8 bg-white p-4"
                        onClick={() => setClose2(true)}
                      >
                        <div
                          onClick={() => history.push(`/`)}
                          className={`flex items-center cursor-pointer transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                        >
                          <div className="flex items-center  justify-center flex-shrink-0 w-10 h-10 text-white ">
                            <img
                              src={ProfileIcon}
                              className="mt-1"
                              alt="logout"
                              height="16px"
                              width="16px"
                            />
                          </div>
                          <div className="ml-4">
                            <p data-cy="my_account_icon" className="text-sm mt-1  text-gray-900">
                              My Profile
                            </p>
                          </div>
                        </div>
                        <div
                          onClick={() => history.push(`/${userRole}/profiles/account-details`)}
                          className={`flex items-center cursor-pointer transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                        >
                          <div className="flex items-center  justify-center flex-shrink-0 w-10 h-10 text-white ">
                            <img
                              src={settingsIcon}
                              className="mt-1"
                              alt="logout"
                              height="16px"
                              width="16px"
                            />
                          </div>
                          <div className="ml-4">
                            <p data-cy="my_account_icon" className="text-sm mt-1  text-gray-900">
                              My Account
                            </p>
                          </div>
                        </div>

                        {!isKhelo &&
                          (userRole === 'athlete' ? (
                            <div
                              onClick={() => history.push(`/${userRole}/profiles/billing-details`)}
                              className={`flex items-center cursor-pointer transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                            >
                              <div className="flex items-center  justify-center flex-shrink-0 w-10 h-10 text-white ">
                                <img
                                  src={BillingIcon}
                                  className="mt-1"
                                  alt="logout"
                                  height="16px"
                                  width="16px"
                                />
                              </div>
                              {!isKhelo && (
                                <div className="ml-4">
                                  <p
                                    data-cy="my_account_icon"
                                    className="text-sm mt-1  text-gray-900"
                                  >
                                    Billing and Information
                                  </p>
                                </div>
                              )}
                            </div>
                          ) : null)}

                        <div
                          onClick={() => logOut(userProfileQuery?.data)}
                          className={`cursor-pointer flex items-center transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                        >
                          <div className="flex items-center  justify-center flex-shrink-0 w-10 h-10 text-white ">
                            <img
                              src={signOut}
                              className="mt-1"
                              alt="logout"
                              height="16px"
                              width="16px"
                            />
                          </div>
                          <div className="ml-4">
                            <p className="text-sm mt-1 text-gray-900">Logout</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>
      </div>
    );
  else
    return (
      <div
        className={`font-roboto ${
          window.location.pathname.split('/')[2] === 'registration' ? 'hidden' : 'block'
        }`}
      >
        <div
          className={`${
            isKhelo ? 'bg-blue-kheloBlue' : 'bg-blue-950'
          } flex justify-between items-center md:px-10 md:py-3 px-6`}
        >
          <div
            className="flex justify-center items-center cursor-pointer"
            onClick={() => {
              history.push('/');
            }}
          >
            <img src={isKhelo ? KheloLogo : SFALogo} alt="logo" className=" h-4 md:h-9" />
          </div>
          <div className="flex gap-2.5 md:gap-5 lg:gap-7 items-center">
            {/* <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    data-cy="notification_icon"
                    className={`
                ${open ? '' : 'text-opacity-90'}
                text-white group bg-orange-700 md:px-3 md:py-2 rounded-md inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span
                      onClick={
                        open
                          ? () => {
                              close ? setClose(false) : setClose(true);
                            }
                          : () => setClose(false)
                      }
                    >
                      <img src={Bell} alt="bell" className="w-4 lg:w-auto" />
                      <div className="bg-red-350 rounded-full w-1.5 h-1.5 md:w-4 md:h-4 lg:w-5 lg:h-5 leading-4 lg:leading-5 text-xxs md:text-xs text-center absolute right-0 top-0 md:right-1 md:top-1 lg:right-0 lg:top-0">
                        <span data-cy="notification_length" className="hidden md:block">
                          {notificationListQueryData?.data?.length}
                        </span>
                      </div>
                    </span>
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel
                      className={`absolute z-10 w-60 lg:w-72 px-4 transform -translate-x-1/2 -left-8 md:-left-20 lg:-left-24 sm:px-0 lg:max-w-3xl ${
                        close2 ? 'hidden' : 'block'
                      }`}
                    >
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div
                          data-cy="navbar_notification"
                          className="relative flex-col gap-8 bg-white"
                          onClick={() => setClose(true)}
                        >
                          <Notifications />
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover> */}

            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    data-cy="user_icon"
                    className={`
              ${open ? '' : 'text-opacity-90'}
              text-white group bg-orange-700 px-2 py-1.5 rounded-md inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span
                      onClick={
                        open
                          ? () => {
                              close2 ? setClose2(false) : setClose2(true);
                            }
                          : () => setClose2(false)
                      }
                    >
                      <div
                        style={{
                          borderRadius: '50%',

                          background: `url('${userImage}')`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                        }}
                        className="w-7 h-7 md:w-8 md:h-8 lg:w-10 lg:h-10"
                      />
                    </span>
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel
                      className={`absolute z-10 w-44 lg:w-72 px-4 transform -translate-x-1/2 -left-8 md:-left-20 lg:-left-24 sm:px-0 lg:max-w-3xl ${
                        close2 ? 'hidden' : 'block'
                      }`}
                    >
                      <div className="overflow-hidden rounded-lg shadow-lg">
                        <div
                          className="relative flex-col gap-8 bg-white p-1.5"
                          onClick={() => setClose2(true)}
                        >
                          {/* <div
                            onClick={() => history.push(`/${userRole}/profiles`)}
                            className={`flex items-center cursor-pointer transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                          >
                            <div className="flex items-center my-1 justify-center flex-shrink-0 w-10 h-10 text-white ">
                              <img
                                src={ProfileIcon}
                                className="mt-1"
                                alt="logout"
                                height="16px"
                                width="16px"
                              />
                            </div>
                            <div className="ml-4">
                              <p
                                data-cy="my_account_icon"
                                className="text-sm mt-1 font-medium text-gray-900"
                              >
                                My Profile
                              </p>
                            </div>
                          </div>
 */}

                          <div
                            onClick={() => history.push(`/`)}
                            className={`flex items-center cursor-pointer transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                          >
                            <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white">
                              <img
                                src={ProfileIcon}
                                className="mt-1"
                                alt="logout"
                                height="16px"
                                width="16px"
                              />
                            </div>
                            <div className="ml-4">
                              <p data-cy="my_account_icon" className="text-sm mt-1 text-gray-900">
                                My Profile
                              </p>
                            </div>
                          </div>
                          <div
                            onClick={() => history.push(`/${userRole}/profiles/account-details`)}
                            className={`flex items-center cursor-pointer transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                          >
                            <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white">
                              <img
                                src={settingsIcon}
                                className="mt-1"
                                alt="logout"
                                height="16px"
                                width="16px"
                              />
                            </div>
                            <div className="ml-4">
                              <p data-cy="my_account_icon" className="text-sm mt-1 text-gray-900">
                                My Account
                              </p>
                            </div>
                          </div>

                          {!isKhelo && userRole === 'athlete' ? (
                            <div
                              onClick={() => history.push(`/${userRole}/profiles/billing-details`)}
                              className={`flex items-center cursor-pointer transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                            >
                              <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white">
                                <img
                                  src={BillingIcon}
                                  className="mt-1"
                                  alt="logout"
                                  height="16px"
                                  width="16px"
                                />
                              </div>

                              {!isKhelo && (
                                <div className="ml-4">
                                  <p
                                    data-cy="my_account_icon"
                                    className="text-sm mt-1 text-gray-900"
                                  >
                                    Billing and Information
                                  </p>
                                </div>
                              )}
                            </div>
                          ) : null}
                          <div
                            onClick={() => logOut(userProfileQuery?.data)}
                            className={`cursor-pointer flex items-center transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                          >
                            <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white">
                              <img
                                src={signOut}
                                className="mt-1"
                                alt="logout"
                                height="16px"
                                width="16px"
                              />
                            </div>
                            <div className="ml-4">
                              <p className="text-sm mt-1 text-gray-900">Logout</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
            {!isKhelo && (
              <div
                data-cy="toggle_handler"
                className={`cursor-pointer ml-3.5 md:hidden ${
                  userRole === 'institution' ? 'hidden' : null
                }`}
                onClick={() => toggleHandler(true)}
              >
                <img src={menuIcon} alt="" />
              </div>
            )}
          </div>
        </div>
        <div
          data-cy="side_menu"
          className={`${
            sideMenuStatus ? 'block' : 'hidden'
          } fixed z-30 md:hidden bg-white px-8 top-0 right-0 h-full w-3/4 rounded-l-2xl`}
        >
          <div className="relative flex flex-col items-center py-12 shadow-shadowBottom">
            <img src={userImage} alt="" className="w-20 h-20 rounded-full overflow-hidden" />
            <p data-cy="full_name" className="text-md font-bold mt-3">
              {userProfileQuery?.data?.message.full_name}
            </p>
            <div
              className={`flex gap-3 px-2 py-1 items-center rounded ${
                isKhelo ? 'bg-orange-150' : 'bg-blue-50'
              } mt-1`}
            >
              <p
                data-cy="sfa_id"
                className={`${isKhelo ? 'text-orange-350' : 'text-blue-350'} text-xs`}
              >
                SFA:{userProfileQuery?.data?.message.sfa_id}
              </p>
              <FontAwesomeIcon
                icon={faCopy}
                className={`${isKhelo ? 'text-orange-350' : 'text-blue-350'} text-xs`}
                onClick={(_e) => {
                  navigator.clipboard.writeText(userProfileQuery?.data?.message.sfa_id);
                }}
              />
            </div>
          </div>
          <div className="py-10 shadow-shadowBottom pl-6">
            <NavigateCard
              header="Accommodation"
              icon={faBed}
              navigateTo="/act/accommodation"
              dataCy="accommodation"
              isSideBar={true}
              toggleHandler={toggleHandler}
            />
            <NavigateCard
              header="Transport"
              icon={faBus}
              navigateTo="/act/transport"
              dataCy="transport"
              isSideBar={true}
              toggleHandler={toggleHandler}
            />
            <NavigateCard
              header="Verification & Collections"
              image={Collection}
              navigateTo="/act/collection"
              dataCy="varification_collection"
              isSideBar={true}
              toggleHandler={toggleHandler}
            />
          </div>
          <Link to="/act/support">
            <div
              onClick={() => toggleHandler(false)}
              className="flex justify-center py-6 shadow-shadowBottom"
            >
              <img src={supportIcon} alt="icon" />
              <span data-cy="support" className="text-sm text-deep-gray font-bold ml-3">
                Support
              </span>
            </div>
          </Link>
          <img
            onClick={() => toggleHandler(false)}
            src={CrossIcon}
            className="cursor-pointer absolute top-4 right-6"
            alt=""
          />
        </div>
      </div>
    );
}
