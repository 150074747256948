import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import { useBreakPoint } from 'utils/HooksUtils';
import banner from '../../../../assets/img/heroSectionBg.webp';
const HeroSection = ({
  bannerProps,
  tableProps,
  showBanners,
  showTableSection,
  showBottomBorder,
}) => {
  const isDesktop = useBreakPoint('lg');
  return (
    <section>
      <div className=" w-full flex flex-col lg:flex-row items-stretch">
        {showBanners ? (
          <div
            className={`${showTableSection ? 'lg:w-3/4' : 'w-full override-slide-margin'}`}
            style={{ flexShrink: 0, maxHeight: showTableSection && isDesktop ? '488.5px' : '' }}
          >
            <LeftBanner {...bannerProps} />
          </div>
        ) : null}
        {showBottomBorder ? (
          <div className="flex lg:hidden">
            <div className="bg-orange-950 h-1.5 md:h-2 w-full"></div>
            <div className="h-1.5 md:h-2 w-full bg-green-500"></div>
            <div className="h-1.5 md:h-2 w-full bg-blue-kheloBlue200"></div>
            <div className="h-1.5 md:h-2 w-full bg-orange-950"></div>
          </div>
        ) : null}
        {showTableSection ? <RightBanner {...tableProps} /> : null}
      </div>
      {showBottomBorder ? (
        <div className="hidden lg:flex">
          <div className="bg-orange-950 h-1.5 md:h-2 w-full"></div>
          <div className="h-1.5 md:h-2 w-full bg-green-500"></div>
          <div className="h-1.5 md:h-2 w-full bg-blue-kheloBlue200"></div>
          <div className="h-1.5 md:h-2 w-full bg-orange-950"></div>
        </div>
      ) : null}
    </section>
  );
};

export default HeroSection;

function LeftBanner({
  images_with_link: images,
  mobile_img_with_link: mobileImg,
  carouselSettings,
  elementRef,
}) {
  const settings = {
    autoPlaySpeed: Number(carouselSettings.autoplaySpeed) * 1000 || 3000,
    autoplay: carouselSettings.autoplayEnable,
    arrows: false,
    dots: false,
  };

  return (
    <>
      <Slider
        className="w-full hidden lg:block home-page-dots-override home-page-slider"
        {...settings}
      >
        {images?.map((image, index) => (
          <a href={image.link} target="_blank" rel="noreferrer" key={index}>
            <img className="m-auto  object-cover h-full" src={image?.image} />
          </a>
        ))}
      </Slider>
      <Slider
        className="w-full  lg:hidden home-page-dots-override override-slide-margin mobile-home-slider"
        {...settings}
      >
        {mobileImg?.map((image, index) => (
          <a href={image.link} target="_blank" rel="noreferrer" key={index}>
            <img className="m-auto  object-cover w-full" src={image.image} />
          </a>
        ))}
      </Slider>
    </>
  );
}
function RightBanner({ data, backgroundImage = banner, mobileBackgroundImage = banner }) {
  const isLg = useBreakPoint('lg');

  // useEffect(() => {
  //   if (!isLg) {
  //     tableContainer.current.style.maxHeight = '';
  //   }
  //   const resizeOnWindowResize = throttle(() => {
  //     if (tableContainer.current && elementRef.current) {
  //       tableContainer.current.style.maxHeight = `${elementRef.current.clientHeight}px`;
  //     }
  //   }, 500);

  //   const elementObserver = new ResizeObserver(() => {
  //     resizeOnWindowResize();
  //   });
  //   elementObserver.observe(elementRef.current);
  //   return () => elementObserver.disconnect();
  // }, [isLg]);
  const settings = {
    arrows: false,
    dots: true,
    rows: 3,
  };
  return (
    <div
      className={`shrink-0  w-full lg:w-1/4`}
      style={{
        background: `url('${(isLg ? backgroundImage : mobileBackgroundImage) || banner}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Slider {...settings} className="hero-table-slider">
        {data?.map((el, i) => (
          <Card key={i} {...el} />
        ))}
      </Slider>
    </div>
  );
}

function Card({ title, description, link }) {
  const history = useHistory();
  return (
    <div className="text-white font-monterssat ">
      <div
        className="py-4 flex flex-col bg-blue-875 text-white flex-grow-1 lg:mt-5 hover:bg-orange-kheloOrange cursor-pointer"
        onClick={() => history.push(link)}
      >
        <div className="mx-5 text-base lg:text-l">{title}</div>
      </div>
      <div style={{ background: 'rgba(255,255,255,0.1)' }}>
        <div className="flex flex-col relative shrink-0 mx-5 py-1 text-xxs lg:text-xs italic">
          {description}
        </div>
      </div>
    </div>
  );
}
