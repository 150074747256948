import { useParams } from 'react-router-dom';

import Pattern from '../../../../assets/img/Pattern.svg';

import { useVenues } from 'micro-site/queries/hooks';
import Loader from 'components/Loader';
import tableDesktopPic from '../../../../assets/img/desktop_venue_calendar.webp';
import tableMobilePic from '../../../../assets/img/mobile_venue_calendar.webp';

import { getDateInDateMonth } from 'constants/DateFunctions';
// import Helmet from 'react-helmet';

const VenuePage = () => {
  const params = useParams();
  const venueData = useVenues({
    tournament: params?.tournament_id,
    city: params.city,
    venue: params.venueName,
  });
  console.log(venueData);
  if (venueData.isFetched)
    return (
      <>
        {/* <Helmet>
        <title>Venues | KIYG Haryana 2021</title>
        <meta
          name="description"
          content="Here's a list of the 8 Venues which will be hosting the 25 Sports at the #KIYG2021 from 4th - 13th June 2022. Find out which venue will host which sport!"
        />
        <meta property="og:title" content="Venues | KIYG Haryana 2021" />
        <meta
          property="og:description"
          content="Here's a list of the 8 Venues which will be hosting the 25 Sports at the #KIYG2021 from 4th - 13th June 2022. Find out which venue will host which sport!"
        />
      </Helmet> */}
        {/* <Banner
        // title={individualData[params.venueId]?.name}
        img={isMobile ? BannerImageMobile : BannerImageDesktop}
        variant="page"
      /> */}
        <div
          style={{
            background: `url('${Pattern}')`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className="py-24 px-6 md:block hidden">
            <div className="px-14 relative flex">
              <div className="relative w-full">
                <img
                  src={venueData?.data?.[0]?.venue_details?.[0]?.inside_pic}
                  className="rounded-2xl h-408 object-cover w-1/2"
                />
                <div className="bg-white shadow-2xl flex flex-col gap-8 items-start rounded-2xl  w-1/2  absolute transform top-1/2 -translate-y-1/2 -translate-x-12 h-32 left-1/2 z-4 py-12 px-9 h-auto">
                  <h1 className="text-blue-kheloBlue font-montserrat font-extrabold text-3.5xl border-b-8 border-orange-950">
                    {venueData?.data?.[0]?.venue_details?.[0]?.writeup_header}
                  </h1>
                  <p className="font-montserrat text-gray-475">
                    {venueData?.data?.[0]?.venue_details?.[0]?.writeup_content}
                  </p>
                  <a href={venueData?.data?.[0]?.venue_details?.[0]?.location_link}>
                    <button className="bg-orange-950 rounded-lg py-2 px-4 text-white font-montserrat font-bold">
                      View Location
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-8 px-6 flex flex-col gap-3  md:hidden">
          <img
            src={venueData?.data?.[0]?.venue_details?.[0]?.inside_pic}
            className="h-34 object-cover rounded-md"
          />
          <div className="bg-white py-12 flex flex-col gap-6 shadow-2xl rounded-xl px-5 text-center items-center mt-2">
            <div className="inline-block">
              <h1 className="text-blue-kheloBlue font-montserrat text-lg font-bold border-b-4 border-orange-950 inline-block">
                {venueData?.data?.[0]?.venue_details?.[0]?.writeup_header}
              </h1>
            </div>
            <p className="text-sm font-montserrat text-gray-475">
              {venueData?.data?.[0]?.venue_details?.[0]?.writeup_content}
            </p>
            <a
              target="_blank"
              href={venueData?.data?.[0]?.venue_details?.[0]?.location_link}
              rel="noreferrer"
            >
              <button className="bg-orange-950 rounded-lg py-2 px-4 text-white font-montserrat font-bold">
                View Location
              </button>
            </a>
          </div>
        </div>
        <section
          className="px-20 font-montserrat  flex-col items-center gap-16 py-20 hidden md:flex"
          style={{ background: `url('${tableDesktopPic}')` }}
        >
          <div>
            <h1 className="text-white capitalize text-3.5xl font-bold  inline-block mx-auto">
              VENUE CALENDAR
            </h1>
            <div className="px-4 mt-2">
              <div className=" w-full px-2 bg-purple-ngPurple" style={{ height: '6px' }}></div>
            </div>
          </div>
          <div className="w-full">
            <table className="w-full text-white  font-semibold uppercase ">
              <thead>
                <tr className=" text-yellow-viewVenue">
                  <th className="border-1 border-white py-5">No.</th>
                  <th className="border-1 border-white py-5">Sports Name</th>
                  <th className="border-1 border-white py-5">Venue Name</th>
                  <th className="border-1 border-white py-5">Date</th>
                  <th className="border-1 border-white py-5">Location</th>
                </tr>
              </thead>
              <tbody>
                {venueData?.data?.[0]?.venue_calender?.map((event, i) => (
                  <tr className="text-center" key={i}>
                    <td className="border-1 border-white py-5">{i + 1}</td>
                    <td className="border-1 border-white py-5">{event?.sport}</td>
                    <td className="border-1 border-white py-5">{event?.venue}</td>
                    <td className="border-1 border-white py-5">
                      {getDateInDateMonth(event?.start_date)}-{getDateInDateMonth(event?.end_date)}
                    </td>
                    <td className="border-1 border-white py-5">
                      <a
                        href={venueData?.data?.[0]?.venue_details?.[0]?.location_link}
                        target="_blank"
                        rel="noreferrer"
                        className="text-white underline capitalize"
                        style={{ color: 'white' }}
                      >
                        View Location
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>{' '}
        </section>

        <section
          className="px-6 font-montserrat flex flex-col items-center gap-16 py-20 md:hidden"
          style={{ background: `url('${tableMobilePic}')` }}
        >
          <div>
            <h1 className="text-white capitalize text-2xl font-bold  inline-block mx-auto">
              VENUE CALENDAR
            </h1>
            <div className="px-4 mt-2">
              <div className=" w-full px-2 bg-purple-ngPurple" style={{ height: '6px' }}></div>
            </div>
          </div>
          <div className="w-full flex flex-col gap-10">
            {venueData?.data?.[0]?.venue_calender?.map((event, i) => (
              <table className="w-full text-white  font-semibold uppercase " key={i}>
                <tbody>
                  <tr className="text-center">
                    <td className="border-1 border-white py-1">{i + 1}</td>
                  </tr>
                  <tr className="text-center">
                    <td className="border-1 border-white py-1">{event?.sport}</td>
                  </tr>
                  <tr className="text-center">
                    <td className="border-1 border-white py-1">{event?.venue}</td>
                  </tr>
                  <tr className="text-center">
                    {' '}
                    <td className="border-1 border-white py-1">
                      {getDateInDateMonth(event?.start_date)}-{getDateInDateMonth(event?.end_date)}
                    </td>
                  </tr>
                  <tr className="text-center">
                    <td className="border-1 border-white py-1">
                      <a
                        href={venueData?.data?.[0]?.venue_details?.[0]?.location_link}
                        className="text-white underline capitalize"
                        style={{ color: 'white' }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        View Location
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}
          </div>{' '}
        </section>
        {/* <div className="px-6 py-8 md:py-24 md:px-14 bg-white text-center hidden md:block">
          <h1 className="font-extrabold text-blue-kheloBlue font-montserrat text-lg md:text-4xl mb-2">
            Venue Gallery
          </h1>
          <div className="grid grid-cols-4 gap-3 grid-flow-col mt-16">
            {individualData[params.venueId]?.gallery_images?.map((el, i) => {
              return <VenueGalleryCard key={i} {...el} />;
            })}
          </div>
        </div> */}
        {/* <div className="bg-blue-kheloLightBlue">
        <div
          style={{
            background: `url('${VenueSide}')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',
          }}
        >
          <h2 className="font-extrabold text-blue-kheloBlue font-montserrat text-lg text-center md:text-left md:text-2xl px-6 py-8 md:px-14 pt-14">
            Similar Venues
          </h2>
          <div className="pb-14 pt-8 px-14 hidden md:flex flex-wrap gap-2">
            {individualData[params.venueId]?.similar_venues?.map((el, i) => (
              <VenueCard
                key={i}
                {...el}
                {...individualData[el.slug]}
                image={individualData[el.slug]?.card_image}
                redirectTo={`/micro-site/${tournamentId}/landing/venues/${el?.slug}`}
              />
            ))}
          </div>
          <div className="px-6 pb-8 pt-2 flex md:hidden flex-wrap gap-2">
            {individualData[params.venueId]?.similar_venues?.map((el, i) => (
              <VenueMobileCard
                key={i}
                {...el}
                {...el}
                {...individualData[el.slug]}
                image={individualData[el.slug]?.card_image}
                redirectTo={`/micro-site/${tournamentId}/landing/venues/${el?.slug}`}
              />
            ))}
          </div>
        </div>
      </div> */}
      </>
    );

  return <Loader />;
};

export default VenuePage;
