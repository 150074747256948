import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faLinkedinIn,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import footerlogos from '../../assets/img/footerlogos.png';
// import { useShowKhelo } from 'utils/HooksUtils';
// import { isKhelo } from '../../utils/kheloConditions';

const Footer = () => {
  return (
    <div className="bg-blue-950">
      <div className="w-full h-full flex flex-col py-3 px-3 min-h-60 text-sm md:text-base max-w-screen-xl bg-blue-950 text-white items-center gap-4 md:gap-0 mx-auto">
        <div className="pt-7">
          <img src={footerlogos} className=" h-20" />
        </div>
        <div className="flex justify-between gap-6 md:gap-12 flex-col md:flex-row w-full h-full mt-auto">
          <ul className="text-xxs md:text-sm text-center md:text-left">
            <li className="font-bold">For queries and support,</li>
            <li>Send us on whatsApp message on +91 7039 66 66 66</li>
            <li>
              Email us at{' '}
              <a href="mailto:uk.support@sfaplay.com" className="text-white">
                uk.support@sfaplay.com
              </a>
            </li>
          </ul>
          <div className="flex gap-8 md:mt-4 md:pt-0.5 justify-center">
            <a
              href="https://www.instagram.com/sfanow/?hl=en"
              target={'_blank'}
              className="text-white"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} className="h-4 md:h-6" />
            </a>
            <a
              href="https://www.facebook.com/sfanow/"
              target={'_blank'}
              className="text-white"
              rel="noreferrer"
            >
              {' '}
              <FontAwesomeIcon icon={faFacebookF} className="h-4 md:h-6" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCWoiHFley8hGy0ad_t-0O7w"
              target={'_blank'}
              rel="noreferrer"
              className="text-white"
            >
              {' '}
              <FontAwesomeIcon icon={faYoutube} className="h-4 md:h-6" />
            </a>
            <a
              href="https://twitter.com/sfanow"
              target={'_blank'}
              rel="noreferrer"
              className="text-white"
            >
              {' '}
              <FontAwesomeIcon icon={faTwitter} className="h-4 md:h-6" />
            </a>
            <a
              href="https://www.linkedin.com/company/sfa-events-pvt.-ltd/"
              target={'_blank'}
              rel="noreferrer"
              className="text-white"
            >
              {' '}
              <FontAwesomeIcon icon={faLinkedinIn} className="h-4 md:h-6" />
            </a>
          </div>
          <ul className="text-xxs md:text-sm  text-center md:text-right">
            <li>
              <a
                className="text-white underline font-bold"
                href="/static/SFAPLAY.COM-Terms-&-Conditions.pdf"
              >
                TERMS & CONDITIONS
              </a>{' '}
              |{' '}
              <a
                className="text-white underline font-bold"
                href="https://web.kheloindia.gov.in/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                PRIVACY POLICY
              </a>
            </li>
            <li>© 2022 SFAPLAY.COM All Rights Reserved by SFA</li>
            <li>Sporting Services Pvt. Ltd.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
