import { useGalleryFolders } from '../../../queries/hooks';

import { useRouteMatch } from 'react-router-dom';
import SectionSliderMesh from './SectionSliderMesh';

const Section = ({ sectionName = 'Section', userRole, data }) => {
  const match = useRouteMatch();

  const sectionFolders = useGalleryFolders({
    section: sectionName,
  });

  return (
    <SectionSliderMesh
      sectionName={sectionName}
      sectionFolders={sectionFolders}
      redirectTo={match.url}
      previewCount={data.preview_count}
      previewType={data.preview_type}
    />
  );
};

export default Section;
