import React, { useEffect, useRef, useState } from 'react';
import 'components/formio/formio.css';
import 'constants/DrawEventForm/style.css';
import {
  useDrawDateList,
  useSportEvent,
  useSportSchedules,
  useTournamentDetails,
  useDrawFilters,
} from 'micro-site/queries/hooks';
import { useHistory, useParams } from 'react-router-dom';
import 'constants/DrawEventForm';
import Table from '../../Components/Table';
import ScheduleTabs from 'micro-site/Components/ScheduleTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Schedule from '../Schedule';
import {
  // faAngleDown,
  faAngleLeft,
  faAngleRight,
  faCheck,
  faSearch,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import SelectList from 'components/select-list';
import { useParticipantList } from '../../queries/hooks.js';
import Loader from 'components/Loader';
import CloseIcon from '../../../assets/img/Close.svg';
import ListLoader from 'components/ListLoader';
import ResultCard from '../../Components/ResultCard';
import drawBanner from '../../../assets/img/DrawsBanner.jpg';
import FilterIcon from '../../../assets/img/Filters.svg';
import microSiteLogo from '../../../assets/img/logo/uttarakhand_logo.jpg';
import microSiteBg from '../../../assets/img/uttarakhand_desktop.jpg';
import DrawEventForm from 'micro-site/Components/DrawEventForm';
import FiltersWrapper from 'components/FiltersWrapper';
import { getDateInMonthDateYear } from 'constants/DateFunctions';
import { useUserProfile } from 'profiles/queries/profileHooks';
import DetailItemLoader from 'components/DetailItemLoader';
import Header from 'micro-site/Components/Header';
import Draws from './ExpandedMobileView';
import { useShowKhelo, useInfiniteScroll, useDebounce } from 'utils/HooksUtils';
// import Helmet from 'react-helmet';
import { tableText } from 'data/noDataText';
// import { isKhelo } from '../../../utils/kheloConditions';

export default function index({ enabledScheduled }) {
  const isKhelo = useShowKhelo();
  const history = useHistory();
  const [date, setDate] = useState('');
  const [sport, setSport] = useState(null);
  const [sportList, setSportList] = useState([]);
  const [gender, setGender] = useState(null);
  const [age, setAge] = useState(null);
  const [ageList, setAgeList] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [opponent, setOpponent] = useState('opponent_1');
  const actualParams = useParams();
  const scrollRef = useRef(null);
  const [viewDrawsScreenMobile, setViewDrawsScreenMobile] = useState(false);
  const [showMobileSearchBox, setShowMobileSearchBox] = useState(false);
  const scheduleRef = useRef(null);
  // const imageRef = useRef(null);
  const infiniteRefMobile = useRef(null);
  const drawFilters = useDrawFilters({
    tournamentId: actualParams?.tournament_id,
    date: date,
    sport: sport ? (sport === 'All' ? '' : sport) : '',
  });
  const participantListQueryData = useParticipantList({
    tournament: actualParams?.tournament_id,
    date: date,
    sport: sport ? (sport === 'All' ? '' : sport) : '',
    gender: gender ? (gender === 'All' ? '' : gender) : '',
    age_group: age ? (age === 'All' ? '' : age) : '',
    round: '',
    limit: 25,
    search: searchTerm,
  });
  const userData = useUserProfile();
  useInfiniteScroll(
    infiniteRefMobile,
    participantListQueryData.fetchNextPage,
    participantListQueryData.isFetching || participantListQueryData.isFetchingNextPage,
    true
  );
  function calculateFilters() {
    console.log(sportList, genderList, ageList, 'HA');
    if (sportList.length === 0 || !Array.isArray(sportList)) {
      setSportList(['All', ...drawFilters?.data?.message?.sports]);
    }
    setGenderList(['All', ...drawFilters?.data?.message?.genders]);
    setAgeList(['All', ...drawFilters?.data?.message?.age_groups]);
  }
  console.log(sportList, genderList, ageList);
  useEffect(() => {
    if (drawFilters.isFetched && drawFilters?.data?.message) {
      calculateFilters();
    }
  }, [drawFilters.isFetched, drawFilters.data]);
  useEffect(() => {
    participantListQueryData.refetch();
  }, [sport, gender, age]);

  const filterData = [
    {
      type: 'dropdown',
      dataCy: 'draws_sport_selector',
      selectedValue: sport,
      setSelectedValue: setSport,
      placeholder: 'Sport',
      listData: sportList,
    },
    {
      type: 'dropdown',
      selectedValue: gender,
      setSelectedValue: setGender,
      placeholder: 'Gender',
      listData: genderList,
    },
    {
      type: 'dropdown',
      selectedValue: age,
      setSelectedValue: setAge,
      placeholder: 'Age',
      listData: ageList,
    },
  ];

  const columns = [
    // {
    //   key: 'array_id',
    //   label: '',
    //   component: 'TextColumn',
    // },
    {
      key: 'sport',
      label: 'Sport',
      component: 'SportColumn',
      showSubCategory: true,
    },
    {
      key: 'match',
      label: 'Round',
      component: 'RoundColumn',
    },
    {
      key: 'venue',
      label: 'Venue',
      component: 'VenueColumn',
    },
    {
      key: 'time',
      label: date ? 'Time' : 'Date & Time',
      component: date ? 'TimeColumn' : 'DateTimeColumn',
    },
    // {
    //   key: 'sfa_id_1',
    //   label: 'SFA ID',
    //   component: 'TextColumn',
    // },
    {
      key: 'opponent_1',
      label: 'Opponent 1',
      component: 'OpponentColumn',
    },
    // {
    //   key: 'sfa_id_2',
    //   label: 'SFA ID',
    //   component: 'TextColumn',
    // },
    {
      key: 'opponent_2',
      label: 'Opponent 2',
      component: 'OpponentColumn',
    },
    {
      key: 'score',
      label: 'Score',
      component: 'ScoreColumn',
      status: true,
      enableLink: true,
      tournamentId: actualParams?.tournament_id,
    },
  ];
  // const [round, setRound] = useState(null);
  // const [categoryList, setCategoryList] = useState([]);
  const [params, setParams] = useState('');

  const [filterPopup, setFilterPopup] = useState(false);
  const [expandedView, setExpandedView] = useState(null);
  const sportEventQueryData = useSportEvent({
    tournament: actualParams?.tournament_id,
    sport: params?.sport,
    gender: params?.gender,
    age_group: params?.age_group,
    sport_category: params?.sport_category,
    sub_category: params?.sub_category,
  });
  const scheduleTabQueryData = useSportSchedules({
    tournamentId: actualParams?.tournament_id,
  });

  const drawDateListQueryData = useDrawDateList({
    tournament: actualParams?.tournament_id,
  });
  const [participantsData, setParticipantsData] = useState([]);
  console.log(participantsData, 'HIYA');
  useEffect(() => {
    if (
      participantListQueryData.isFetched &&
      Array.isArray(participantListQueryData?.data?.pages?.[0]?.data)
    ) {
      const tempArray = [];
      const combinedAray = participantListQueryData?.data?.pages?.reduce((a, b) => {
        if (!a.data) return { data: [...b.data] };
        else
          return {
            data: [...a.data, ...b.data],
          };
      }, {});
      let previousValue = null;
      for (let i = 0; i < combinedAray.data.length; i++) {
        if (!previousValue || previousValue !== combinedAray.data[i].match.date) {
          previousValue = combinedAray.data[i].match.date;
          tempArray.push({
            date: combinedAray.data[i].match.date,
            list: [combinedAray.data[i]],
          });
        } else {
          tempArray[tempArray.length - 1].list.push(combinedAray.data[i]);
        }
      }
      setParticipantsData(tempArray);
    }
  }, [
    participantListQueryData.isFetchedAfterMount,
    participantListQueryData.isFetched,
    participantListQueryData.data,
  ]);
  const handleDrawFormat = (schema) => {
    setParams(schema);
  };

  const filterPopupHandler = () => {
    setFilterPopup(!filterPopup);
  };

  const clearFilterHandler = () => {
    setSport('All');
    setGender('All');
    setAge('All');
    setFilterPopup(false);
  };
  const tournamentDetailsQuery = useTournamentDetails({
    tournament_id: actualParams?.tournmaent_id,
  });
  useEffect(() => {
    sportEventQueryData.refetch();
  }, [params]);

  if (sportEventQueryData.isFetched === true && params) {
    try {
      history.push(
        `/public/score/${sportEventQueryData.data[0].digital_scoring_format?.toLowerCase()}/${encodeURIComponent(
          sportEventQueryData.data[0].sport_event
        )}`
      );
    } catch (error) {
      console.debug(error.message);
    }
  }

  const scroll = (scrollOffset) => {
    const scrollElement = document.getElementById('scroll-container');
    if (scrollElement.scrollWidth > scrollElement.clientWidth) {
      scrollElement.scrollLeft += scrollOffset;
    }
  };

  if (!expandedView)
    return (
      <>
        {/* <Helmet>
        <title>Draws & Schedule | KIYG Haryana 2021</title>
        <meta
          name="description"
          content="Check the DRAWS & SCHEDULE now! 25 Sports | 4th - 13th June 2022. Watch India's finest U/18 athletes represent their respective states at #KIYG2021!"
        />
        <meta property="og:title" content="Draws & Schedule | KIYG Haryana 2021" />
        <meta
          property="og:description"
          content="Check the DRAWS & SCHEDULE now! 25 Sports | 4th - 13th June 2022. Watch India's finest U/18 athletes represent their respective states at #KIYG2021!"
        />
      </Helmet> */}
        {!isKhelo && (
          <DetailItemLoader queryData={tournamentDetailsQuery} queryKey="id">
            {({ data }) => {
              return (
                <Header
                  eventName={data?.name}
                  city={data?.city}
                  eventImage={microSiteLogo}
                  banner={microSiteBg}
                  // price={data.price}
                  remarks={`This is one time tournament fees`}
                  // remarks={data.remarks}
                  startDate={data?.start_date}
                  endDate={data?.end_date}
                />
              );
            }}
          </DetailItemLoader>
        )}
        <div className="px-4 md:px-16 max-w-fhd mx-auto">
          {enabledScheduled ? (
            <>
              {!viewDrawsScreenMobile ? (
                <>
                  <div
                    className={`${
                      participantListQueryData?.data?.length === 0 ? '' : 'mt-16'
                    } hidden md:block`}
                  >
                    <div className="shadow-shadowBottom pb-3 mb-8">
                      <h1 className="text-2xl font-bold">View Detailed Schedules</h1>
                      <div className="flex gap-1.5">
                        <h6 className="font-normal text-sm text-gray-750 pb-2 md:pb-1 block">
                          <a
                            className="cursor-pointer"
                            onClick={() => {
                              scheduleRef.current.scrollIntoView({
                                behavior: 'smooth',
                              });
                            }}
                          >
                            Scroll Down
                          </a>{' '}
                          ,{' '}
                          {/* <span className="inline-block">
                          <span
                            className="bg-blue-950 rounded-full w-5 h-5 text-white flex items-center justify-center cursor-pointer"
                            onClick={() => {
                              scheduleRef.current.scrollIntoView({
                                behavior: 'smooth',
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faAngleDown} />
                          </span>
                        </span>{' '} */}
                          to download individual sport schedules as PDF.
                          {/* <a
                          className="cursor-pointer"
                          onClick={() => {
                            imageRef.current.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }}
                        >
                          {' '}
                          Click Here
                        </a>{' '}
                        to view full schedule.{' '} */}
                        </h6>
                      </div>
                    </div>
                    <div className="draw-form">
                      <DrawEventForm
                        handleDrawFormat={handleDrawFormat}
                        isLoading={sportEventQueryData?.isLoading}
                      />
                    </div>
                  </div>
                  <div className="flex gap-1 md:hidden mt-4">
                    <h6 className="font-normal text-sm text-gray-750 pb-2 md:pb-1 block">
                      <a
                        className="cursor-pointer"
                        onClick={() => {
                          scheduleRef.current.scrollIntoView({
                            behavior: 'smooth',
                          });
                        }}
                      >
                        Scroll Down
                      </a>{' '}
                      ,{' '}
                      {/* <span className="inline-block">
                          <span
                            className="bg-blue-950 rounded-full w-5 h-5 text-white flex items-center justify-center cursor-pointer"
                            onClick={() => {
                              scheduleRef.current.scrollIntoView({
                                behavior: 'smooth',
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faAngleDown} />
                          </span>
                        </span>{' '} */}
                      to download individual sport schedules as PDF.
                      {/* <a
                      className="cursor-pointer"
                      onClick={() => {
                        imageRef.current.scrollIntoView({
                          behavior: 'smooth',
                        });
                      }}
                    >
                      {' '}
                      Click Here
                    </a>{' '}
                    to view full schedule.{' '} */}
                      {/* <span className="inline-block">
                      <span
                        className="bg-blue-950 rounded-full w-5 h-5 text-white flex items-center justify-center cursor-pointer flex-shrink-0"
                        onClick={() => {
                          scheduleRef.current.scrollIntoView({
                            behavior: 'smooth',
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faAngleDown} />
                      </span>
                    </span> */}
                    </h6>
                  </div>
                  {drawDateListQueryData?.data?.length > 1 ? (
                    <div className="flex mb-5 md:mb-8 pt-6 md:pt-0 md:mt-16">
                      <button
                        onClick={() => scroll(-300)}
                        className={`justify-start my-auto -mr-4 bg-white z-10 px-3 py-1 rounded-full ${
                          isKhelo ? 'text-blue-kheloBlue' : 'text-purple-650'
                        } shadow-shadowLeftRight`}
                      >
                        <FontAwesomeIcon icon={faAngleLeft} />
                      </button>
                      <div
                        className="overflow-auto whitespace-nowrap rounded-lg no-scrollbar"
                        ref={scrollRef}
                        id="scroll-container"
                      >
                        <ScheduleTabs
                          scrollRef={scrollRef}
                          setDate={(date) => {
                            setDate(date);
                            setAge('');
                            setSport('');
                            setGender('');
                            setSportList([]);
                            setAgeList([]);
                            setGenderList([]);
                          }}
                        />
                      </div>
                      <button
                        onClick={() => scroll(300)}
                        className={`justify-start my-auto -ml-4 bg-white z-10 px-3 py-1 rounded-full ${
                          isKhelo ? 'text-blue-kheloBlue' : 'text-purple-650'
                        } shadow-shadowLeftRight`}
                      >
                        <FontAwesomeIcon icon={faAngleRight} />
                      </button>
                    </div>
                  ) : // <div className="w-full text-gray-750">
                  //   <p className="text-center">No Draws</p>
                  // </div>

                  null}
                  <div className="md:flex hidden justify-between items-end">
                    <div className="flex gap-2.5">
                      {
                        <div className="w-40 flex">
                          <FiltersWrapper filters={filterData} />
                        </div>

                        //   <div className="w-full text-gray-750">
                        //   <p className="text-center">No Draws</p>
                        // </div>
                      }
                      {/* <div className="w-40">
            <SelectList
              selectedValue={round}
              setSelectedValue={setRound}
              placeholder="Round"
              listData={categoryList}
              />
            </div> */}
                    </div>
                    {/* <div className="flex gap-2 text-sm py-2.5 px-4 border-1 border-gray-350 rounded-lg items-center">
          <FontAwesomeIcon icon={faSearch} className="text-gray-450" />
          <input
            type="text"
            className="border-0 h-5 text-sm placeholder-gray-450"
            placeholder="Search by Athlete or School"
          />
        </div> */}
                    <div className="font-normal text-sm text-gray-750 pb-2 md:pb-1 block">
                      <SearchBox initialState={searchTerm} searchSetter={setSearchTerm} />
                    </div>
                  </div>
                  <div className="mt-4 hidden md:block overflow-y-auto md:mb-8">
                    {participantListQueryData?.data &&
                    participantListQueryData.isFetchedAfterMount ? (
                      participantListQueryData?.data?.pages?.[0]?.data?.length > 0 ? (
                        <Table
                          rowCallback={(row) => setExpandedView(row)}
                          columns={columns}
                          rows={participantListQueryData?.data}
                          searchTerm={searchTerm}
                          tableHeight={'500px'}
                          isInfinite
                          isInfiniteFetching={participantListQueryData.isFetchingNextPage}
                          fetchNext={participantListQueryData?.fetchNextPage}
                          prependRow={
                            date === '' || !date
                              ? (() => {
                                  let previousValue = null;
                                  return function prependRow({ match: { date: currentDate } }) {
                                    if (!previousValue) {
                                      previousValue = new Date(currentDate);
                                      return (
                                        <tr>
                                          <td className="pl-4 py-2.5 text-white items-center text-xs">
                                            {getDateInMonthDateYear(currentDate)}
                                          </td>
                                        </tr>
                                      );
                                    }
                                    let flag = false;
                                    if (
                                      previousValue.getTime() !== new Date(currentDate).getTime()
                                    ) {
                                      flag = true;
                                    }
                                    previousValue = new Date(currentDate);
                                    if (flag)
                                      return (
                                        <tr>
                                          <td className="pl-4 py-2.5 text-white items-center text-xs">
                                            {getDateInMonthDateYear(currentDate)}
                                          </td>
                                        </tr>
                                      );
                                  };
                                })()
                              : null
                          }
                        />
                      ) : (
                        <p className="w-full text-center my-20 text-sm text-gray-750">
                          {!searchTerm ? (
                            <>{tableText}</>
                          ) : (
                            'There Is No Existing Data That Matches  The Search Result'
                          )}
                        </p>
                      )
                    ) : (
                      // <div className="w-full text-gray-750">
                      //   <p className="text-center">No Participants Found</p>
                      // </div>
                      <Loader />
                    )}
                  </div>
                  <div className="md:hidden">
                    <div className="mb-3 md:hidden flex justify-between">
                      <div className="flex gap-1 items-center">
                        <FontAwesomeIcon
                          icon={faSearch}
                          className={`${showMobileSearchBox ? 'text-blue-350' : 'text-gray-450'} `}
                          onClick={() => setShowMobileSearchBox(true)}
                        />

                        <div
                          onClick={filterPopupHandler}
                          className="flex cursor-pointer items-center"
                        >
                          <img
                            src={FilterIcon}
                            className="cursor-pointer w-5 h-5"
                            alt="filter icon"
                          />
                          <p className="text-gray-450 text-sm cursor-pointer">Filters</p>
                        </div>
                      </div>
                      <div
                        className="text-sm font-medium text-blue-350 cursor-pointer"
                        onClick={() => setViewDrawsScreenMobile(true)}
                      >
                        View Draws by Sport
                      </div>
                    </div>
                    <div>
                      {showMobileSearchBox ? (
                        <div className="md:hidden">
                          <SearchBox
                            initialState={searchTerm}
                            searchSetter={setSearchTerm}
                            closeSetter={setShowMobileSearchBox}
                            showClose
                          />
                        </div>
                      ) : null}
                    </div>
                    <div
                      className="result_card_container bg-white overflow-y-auto"
                      style={{ maxHeight: '700px' }}
                      ref={infiniteRefMobile}
                    >
                      {!participantListQueryData.isFetchedAfterMount ? (
                        <>
                          <Loader className={'mt-4'} />
                        </>
                      ) : !participantListQueryData.data ||
                        participantListQueryData?.data?.pages?.[0]?.data?.length === 0 ? (
                        <h1
                          data-cy="data_empty"
                          className="w-full text-center my-20 text-sm text-gray-750"
                        >
                          {!searchTerm ? (
                            <>{tableText}</>
                          ) : (
                            'There Is No Existing Data That Matches  The Search Result'
                          )}
                        </h1>
                      ) : (
                        <>
                          {date ? (
                            <>
                              <ListLoader
                                queryData={participantListQueryData}
                                queryKey="result"
                                isInfinite
                                isInfiniteFetching={participantListQueryData.isFetchingNextPage}
                              >
                                {({ item, index }) => {
                                  return (
                                    <ResultCard
                                      showCategoryInMiddle
                                      enableLinks={true}
                                      data={item}
                                      onClick={() => {
                                        if (
                                          item.opponent_1.name === 'NA' &&
                                          item.opponent_2.name === 'NA'
                                        ) {
                                          return;
                                        }
                                        setExpandedView(item);

                                        console.log(item);
                                      }}
                                    />
                                  );
                                }}
                              </ListLoader>
                            </>
                          ) : (
                            <>
                              {participantsData.map((el, i) => (
                                <div key={i}>
                                  <div className="flex justify-between mb-4 sticky -top-2 bg-white py-2 z-5">
                                    <div className="text-xs">{getDateInMonthDateYear(el.date)}</div>
                                  </div>
                                  {el.list.map((item, i) => (
                                    <ResultCard
                                      searchTerm={searchTerm}
                                      showCategoryInMiddle
                                      enableLinks
                                      key={i}
                                      data={item}
                                      onClick={() => {
                                        if (
                                          item.opponent_1.name === 'NA' &&
                                          item.opponent_2.name === 'NA'
                                        ) {
                                          return;
                                        }
                                        setExpandedView(item);
                                      }}
                                    />
                                  ))}
                                </div>
                              ))}
                            </>
                          )}
                          {participantListQueryData.isFetchingNextPage && (
                            <Loader width={8} height={8}></Loader>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div
                    className={`fixed overflow-y-auto shadow-footerPopUp h-3/4 rounded-t-xl w-full bg-white flex flex-col justify-between px-4 py-6 bottom-0 right-0 left-0 z-20 ${
                      filterPopup ? 'block' : 'hidden'
                    } `}
                  >
                    <div>
                      <div className="flex justify-between">
                        <h3 className="text-base font-bold gray-text-750">Filters </h3>
                      </div>

                      <div className="mt-8">
                        <div className="mb-4 w-full">
                          <p className="text-sm mb-1 ">Sport</p>
                          <SelectList
                            dataCy="draws_sport_selector"
                            selectedValue={sport}
                            setSelectedValue={setSport}
                            placeholder="Sport"
                            listData={sportList}
                            fullWidth={true}
                            filterClose={true}
                            setFilterPopup={() => {}}
                          />
                          <p className="text-gray-750 text-sm shadow-paymentShadow pt-4 mt-4">
                            Gender
                          </p>
                          <SelectList
                            selectedValue={gender}
                            setSelectedValue={setGender}
                            placeholder="Gender"
                            listData={genderList}
                            fullWidth={true}
                            filterClose={true}
                            setFilterPopup={() => {}}
                          />
                          <p className="text-gray-750 text-sm shadow-paymentShadow pt-4 mt-4">
                            {' '}
                            Age
                          </p>
                          <SelectList
                            selectedValue={age}
                            setSelectedValue={setAge}
                            placeholder="Age"
                            listData={ageList}
                            fullWidth={true}
                            filterClose={true}
                            setFilterPopup={() => {}}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 items-center text-sm md:text-base mt-auto sticky w-full bottom-0 py-2 bg-white">
                      <button
                        onClick={() => setFilterPopup(false)}
                        className="btn btn-primary btn-md btn-block  bg-gray-750 hover:bg-orange-kheloOrange flex gap-1 items-center"
                      >
                        <FontAwesomeIcon icon={faCheck} />
                        <p>Apply</p>
                      </button>
                      <button
                        onClick={clearFilterHandler}
                        className="flex gap-1 items-center justify-center cursor-pointer reg-btn-outline-dark mr-1 w-full hover:bg-gray-250 font-semibold rounded-lg mt-2 py-2 text-gray-dark"
                      >
                        <FontAwesomeIcon icon={faTimes} />
                        <p>Clear</p>
                      </button>
                    </div>
                  </div>

                  <div className="flex gap-2 mb-8 flex-wrap pt-8" ref={scheduleRef}>
                    {scheduleTabQueryData.isFetched &&
                      Array.isArray(scheduleTabQueryData?.data?.message) &&
                      scheduleTabQueryData?.data?.message?.map((el, i) => (
                        <ScheduleCard key={i} {...el} />
                      ))}
                  </div>
                  {/* <span ref={imageRef}>
                  <Schedule />
                </span> */}
                </>
              ) : (
                <div>
                  <h1 className="text-base text-black font-bold my-3">View Draws by Sport</h1>
                  <DrawEventForm
                    handleDrawFormat={handleDrawFormat}
                    isLoading={sportEventQueryData?.isLoading}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="w-full pb-4">
              <div className="shadow-shadowBottom pb-3 mb-8">
                <h1 className="text-2xl font-bold">View Schedules</h1>
              </div>
              <div>
                <div className="flex gap-2 mb-4 flex-wrap">
                  {scheduleTabQueryData.isFetched &&
                    Array.isArray(scheduleTabQueryData?.data?.message) &&
                    scheduleTabQueryData?.data?.message?.map((el, i) => (
                      <ScheduleCard key={i} {...el} />
                    ))}
                </div>
              </div>
              <img src={drawBanner} className="text-center mx-auto" alt={''} />
            </div>
          )}
        </div>
      </>
    );
  return (
    <Draws
      userData={userData}
      expandedView={expandedView}
      opponent={opponent}
      setOpponent={setOpponent}
      setExpandedView={setExpandedView}
    />
  );
}

export function SearchBox({ initialState, searchSetter, closeSetter, showClose }) {
  const [state, setState] = useState(initialState);
  const debouncedState = useDebounce(state, 500);
  const isKhelo = useShowKhelo();
  useEffect(() => {
    searchSetter(debouncedState);
  }, [debouncedState]);
  return (
    <div className="py-2 px-4 rounded-md flex gap-3 items-center border-1 border-gray-475 mx-2">
      <FontAwesomeIcon icon={faSearch} className="text-gray-450 " />
      <input
        value={state}
        className="outline-none border-0 p-0 focus:outline-none border-white shadow-none w-full md:w-auto"
        type="text"
        onChange={(e) => setState(e.target.value)}
        placeholder={`Search by Athlete or ${isKhelo ? 'State' : 'Institution'}`}
      ></input>
      {showClose && <img src={CloseIcon} onClick={() => closeSetter && closeSetter(false)} />}
    </div>
  );
}
/**
 * A function to calculate the result on basis of various conditions
 * @param {boolean | string} won - did this opponent win or lose or draw or qualified or not qualified
 * @param {string} condition - match status, is match completed , is it a walkover, etc.
 * @param {boolean} draw - is it a draw
 * @param {string} text - special text that will be used incase it is provided
 * @returns {string} the winning condition
 */
export function calculateConditions(won, condition = 'completed', draw, text) {
  const hashmap = { won: 1, lost: 0, draw: 'draw' };
  // if type of won is string, a special case where the status or the result comes in won itself
  if (
    typeof won === 'string' &&
    (won?.toLowerCase() === 'qualified' || won?.toLowerCase() === 'not qualified')
  )
    return won;
  // return condition itself in case if it is provided for qualified and not qualified case
  if (condition.toLowerCase() === 'qualified' || condition.toLowerCase() === 'not qualified')
    return condition;
  // return draw itself in case if it is provided for draw case
  if (condition.toLowerCase() === 'draw') return 'Draw';
  // special text case returns text if provided
  if (text) return text;
  // mapping won  to boolean if its a string
  won = typeof won === 'string' ? hashmap[won.toLowerCase()] : won;
  if (condition.toLowerCase() === 'completed') {
    if (won) return 'Won';
    if (draw || won === 'draw') return 'Draw';
    return 'Lost';
  }
  if (condition.toLowerCase() === 'bye') return 'BYE';
  if (condition.toLowerCase() === 'walkover') {
    if (won) return 'Walkover';

    return 'Did Not Show';
  }
  if (condition.toLowerCase() === 'no_show') {
    return 'Did Not Show';
  }

  if (condition.toLowerCase() === 'digital_score') return 'Yet to play';
  if (condition.toLowerCase() === 'digital_scoring_in_progress') {
    return 'In Progress';
  }
}
// for cards showing view schedule with link
function ScheduleCard({ image, title, link, linkText }) {
  return (
    <div className="bg-white border border-gray-300 flex flex-col  rounded-lg px-4 md:px-4 py-4 md:py-5 w-47% md:w-60 items-center">
      <div className="flex gap-2">
        <img src={image} className="w-12 h-12" alt={''} />
        <div>
          <div>{title}</div>
          {typeof link === 'string' ? (
            <a
              href={`${link}`}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => {
                if (!link) e.preventDefault();
              }}
              className="text-xxs md:text-sm"
            >
              {linkText || 'Click here to download PDF'}
            </a>
          ) : (
            <p className="text-xxs md:text-sm">Will be updated soon.</p>
          )}
        </div>
      </div>
    </div>
  );
}
