import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

function NavigateCard({ header, icon, navigateTo, image, toggleHandler, dataCy }) {
  return (
    <Link to={navigateTo} onClick={() => (toggleHandler ? toggleHandler(false) : null)}>
      <div>
        <div className="px-6 py-2.5 items-center max-w-full bg-white flex  font-roboto rounded-lg">
          {image ? (
            <img src={image} />
          ) : (
            <FontAwesomeIcon icon={icon} className="fa-lg text-light-orange" />
          )}
          <p data-cy={dataCy} className="text-sm font-bold ml-5 flex-grow text-dark-gray">
            {header}
          </p>
        </div>
      </div>
    </Link>
  );
}

export default NavigateCard;
