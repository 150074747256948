import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDateInDateMonth, getDateInYear } from 'constants/DateFunctions';
import { Link } from 'react-router-dom';

const NewsCard = ({ cover, date, title, link }) => {
  return (
    <div className="h-68 md:h-86 w-full relative shadow-shadowPopup rounded-2xl overflow-hidden ">
      <div className="h-72 attraction-img-wrapper relative">
        <img src={cover} className="h-full w-full" alt="" />
      </div>
      <div className="bg-white px-6 pt-4 pb-3 absolute w-full bottom-0 rounded-2xl h-32 ">
        <div className="relative">
          <div className="flex justify-between items-end absolute -top-12 w-full">
            <p className="text-sm font-normal text-white font-poppins">{`${
              getDateInDateMonth(date).split(' ')[0]
            }th ${getDateInDateMonth(date).split(' ')[1]}, ${getDateInYear(date)}`}</p>
            {/* <p className="text-xs md:text-sm text-white">{time}</p> */}
          </div>
        </div>
        <div className="flex flex-col justify-between h-full">
          <div>
            <h4 className="text-gray-750 font-roboto text-md font-bold mb-2">{title}</h4>
            {/* <p className="text-gray-750 text-xs">{description}</p> */}
          </div>
          <div className="flex items-center justify-start">
            <div className="flex gap-2 justify-between items-center mb-3">
              {link ? (
                <Link to={link} className="text-xs text-gray-650 flex gap-2 items-center">
                  Read More <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsCard;
