import EventCard from './EventCard';
import Substitute from '../../../../assets/img/Substitute.svg';
import FootballWhite from '../../../../assets/img/footballwhite.svg';
import redCard from '../../../../assets/img/redCard.svg';
import yellowCard from '../../../../assets/img/yellowCard.svg';
import greenDownArrowfrom from '../../../../assets/img/greenDownArrow.svg';
import redUpArrow from '../../../../assets/img/redUpArrow.svg';

export const Cards = {
  substitutes: (time, col1Data, col2Data) => (
    <EventCard
      heading={'Substitute'}
      headingIcon={Substitute}
      col1Type="detailed"
      col2Type="detailed"
      col1Data={{ ...col1Data, icon: redUpArrow }}
      col2Data={{ ...col2Data, icon: greenDownArrowfrom }}
      time={time}
      enableIcon={true}
    ></EventCard>
  ),
  goals: (time, col1Data, col2Data, el) => (
    <EventCard
      heading={el.subType === 'penaltyShootout' ? 'Penalty Shootout' : 'Goal!!!'}
      headingIcon={FootballWhite}
      col1Type="detailed"
      col2Type="image"
      time={time}
      col1Data={col1Data}
      col2Data={col2Data}
      headingColor={'text-white'}
      headingBG={'bg-green-450'}
    ></EventCard>
  ),
  yellow_cards: (time, col1Data, col2Data) => (
    <EventCard
      heading={'Yellow Card'}
      headingIcon={yellowCard}
      col1Type="detailed"
      col2Type="image"
      time={time}
      col1Data={col1Data}
      col2Data={col2Data}
    ></EventCard>
  ),
  red_cards: (time, col1Data, col2Data) => (
    <EventCard
      heading={'Red Card'}
      headingIcon={redCard}
      col1Type="detailed"
      col2Type="image"
      time={time}
      col1Data={col1Data}
      col2Data={col2Data}
    ></EventCard>
  ),
};
