import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVenus } from '@fortawesome/free-solid-svg-icons';

import { getDateInMonthDateYear, getTimeIn12HourFormat } from '../../../constants/DateFunctions';
import calendarToday from '../../../assets/img/calenderToday.svg';
import officeBuilding from '../../../assets/img/grayOffice.svg';

import maleIcon from '../../../assets/img/male.svg';

// import { isKhelo } from '../../utils/kheloConditions';

import { faClock } from '@fortawesome/free-regular-svg-icons';

export default function Header({
  eventName,
  city,
  startDate,
  endDate,
  img,
  variant,
  gender,
  _ageGroup,
  venue,
  time,
  hideOnRoutes,
  hideTabs,
  removePaddingOnRoutes,
  userData,
}) {
  return (
    <>
      <div className=" mt-4 bg-white backdrop-filter backdrop-blur-lg px-4 rounded-lg w-full  max-w-hdplus">
        <div className="flex justify-between items-center md:shadow-shadowBottom pb-0 md:pb-6">
          <div className="flex gap-3 md:gap-5 md:items-center">
            <div
              className={`flex border flex-shrink-0 
                     bg-blue-950 border-gray-300
                   rounded-full w-10 h-10 md:h-12 md:w-12 items-center justify-center mt-1 md:mt-0`}
            >
              <img src={img} alt="logo" className="h-6" />
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <h1 className="text-gray-750 text-lg md:text-2xl font-bold">{eventName}</h1>
                {/* <FontAwesomeIcon
                      icon={faShareAlt}
                      className="text-gray-650 opacity-60 text-xl cursor-pointer"
                    /> */}
              </div>

              <div className="flex flex-wrap gap-2">
                {gender.toLowerCase() === 'male' ? (
                  <div className="px-1 py-0.5 flex flex-row gap-2 items-center justify-center font-bold text-xxs text-blue-iris bg-blue-irisLight rounded">
                    {' '}
                    <img src={maleIcon} alt={''} />
                    {gender}
                  </div>
                ) : (
                  <div className="px-1 py-0.5 flex flex-row gap-2 items-center justify-center font-bold text-xxs text-blue-iris bg-blue-irisLight rounded">
                    {' '}
                    <FontAwesomeIcon icon={faVenus} />
                    {gender}
                  </div>
                )}
                <div className="px-1 py-0.5 flex flex-row gap-2 items-center justify-center font-bold text-xxs text-pink-750 bg-pink-150 rounded">
                  <img src={calendarToday} alt={''} />
                  {'U-19'}
                </div>
              </div>
              <div className=" flex flex-row gap-2 items-center  text-xxs text-gray-650">
                <FontAwesomeIcon icon={faClock} className="text-gray-650" />
                {getDateInMonthDateYear(time)} at {getTimeIn12HourFormat(time)}
              </div>
              <div className=" flex flex-row gap-2 items-center  text-xxs text-gray-650">
                <img src={officeBuilding} alt={''} />
                {venue}
              </div>
            </div>
          </div>

          {/* <div className="flex flex-col items-end gap-1.5">
                  <p className="px-4 py-2 bg-green-450 rounded-lg text-white font-bold cursor-pointer">
                    Register Now at ₹ {price}*
                  </p>
                  <p className="text-gray-650 opacity-60 text-sm">{remarks}</p>
                </div> */}
        </div>
      </div>
    </>
  );
}
