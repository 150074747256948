import React from 'react';
import { useLocation } from 'react-router-dom';

export default function ReportEmbed(props) {
  const location = useLocation();
  console.log('location', location);
  return (
    <iframe
      src={'https://analytics.zoho.in' + location.pathname}
      width="100%"
      style={{ height: window.innerHeight }}
    />
  );
}
