import React from 'react';
import AttractionCard from 'micro-site/Components/AttractionsCard';
import { useEventAttractionsByType, useEventAttractionsType } from 'micro-site/queries/hooks';
// import Helmet from 'react-helmet';
import Loader from 'components/Loader';
import { useParams } from 'react-router-dom';

export default function Attractions() {
  // const eventAttractionsListQueryData = useEventAttractions({
  //   tournament_id: '221',
  // });
  const params = useParams();
  const eventAttractionsByType = useEventAttractionsType({
    tournamentId: params?.tournament_id,
  });

  // useEffect(() => {
  //   if (eventAttractionsListQueryData.isFetched && eventAttractionsListQueryData.data)
  //     setEventAttractionsByType(
  //       eventAttractionsListQueryData?.data?.reduce((prev, curr) => {
  //         return {
  //           ...prev,
  //           [curr.event_attraction_type]: prev?.[curr?.event_attraction_type]
  //             ? [...prev?.[curr?.event_attraction_type], { ...curr }]
  //             : [{ ...curr }],
  //         };
  //       }, {})
  //     );
  // }, [eventAttractionsListQueryData.data]);
  if (eventAttractionsByType.isLoading) return <Loader />;
  return (
    <div className="px-4 md:px-6 pt-4 pb-14 max-w-fhd mx-auto no-scrollbar">
      {/* <Helmet>
        <title>Special Events & Attractions | KIYG Haryana 2021</title>
        <meta
          name="description"
          content="Don't miss out on the SPECIAL MOMENTS at #KIYG2021. Keep checking this page for LATEST UPDATES on all SPECIAL EVENTS, GUEST APPEARANCES and lots more!"
        />
        <meta property="og:title" content="Special Events & Attractions | KIYG Haryana 2021" />
        <meta
          property="og:description"
          content="Don't miss out on the SPECIAL MOMENTS at #KIYG2021. Keep checking this page for LATEST UPDATES on all SPECIAL EVENTS, GUEST APPEARANCES and lots more!"
        />
      </Helmet> */}
      <hr className="w-16 mx-0 md:hidden my-0 md:w-60 bg-gray-750 h-0.5 md:h-1 mt-1 md:mt-6" />
      {eventAttractionsByType?.data?.map?.((el, i) => (
        <AttractionsSection key={i} heading={el.event_attraction_type} />
      ))}
    </div>
  );
}

function AttractionsSection({ heading }) {
  const eventQuery = useEventAttractionsByType({ type: heading });
  if (eventQuery.isLoading) return null;
  return (
    <div key={heading}>
      <h3 className="text-lg md:pb-3 md:shadow-shadowBottom md:text-2xl font-bold text-gray-750 mt-10">
        {heading}
      </h3>
      <div className="grid auto-rows-min mt-10 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 lg:gap-10">
        {eventQuery?.data?.map((item, index) => {
          return <AttractionCard key={index} data={item} />;
        })}
      </div>
    </div>
  );
}
