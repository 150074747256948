import Loader from 'components/Loader';
import { useAnthem } from 'micro-site/queries/hooks';
import { useParams } from 'react-router-dom';

const Anthem = () => {
  const params = useParams();
  const anthemQuery = useAnthem({ tournament: params?.tournament_id });
  if (anthemQuery?.isFetching) return <Loader />;
  return (
    <>
      <section className="flex justify-center">
        <div className="font-montserrat w-full mb-16" style={{ maxWidth: '1200px' }}>
          <h1 className="text-2.5xl font-extrabold uppercase mt-5 text-center text-blue-navBg mb-5">
            {anthemQuery?.data?.anthem_first_heading}
          </h1>
          <div className=" px-9">
            <div
              className="mx-auto"
              style={{
                left: 0,
                width: '100%',
                height: 0,
                position: 'relative',
                paddingBottom: '40%',
                maxWidth: '900px',
              }}
            >
              <iframe
                width="100%"
                height="100%"
                style={{ top: 0, position: 'absolute', left: 0 }}
                src={`https://www.youtube.com/embed/${anthemQuery?.data?.anthem_video}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      {anthemQuery?.data?.anthem_content ||
      anthemQuery?.data?.anthem_pic ||
      anthemQuery?.data?.anthem_second_heading ||
      anthemQuery?.data?.anthem_pin2 ? (
        <section className="flex justify-center bg-blue-mascotBg">
          <div className="font-montserrat w-full mb-16 px-9" style={{ maxWidth: '1200px' }}>
            <h1 className="text-2.5xl font-extrabold uppercase mt-10 text-center text-blue-navBg mb-6">
              {}
            </h1>
            <div className="flex">
              <img
                style={{ maxHeight: '500px' }}
                src={anthemQuery?.data?.anthem_pic1}
                className="mr-6 md:inline-block hidden"
              />
              <div
                className=" px-3 border-2 mx-2 rounded-md border-purple-anthemBorder overflow-auto w-full"
                style={{ height: '500px', width: 'calc(33.3333% - 13.3333px)' }}
                dangerouslySetInnerHTML={{ __html: anthemQuery?.data?.anthem_content }}
              ></div>
              <img
                style={{ maxHeight: '500px' }}
                src={anthemQuery?.data?.anthem_pin2}
                className="ml-6 md:inline-block hidden"
              />
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default Anthem;
