import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
// import GalleryCard from '../../../Components/GalleryCard';
// import GalleryIcon from '../../../../assets/img/gallery_icon.svg';
import { useGalleryMedia } from '../../../queries/hooks';
// import ListLoader from '../../../../components/ListLoader';
// import DetailItemLoader from '../../../../components/DetailItemLoader';
// import Modal from './Modal';
import MediaSlider from './MediaSlider';
// import Helmet from 'react-helmet';

export default function IndividualEvent({ userRole, className }) {
  const history = useHistory();
  const match = useRouteMatch();

  // const [viewAll, setViewAll] = useState(false);
  console.log(match);
  const folderMediaList = useGalleryMedia({
    folder: `${match.params.section}-${match.params.folder}`,
  });
  return (
    <div className={className || 'px-4 md:px-16 pb-14 max-w-fhd mx-auto bg-white'}>
      {/* <Helmet>
        <title>Gallery | KIYG Haryana 2021</title>
        <meta
          name="description"
          content="Here are the best moments captured at the #KIYG2021! Missed out on the action? Here is your chance to re-live it! Check this page daily for LATEST UPLOADS."
        />
        <meta property="og:title" content="Gallery | KIYG Haryana 2021" />
        <meta
          property="og:description"
          content="Here are the best moments captured at the #KIYG2021! Missed out on the action? Here is your chance to re-live it! Check this page daily for LATEST UPLOADS."
        />
      </Helmet> */}
      <div
        className="flex gap-2 md:gap-4 items-center text-gray-750 font-semibold cursor-pointer md:shadow-shadowBottom md:mb-6 md:pt-8 md:pb-2 pt-2"
        onClick={() => {
          history?.location?.state?.fromAttractions
            ? history.goBack()
            : history.goBack(`/micro-site/${match.params.tournament}/landing/gallery`);
        }}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
        <h3 className="text-lg md:text-2xl font-bold text-gray-750 font-poppins">
          {match?.params?.folder}
        </h3>
      </div>
      <hr className="w-16 md:hidden mx-0 my-0 md:w-60 bg-gray-750 h-0.5 md:h-1 mt-1 md:mt-6" />
      <div className="">
        {/* <ListLoader
          queryData={folderMediaList}
          queryKey={'name'}
          EmptyMessage={'Stay tuned , Images and Videos will be updated shortly'}
          showLimited={!viewAll}
          limit={6}
          EmptyMessageStyle={{
            position: 'absolute',
            margin: 'auto',
            backgroundColor: '#fff',
            padding: '10px',
          }}
        >
          {(item, index) => {
            return ''
          }}
        </ListLoader> */}

        <MediaSlider
          data={folderMediaList}
          // limit={folderMediaList?.data?.length > 10 && !viewAll ? 10 : null}
        />
      </div>
      {/* <DetailItemLoader queryData={folderMediaList}>
        {({ data }) => {
          if (data.length > 10) {
            return (
              <div className="mt-4 flex justify-center items-center">
                <button
                  className="w-full md:w-1/3 reg-btn-dark text-white font-bold py-2 px-4 rounded-lg"
                  onClick={() => setViewAll(!viewAll)}
                >
                  {viewAll ? 'View Less' : 'View All'}
                </button>
              </div>
            );
          }
          return null;
        }}
      </DetailItemLoader> */}

      {/*
      <div
        className="text-gray-750 font-semibold cursor-pointer md:shadow-shadowBottom md:mb-6 md:pt-24 md:pb-2 pt-2"
        onClick={() => {
          history.goBack();
        }}
      >
        <h3 className="text-lg md:text-2xl font-bold text-gray-750 font-poppins">
          You might also like
        </h3>
        <hr className="w-16 md:hidden mx-0 my-0 md:w-60 bg-gray-750 h-0.5 md:h-1 mt-1 md:mt-6" />
      </div>
*/}
    </div>
  );
}

// function IndividualCard({ _index, data }) {
//   const [modal, setModal] = useState(false);
//   const [modalData, setModalData] = useState({});

//   const mediaDetails = useMediaDetails({
//     media_item: data.name,
//   });

//   useEffect(() => {
//     if (!mediaDetails.isLoading) {
//       console.log(mediaDetails.data);
//     }
//   }, [mediaDetails]);

//   return (
//     <>
//       <DetailItemLoader queryData={mediaDetails}>
//         {({ data }) => {
//           return (
//             <div
//               onClick={() => {
//                 setModal(true);
//                 setModalData(data);
//               }}
//               className="cursor-pointer relative card-bg rounded-lg overflow-hidden"
//             >
//               {data.type.toLowerCase().includes('video') && (
//                 <div
//                   className={
//                     'absolute top-0 left-0 h-full w-full flex justify-center items-center opacity-70 rounded-lg'
//                   }
//                 >
//                   <FontAwesomeIcon icon={faPlayCircle} className=" text-gray-100 z-3 text-5xl" />
//                 </div>
//               )}
//               <img
//                 onContextMenu={(e) => e.preventDefault()}
//                 src={
//                   data.type === 'Image'
//                     ? data.image
//                     : data.type === 'Video(youtube)'
//                     ? `https://ytimg.googleusercontent.com/vi/${data.video_id}/hqdefault.jpg`
//                     : data.type === 'Video(vimeo)'
//                     ? `https://vumbnail.com/${data.video_id}.jpg`
//                     : null
//                 }
//                 alt=""
//                 className="h-full w-full object-cover"
//               />
//               <div className="absolute ml-4 bottom-4">
//                 <h3 className="text-white text-xs md:text-lg font-bold mt-1 font-poppins">
//                   {data.media_name}
//                 </h3>
//                 {/* <p className="text-white text-xxs md:text-xs md:mt-1">{item.img_description}</p> */}
//               </div>
//             </div>
//           );
//         }}
//       </DetailItemLoader>
//       {modal ? <Modal setModalClose={setModal} data={modalData} /> : null}
//     </>
//   );
// }
