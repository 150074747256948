import Loader from 'components/Loader';
import { useHome } from 'profiles/queries/profileHooks';
import { useParams } from 'react-router-dom';
import Draws from './components/Draws';
import GeneralSection from './components/GeneralSection';
import HeroSection from './components/HeroSection';
import MarkDownSection from './components/MarkDownSection';
import MedalTally from './components/MedalTally';
import Partners from './components/Partners';
import Photos from './components/Photos';
import Sports from './components/Sports';

const Home = () => {
  const params = useParams();
  const homeQuery = useHome({ tournament: params.tournament_id });
  if (homeQuery.isFetching) return <Loader />;
  return (
    <div>
      {homeQuery?.data?.map((section) => (
        <RenderComponent {...section} key={section.component} />
      ))}
    </div>
  );
};

export default Home;

function RenderComponent({ component, props, show_section: showSection }) {
  if (component === 'heroSection' && showSection) return <HeroSection {...props} />;
  if (component === 'sportsSection' && showSection) return <Sports {...props} />;
  if (component === 'rankingTable' && showSection) return <MedalTally {...props} />;
  if (component === 'photosComponent' && showSection) return <Photos {...props} />;
  if (component === 'markdownSection' && showSection) return <MarkDownSection {...props} />;
  if (component === 'partners' && showSection) return <Partners {...props} />;
  if (component === 'detailed_scoring' && showSection) return <Draws {...props} />;
  if (component === 'general' && showSection) return <GeneralSection {...props} />;

  return null;
}
