// Micro-site Sports Page -- /landing/sports/<name>
export const filtersText = 'No sport events for selected filters';
export const drawsText = 'This information will be updated soon.';
export const rankingRecentText = 'This information will be updated after the Games begin.';
export const galleryText = 'Keep a close watch on this space to find out the pictures of ';
export const rulesText = 'This information will be updated soon.';

// Micro-site Draws & Schedule Page -- /landing/draws-schedules
export const tableText = 'This information will be updated soon.';

// Micro-site Results Page -- /landing/results
export const rankingTableText = 'This information will be updated after the Games begin.';
export const recentWinnersText = 'This information will be updated after the Games begin.';
export const fullRankingTableKheloText =
  'Keep A Close Watch On This Space To Find Out The Best State Level Performance in KIYG.';
export const fullRankingTableSFAText =
  'Keep a close watch on this space to find out the best school for sport in Uttarakhand.';
export const winnerCardListKheloText =
  'Keep A Close Watch On This Space To Find Out The Best State Level Performance in KIYG.';
export const winnerCardListSFAText =
  'Keep a close watch on this space to find out the best school for sport in Uttarakhand.';
export const noWinnersText = 'No Winners ';

// Micro-site Gallery Page -- /landing/gallery
export const noMediaText = 'Stay tuned , Images and Videos will be updated shortly';

// Micro-site News Page -- /landing/news
export const noNewsText = 'No Media to show right now.';

// micro-site -- micro-site/components/InformationCard
export const noInformationText = 'This information will be updated soon.';

// micro-site -- micro-site/components/RecentWinnersCarousel
export const noWinnersCarouselText = 'No Winners till now';

// Digital-Scoring Page
export const noDataText = 'This information will be updated soon';
export const noRoundsHeatsText = 'Rounds are not created yet for this sport event';
export const noMedalWinnersHighJumpLongJumpText = 'Medal Winners Are Yet To Be Declared.';
export const noRoundsSwissText = 'Rounds are not created yet for this sport event';

// Digital-scoring -- digital-scoring/component/PlaceHolder
export const noDrawsText = ' There are no draws created for this Sport Event yet';

// Profiles Match & Fixtures Page -- Athlete Fixtures
export const noAthleteFixturesDataText = 'This information will be updated soon.';

// Profiles Match & Fixtures Page -- Coach Fixtures
export const noTeamMatchText = 'Your team does not have any matches yet.';
export const noTeamBuild = 'Your event list will be updated soon.';

// Profiles Contingents
export const noContingentsKheloText = `Your state's contingent for the Championship will be updated here.`;
export const noContingentsSFAText = `Your institute's contingent for the Championship will be updated here.`;
export const contingentTableCardText = 'No information available yet';

// Profiles Events Page
export const noEventsAthleteText = 'Your events for the Championship will be updated here';
export const noEventsCoachText = 'Your events for the Championship will be updated here';
export const noEventsInstitutionText = 'Your events for the Championship will be updated here';
export const coachVerificationPendingText =
  'Kindly Note Your Verification Status Confirmed Within 3 Days. Upon Successful Verification You Shall Be Able To Generate Team Registration Links.';
export const coachNoAthleteFoundText = 'No Athletes (Students) Found.';
export const coachNoMatchFixturesText =
  'Match Fixtures & Results for your team will be displayed here.';
export const coachTeamAddLoadingText =
  'Kindly Note Your Teams For The Championship Will Be Displayed Here';

// Profiles Tournament Details/SportsEvents
export const noSportEventListText = 'Your events for the Championship will be updated here';
export const noSportEventListFilterText =
  'There was no sport event match to the filters applied. Remove some filters or reset filters to get new results.';

// Profiles Tournament Details/payments
export const sportEventEmptyCartText = 'No Sport Events Added to Cart';

// ACT - Transport
export const noTransportAllotedText = 'Transportation is not alloted.';
export const notAssignedText = 'To Be Assigned Shortly';
export const noTransportInformationText = 'This information will be updated soon.';
export const infoUpdateText = 'Information to be updated shortly.';
