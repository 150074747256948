import React, { useState, useEffect, useRef } from 'react';
import { useDrawDateList } from '../queries/hooks.js';
import Loader from 'components/Loader/index.js';
import { getDateInMonthDate, getDateInYear } from '../../constants/DateFunctions';
import { useShowKhelo } from 'utils/HooksUtils.js';
import { useParams } from 'react-router-dom';
export default function ScheduleTabs({ setDate, scrollRef }) {
  const [selected, setSelected] = useState('All');

  const setTabs = (item, idx) => {
    setSelected(idx);
    setDate(item);
  };
  const params = useParams();
  const isKhelo = useShowKhelo();
  const drawDateListQueryData = useDrawDateList({
    tournament: params.tournament_id,
  });
  const elementRef = useRef(null);
  useEffect(() => {
    if (drawDateListQueryData?.data) {
      const today = new Date();
      const actualDate = new Date(
        today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate()
      ).toJSON();
      const index = drawDateListQueryData?.data?.findIndex(
        (el) => new Date(el).toJSON() === actualDate
      );
      if (index !== -1) {
        setSelected(index);
        setDate(drawDateListQueryData?.data[index]);
      }
    }
  }, [drawDateListQueryData.isFetched]);
  useEffect(() => {
    if (elementRef.current) {
      scrollRef.current.scrollTo({
        left: elementRef.current.offsetLeft - elementRef.current.clientWidth * 2,
        behavior: 'smooth',
      });
    }
  }, [selected]);
  return (
    <div>
      {drawDateListQueryData.data ? (
        <div className="w-full flex">
          {drawDateListQueryData.data.length > 0 ? (
            <div
              className={`bg-cover min-w-schedule-tab h-9 md:h-14 flex flex-col justify-center items-center shadow-shadowLeft relative cursor-pointer italic ${
                selected === 'All'
                  ? `text-white ${isKhelo ? 'bg-khelo-active-schedule' : 'bg-active-schedule'}`
                  : 'text-gray-500 bg-gray-20'
              }`}
              onClick={() => setTabs('', 'All')}
            >
              <h1 className="font-bold text-sm ">All</h1>
            </div>
          ) : null}

          {drawDateListQueryData.data.length > 0 ? (
            drawDateListQueryData.data.map((item, idx) => (
              <div
                data-cy="draws_date"
                key={idx}
                ref={selected === idx ? elementRef : { current: null }}
                className={`bg-cover min-w-schedule-tab h-9 md:h-14 flex flex-col justify-center shadow-shadowLeft relative cursor-pointer italic ${
                  selected === idx
                    ? `text-white ${isKhelo ? 'bg-khelo-active-schedule' : 'bg-active-schedule'}`
                    : 'text-gray-400 bg-gray-20'
                }`}
                onClick={() => setTabs(item, idx)}
              >
                <div className="mx-auto">
                  <h1
                    className="text-xxs md:text-sm mb-2 mb:mb-0 uppercase"
                    style={{ fontWeight: '700' }}
                  >
                    {getDateInMonthDate(item)},
                  </h1>
                  <h2
                    className="italic text-xxs leading-3 md:leading-5  md:text-sm -mt-2"
                    style={{ fontWeight: '700' }}
                  >
                    {getDateInYear(item)}
                  </h2>
                </div>
              </div>
            ))
          ) : (
            <div className="px-16 text-gray-750">No Draws</div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}
