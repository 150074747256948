import { faArrowRight, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory } from 'react-router-dom';
import VenueCard from '../components/VenueCard';
import VenueMobileCard from '../components/VenueMobileCard';

export default function All({ tournamentId, setCurCity, isMobile, landingAllData, cityList }) {
  const history = useHistory();
  const clickHandler = (city) => {
    history.push(`/micro-site/${tournamentId}/landing/venues`);

    if (city !== 'All') {
      history.push(`/micro-site/${tournamentId}/landing/venues/${city}`);
    }
    setCurCity(city);
  };
  console.log(landingAllData);
  return (
    <>
      {!isMobile
        ? landingAllData &&
          Object.keys(landingAllData)?.map((city, i) => {
            return (
              <div className="w-full px-auto mx-10 my-5" key={i}>
                <p className="text-l font-bold mb-5 text-blue-kheloBlue">{`${city} Venues`}</p>
                <div className="flex justify-between">
                  {landingAllData[city]?.slice(0, 2).map((el, i) => (
                    <VenueCard
                      key={i}
                      {...el}
                      image={el.banner_pic}
                      redirectTo={`/micro-site/${tournamentId}/landing/venues/${city}/${el.venue}`}
                    />
                  ))}
                </div>
                <div
                  className="text-purple-venueTxt w-31 float-right my-5 bg-yellow-viewVenue rounded-lg font-bold font-roboto p-2 cursor-pointer"
                  onClick={() => clickHandler(city)}
                >
                  View more
                  <FontAwesomeIcon icon={faArrowRight} className="mx-2" />
                </div>
              </div>
            );
          })
        : landingAllData &&
          Object.keys(landingAllData)?.map((city, i) => {
            return (
              <div className="mb-5" key={i}>
                <p className="flex justify-between font-bold mb-5 text-blue-kheloBlue">
                  <span className="text-l">{`${city} Venues`}</span>
                  <div
                    className="text-purple-venueTxt text-sm m-auto mx-0 w-31 underline"
                    onClick={() => clickHandler(city)}
                  >
                    View more
                    <FontAwesomeIcon icon={faAngleRight} className="mx-2" />
                  </div>
                </p>
                <div className="grid grid-cols-2 gap-2 md:hidden">
                  {landingAllData[city]?.slice(0, 2).map((el, i) => (
                    <VenueMobileCard
                      key={i}
                      image={el.banner_pic}
                      name={el.venue}
                      redirectTo={`/micro-site/${tournamentId}/landing/venues/${city}/${el.venue}`}
                    />
                  ))}
                </div>
              </div>
            );
          })}
    </>
  );
}
