const EventList = ({ listOne, listTwo, icon, masterListOne, masterListTwo }) => {
  if (listOne?.length === 0 && listTwo?.length === 0) return null;

  return (
    <div className="flex justify-between my-4">
      <div className="text-xxs md:text-xs flex flex-col gap-2 w-full">
        {listOne?.map((el, i) => (
          <div key={i}>
            {masterListOne[el?.athleteIndex]?.name} {el?.minutes && el?.minutes + "'"}
          </div>
        ))}
      </div>
      <div className="flex-shrink-0">{icon}</div>
      <div className="text-xxs md:text-xs flex flex-col gap-2 w-full text-right">
        {listTwo?.map((el, i) => (
          <div key={i}>
            {masterListTwo[el?.athleteIndex]?.name} {el?.minutes && el?.minutes + "'"}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventList;
