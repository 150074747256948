import styles from './styles/scheduleCard.module.css';
import { getDateInDateMonth } from 'constants/DateFunctions';
import { useHistory, useParams } from 'react-router-dom';

export default function ScheduleCard({ cardData }) {
  const history = useHistory();
  const params = useParams();
  return (
    <div
      className={' w-full  rounded-md flex gap-4 md:gap-12 pt-6 pb-2 ' + styles.container}
      onClick={() => {
        history.push(`/micro-site/${params.tournament_id}/landing/sports/${cardData.title}`);
      }}
    >
      <img src={cardData?.img} className="h-32 p-4"></img>
      <div className="flex justify-between w-full  gap-2 md:gap-4 grid grid-cols-2 md:grid-cols-4 md:pr-8">
        <div className="font-montserrat flex flex-col md:gap-4 text-xs md:text-base">
          <p>Sport</p>
          <p className="md:text-l text-xs font-bold text-blue-kheloBlue ">{cardData.title}</p>
        </div>
        <div className="font-montserrat flex flex-col md:gap-4  text-xs  md:text-base">
          <p>City</p>
          <p className="md:text-l text-xs font-bold text-blue-kheloBlue ">{cardData.venue_city}</p>
        </div>
        <div className="font-montserrat flex flex-col md:gap-4  text-xs  md:text-base">
          <p>Venue</p>
          <p className="md:text-l text-xs font-bold text-blue-kheloBlue ">{cardData.venue}</p>
        </div>
        <div className="font-montserrat flex flex-col md:gap-4  text-xs  md:text-base">
          <p>Date</p>
          <p className="md:text-l text-xs font-bold text-blue-kheloBlue ">
            {getDateInDateMonth(cardData.start_date)}-{getDateInDateMonth(cardData.end_date)}
          </p>
        </div>
      </div>
    </div>
  );
}
