import React from 'react';
import Loader from '../Loader';
const DetailItemLoader = ({ queryData, customMessage, ...props }) => {
  if (queryData.isLoading)
    return (
      <div className="flex w-full justify-center bg-white items-center">
        <Loader />
      </div>
    );
  if (queryData.isError) return <h1>Error</h1>;
  if (!props.children)
    return (
      <p className="w-full text-center my-20 text-sm text-gray-750">
        {customMessage || 'This component does not have renderable child'}{' '}
      </p>
    );
  return <props.children {...queryData} />;
};

export default DetailItemLoader;
