import { useHistory } from 'react-router-dom';
const VenueMobileCard = ({ image, type, name, redirectTo }) => {
  const history = useHistory();
  return (
    <div
      className="relative  rounded-lg font-montserrat w-full min-h-50 "
      style={{
        backgroundImage: `${`linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url('${image}')`}`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
      onClick={() => {
        history.push(redirectTo);
      }}
    >
      <h1 className="text-white font-bold font-montserrat text-sm absolute bottom-0 p-4">{name}</h1>
    </div>
  );
};

export default VenueMobileCard;
