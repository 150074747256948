import { nanoid } from 'nanoid';
import TagManager from 'react-gtm-module';
import { roundOfTwoDigits } from './formattingUtils';
const GTM_ID = process.env.REACT_APP_GTM_KEY;

export function gtmInitialize(args) {
  if (typeof args === 'undefined') {
    TagManager.initialize({
      gtmId: GTM_ID,
    });
  } else
    TagManager.initialize({
      gtmId: GTM_ID,
      ...args,
    });
}
export function transformCartGTM(
  cartItems,
  cartSummary,
  res,
  tournamentId,
  tournamentDetails,
  order
) {
  TagManager.dataLayer({ ecommerce: null });
  const temp = {
    dataLayer: {
      event: 'purchase',
      ecommerce: {
        tournament_name: tournamentDetails?.data?.tournament_name || '',
        transaction_id:
          res?.razorpay_payment_id ||
          order?.id ||
          res?.id ||
          'Cash_' + Date.now() + '_' + nanoid(6),
        currency: order?.currency || 'INR',
        affilation: 'SFA ONLINE STORE',
        value: roundOfTwoDigits(cartSummary?.data?.total),
        amount_after_discount: roundOfTwoDigits(cartSummary?.data?.amount_after_discount),
        tax: roundOfTwoDigits(
          cartSummary?.data?.taxes?.reduce((prev, current) => prev.amount + current.amount, {
            amount: 0,
          })
        ),
        taxes: cartSummary?.data?.taxes?.map((el) => ({
          ...el,
          amount: roundOfTwoDigits(el.amount),
        })),
        coupon: cartSummary?.data?.discount?.coupon,
        used_credits: cartSummary?.data?.used_credits,
        discount: roundOfTwoDigits(cartSummary?.data?.discount?.amount),
        items: Array.isArray(cartItems?.cart_items)
          ? cartItems.cart_items.map((el, index) => {
              const { gender = '', price = '', sport = '' } = el;
              return {
                index: index,
                item_name: el.sport_event,
                item_category: sport,
                item_category2: gender,
                item_category3: el.age_group,
                price: roundOfTwoDigits(parseFloat(price)),
                currency: order?.currency || 'INR',
                item_category4: el.sub_category,
                quantity: 1,
              };
            })
          : [],
      },
    },
  };

  return temp;
}
