import Loader from 'components/Loader';
import { useNews } from 'micro-site/queries/hooks';
// import Helmet from 'react-helmet';

import NewsCard from '../component/NewsCard';
import { noNewsText } from 'data/noDataText';
import { useParams } from 'react-router-dom';

const News = () => {
  const actualParams = useParams();
  const newsQuery = useNews({
    tournamentId: actualParams?.tournament_id,
  });

  return (
    <>
      {/* <Helmet>
        <title>Latest News & Updates | KIYG Haryana 2021</title>
        <meta
          name="description"
          content="Check this page for LATEST NEWS & UPDATES from the #KIYG2021. Stay connected with the Games here through daily Match Records, Media Articles and lots more!"
        />
        <meta property="og:title" content="Latest News & Updates | KIYG Haryana 2021" />
        <meta
          property="og:description"
          content="Check this page for LATEST NEWS & UPDATES from the #KIYG2021. Stay connected with the Games here through daily Match Records, Media Articles and lots more!"
        />
      </Helmet> */}
      {newsQuery.isFetching ? (
        <Loader />
      ) : (
        <div>
          <div className="px-6 mt-11 md:mt-24 pt-1 text-center mb-8">
            <h1 className="font-extrabold text-blue-kheloBlue font-montserrat md:text-4xl mb-2 text-lg">
              Media
            </h1>
          </div>
          {Array.isArray(newsQuery?.data) && newsQuery?.data?.length > 0 ? (
            <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-6 md:px-16 py-8 mb-8">
              {newsQuery?.data?.map((el, i) => (
                <NewsCard
                  key={i}
                  cover={el?.cover_image}
                  date={el?.date}
                  // date={el?.creation?.split(' ')?.[0]}
                  // time={el?.creation?.split(' ')?.[1]}
                  // description={el?.description}
                  title={el?.title}
                  link={`media/${el?.title}`}
                />
              ))}
            </div>
          ) : (
            <p className="w-full text-center my-4 text-sm text-gray-750">{noNewsText}</p>
          )}
        </div>
      )}
    </>
  );
};

export default News;
<div>Welcome to the Media</div>;
