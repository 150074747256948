import React from 'react';
import calendar from '../../assets/img/micro_calendar.svg';
// import download from '../../assets/img/download_icon.svg';
import ruleIcon from '../../assets/img/rules_icon.svg';
import prizeIcon from '../../assets/img/prize_icon.svg';

export default function CardDetail({ subContent, index }) {
  const getIcon = () => {
    if (subContent.header === 'Rules and Regulations (General)') return ruleIcon;
    if (subContent.header === 'Prize Information') return prizeIcon;
    return calendar;
  };
  if (!subContent.value) return null;
  return (
    <div className="pb-8 relative">
      <div className="flex gap-x-4 mb-3 md:flex-col md:gap-y-6 items-center md:items-start">
        <img src={getIcon()} className="md:w-12 md:h-12 w-9 h-9" alt="" />
        <div className="flex w-full justify-between">
          <h3 className="text-sm gray-text-750">{subContent.header}</h3>
          <div
            className={`${
              subContent.pdf_url ? 'flex' : 'hidden '
            } absolute bottom-0 right-4 md:relative gap-2`}
          >
            {/* <img src={download} alt="download-icon" /> */}
            {/* <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_97_21360)">
                <path
                  d="M14.3864 7.60012H11.3998V3.12012H6.91977V7.60012H3.93311L9.15977 12.8268L14.3864 7.60012ZM3.93311 14.3201V15.8134H14.3864V14.3201H3.93311Z"
                  fill="#1388DD"
                />
              </g>
              <defs>
                <clipPath id="clip0_97_21360">
                  <rect
                    width="17.92"
                    height="17.92"
                    fill="white"
                    transform="translate(0.199707 0.880127)"
                  />
                </clipPath>
              </defs>
            </svg> */}

            {/* <a href={`${subContent.pdf_url}`} className="text-sm font-medium text-blue-300">
              Download Rules and Guidelines PDF
            </a> */}
          </div>
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: subContent.value,
        }}
        className="view ql-container"
        style={{ color: '#606060' }}
      />
    </div>
  );
}
