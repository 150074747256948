import { useAboutUs } from 'micro-site/queries/hooks';
import { useParams } from 'react-router-dom';
import Loader from 'components/Loader';

const AboutUs = ({ type }) => {
  const params = useParams();
  const aboutUsQuery = useAboutUs({ tournament: params?.tournament_id, title: type });
  if (aboutUsQuery.isFetching) return <Loader />;

  return (
    <div>
      {aboutUsQuery?.isFetching ? (
        <Loader className={'h-40-screen'} />
      ) : (
        <>
          <img
            className="m-auto  object-cover h-full md:block hidden mt-2"
            src={aboutUsQuery?.data?.about_us_banner_images?.[0]?.image}
          />
          <img
            className="m-auto  object-cover h-full block md:hidden"
            src={aboutUsQuery?.data?.about_us_banner_images?.[0]?.image_mobile}
          />
        </>
      )}
      <div>
        <div className="mx-auto px-4 md:px-0 font-montserrat" style={{ maxWidth: '1000px' }}>
          <div
            className=""
            dangerouslySetInnerHTML={{ __html: aboutUsQuery?.data?.about_us_details }}
          ></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
