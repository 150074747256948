const PrivacyPolicy = () => {
  return (
    <div className="max-w-2xl mx-auto my-8 p-2 sm:p-0">
      <h1 className="text-3xl font-bold mb-4 text-center">Privacy Policy</h1>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
        <p className="text-justify">
          In our application, the user account deletion process is designed to prioritize user
          privacy, data protection, and strict adherence to Government's guidelines. This process
          aims to provide users with a secure and transparent method to delete their accounts and
          associated data, ensuring a seamless experience.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">2. Initiating Account Deletion</h2>
        <p className="text-justify">
          Users can trigger the account deletion process by sending a deletion request via email.
          This email address (info@kiygtamilnadu.in) will be available within the app, promoting a
          controlled environment for user-initiated actions.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">3. Verification and Security Measures</h2>
        <p className="text-justify">
          Upon receiving a deletion request, a thorough verification process will be initiated on
          priority. This will involve cross-referencing the provided details with existing user
          records to ensure the legitimacy of the request. We will implement strong security
          measures to safeguard against unauthorized deletion attempts, ensuring the integrity of
          the account deletion process.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">4. Commitment to Data Protection</h2>
        <p className="text-justify">
          Our commitment to safeguard user data is persistent. The account deactivation phase, which
          precedes data removal, will be implemented to provide a grace period during which the
          account becomes inaccessible while preserving the data.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">5. Data Removal Process</h2>
        <p className="text-justify">
          This includes deleting information both on the client-side (device) and the server-side,
          leaving no trace of the user's personal data. Every step in this process ensures a
          thorough and irreversible deletion.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">6. Confirmation and Transparency</h2>
        <p className="text-justify">
          Once the deletion process will be completed, a confirmation email will be promptly sent to
          the user. This email will serve as proof of the successful deletion and will include
          relevant details such as the completion date. Our commitment to transparency is evident
          throughout, as we aim to keep users informed about the status of their account deletion
          requests.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">7. Timeline and Compliance</h2>
        <p className="text-justify">
          The entire process is designed to be efficient yet comprehensive, with an expected
          timeline of approximately 2 working days. This timeframe strikes a balance between user
          expectations and the need for data removal process.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
