import React from 'react';

import calendar from '../../assets/img/micro_calendar.svg';
import noteIcon from '../../assets/img/note_icon.svg';
import mailICon from '../../assets/img/mail_icon.svg';
import addressIcon from '../../assets/img/address_icon.svg';

export default function ContactCard({ content }) {
  const getIcon = () => {
    if (content.header === 'Contact Person') return noteIcon;
    if (content.header === 'Contact Number') return calendar;
    if (content.header === 'Address ') return addressIcon;
    if (content.header === 'Email ID') return mailICon;
  };

  return (
    <div className="rounded-2xl w-full lg:w-auto overview-card bg-white md:px-6 md:py-6 px-3.5 py-4">
      <img src={getIcon()} className="md:w-12 md:h-12 w-9 h-9" alt="" />
      <div className="mt-2.5 md:mt-6">
        <p className="text-xs md:text-sm text-gray-lighter">{content.header}</p>
        <h3 className="text-sm md:text-lg gray-text-800 mt-1 break-words md:mt-3 font-medium">
          {content.value}
        </h3>
      </div>
    </div>
  );
}
