import { Route, Redirect } from 'react-router-dom';
import ProtectedLayout from 'components/layouts/ProtectedLayout';
import { useUserProfile } from 'profiles/queries/profileHooks';
import Loader from 'components/Loader';
import FeatureConfigs from 'utils/FeatureConfigs';
import { defaultRedirectTo } from 'components/routes';

export default function ProtectedRoute({ component: Component, ...rest }) {
  // fetch user profile details
  const { isLoading, isError, status } = useUserProfile();

  if (isError) {
    FeatureConfigs.ALLOW_ROUTE_REDIRECTION &&
      sessionStorage.setItem(
        'redirectToURL',
        `${window.location.pathname}${window.location.search}`
      );
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    params.institution && sessionStorage.setItem('institution', params.institution);
    params.otp && sessionStorage.setItem('otp', params.otp);
    params.role && sessionStorage.setItem('inviteRole', params.role);
    window.location.href = ((params.otp || params.institution) && '/register') || defaultRedirectTo;
  } else {
    sessionStorage.removeItem('redirectToURL');
    sessionStorage.removeItem('institution');
    sessionStorage.removeItem('otp');
    sessionStorage.removeItem('inviteRole');
  }
  return isLoading ? (
    <div className="flex justify-center items-center h-screen w-full ">
      <Loader />
    </div>
  ) : (
    <Route
      {...rest}
      render={(props) =>
        status === 'success' ? (
          <ProtectedLayout>
            <Component {...props} />
          </ProtectedLayout>
        ) : (
          <Redirect to={sessionStorage.redirectToUrl || '/register'} />
        )
      }
    />
  );
}
