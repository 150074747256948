import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';
/* Initialize analytics & load plugins */
let analytics;
if (process.env.REACT_APP_GA_TRACKING_ID)
  analytics = Analytics({
    app: 'SFA',
    plugins: [
      googleAnalytics({
        trackingId: process.env.REACT_APP_GA_TRACKING_ID,
      }),
    ],
  });

export default analytics;
