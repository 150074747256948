import { Route, Switch } from 'react-router-dom';
import NotFoundPage from 'components/ErrorPage';
/**
 *
 * @param {{children:React.ReactNode}} -takes children as props
 *
 * A component that attaches a 404 page automatically
 * @returns
 */
const CustomSwitch = ({ children }) => {
  return (
    <Switch>
      {children}
      <Route path="*">
        <NotFoundPage
          title={'404'}
          line1={"Oops! You're lost"}
          line2="We can not find the page you're looking for."
        />
      </Route>
    </Switch>
  );
};

export default CustomSwitch;
