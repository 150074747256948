export const matchStatus = {
  walkover: 'Walkover',
  'did not show': 'Did Not Show',
  bye: 'BYE',
  no_show: 'No Show',
};

export const yetToPlayStatus = {
  'yet to play': 'Yet To Play',
  digital_score: 'Yet To Play',
};

export const realTimeStatus = {
  digital_scoring_in_progress: 'In Progress',
};
