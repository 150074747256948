import Loader from 'components/Loader';
import { useMascot } from 'micro-site/queries/hooks';
import { useParams } from 'react-router-dom';

const Mascot = () => {
  const params = useParams();
  const mascotQuery = useMascot({ tournament: params.tournament_id });
  if (mascotQuery.isFetching) return <Loader />;
  return (
    <>
      <section className="flex justify-center">
        <div className="font-montserrat" style={{ maxWidth: '1200px' }}>
          <h1 className="text-3.5xl font-extrabold uppercase mt-14 text-center text-purple-mascotText">
            {mascotQuery?.data?.mascot_first_heading}
          </h1>
          <div className="flex gap-10 mt-10 p-5 flex-col-reverse md:flex-row">
            <div
              dangerouslySetInnerHTML={{ __html: mascotQuery?.data?.mascot_content }}
              style={{ maxWidth: '800px' }}
            >
              {/* <p style={{ maxWidth: '800px' }} className="text-base text-purple-mascotText">
              The Official mascot for the 36th National Games is “SAVAJ”. The stance of the Mascot
              clearly portrays a sportsman’s most prominent personality traits of self-confidence,
              vigour, a strong sense of motivation, an inner desire to succeed, a strong sense of
              focus, a natural leader, and of a goal setter. Perfectionism and willingness to fight
              are what define the Mascot. Our mascot is depicting the integration of Indian heritage
              and reflects the idea of a rapidly growing India becoming a global leader again. It
              emphasizes on Gujarat’s roaring success in the field of development and economy. In
              the last 25 years, the state has developed world-class sports infrastructure making
              India a sports destination. The aggression in the performance along with the composure
              on the outside makes the mascot of the National Games a cultural icon too, depicting
              some of the greatest traits of the legends of the land like Sardar Vallabhbhai Patel,
              Mahatma Gandhi and many more. Savaj- taranhar(તારણહાર) in gujarati means “Savior” in
              English. Savaj is the representation of The Asiatic Lion, which today survives only in
              wild India. Since the turn of the 20th century, its range has been restricted to the
              Gir National Park and the surrounding areas in the Indian state of Gujarat. The
              Asiatic lion's fur color ranges from ruddy-tawny, heavily speckled with black,
              sometimes with a silvery sheen in certain lighting. The Gir Lion is similar in size to
              the Central African lion. The symbolic meaning of lions, as one might imagine,
              primarily deals with strength. This serves as a symbolic message of balance and sound
              judgment. In that the lion shares the world of both night and day, the lion bears a
              message of prudence to us.
            </p> */}
            </div>
            <img
              src={mascotQuery?.data?.mascot_pic1}
              className="mx-auto md:mx-0"
              style={{ width: 'calc(50% - 40px)' }}
            />
          </div>
        </div>
      </section>
      <section className="flex justify-center bg-blue-mascotBg">
        <div className="font-montserrat w-full mb-20" style={{ maxWidth: '1200px' }}>
          <h1 className="text-xl md:text-3.5xl font-extrabold uppercase mt-14 text-center text-purple-mascotText mb-3">
            {mascotQuery?.data?.mascot_second_heading}
          </h1>
          {mascotQuery?.data?.mascot_video ? (
            <div className=" px-9">
              <div
                className="mx-auto"
                style={{
                  left: 0,
                  width: '100%',
                  height: 0,
                  position: 'relative',
                  paddingBottom: '40%',
                  maxWidth: '900px',
                }}
              >
                <iframe
                  width="100%"
                  height="100%"
                  style={{ top: 0, position: 'absolute', left: 0 }}
                  src={`https://www.youtube.com/embed/${mascotQuery?.data?.mascot_video}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          ) : null}
        </div>
      </section>
    </>
  );
};

export default Mascot;
