import { genQueryParams } from 'utils/helpers';
import Http from 'utils/Http';

const apiEndPoints = {
  getTournamentList: 'sfa_online.v1.profile.get_participated_tournaments',
  getNotificationsList: 'sfa_online.v1.profile.get_notifications',
  getTournamentEventsList: 'sfa_online.v1.profile.tournament_list_events',
  getMatchDetailsBySportEvent: 'sfa_online.v1.profile.get_match_details',
  getMedalWinnerBySportEvent: 'sfa_online.v1.profile.medal_winners_by_sport_event',
  getTournamentDetails: 'sfa_online.v1.profile.get_tournament_details',
  getSportListByTournamentId: 'sfa_online.v1.profile.tournament_list_sports',
  getSportEventList: 'sfa_online.v1.profile.tournament_list_sport_events_by_sport',
  getMatchFixtureDates: 'sfa_online.v1.profile.get_match_fixture_dates',
  getMatchFixturesList: 'sfa_online.v1.profile.get_match_fixtures',
  getAccommodationDetails: 'sfa_online.v1.act.get_accomodation',
  getContingentList: 'sfa_online.v1.profile.get_contingent',
  getContingentFilters: 'sfa_online.v1.profile.get_contingent_filter',
  getContingentDetails: 'sfa_online.v1.profile.get_contingent_details',
  getAllSportEventsUnderTournamentList: 'sfa_online.v1.profile.get_sport_event_participants',
  getTeamUnderSportEventList: 'sfa_online.v1.registrations.team.list_teams',
  getProfileOverview: 'sfa_online.v1.profile.get_institution_public_profile',
  getProfileRank: 'sfa_online.v1.profile.get_institution_rank',
  getTeamDetails: 'sfa_online.v1.registrations.team.get_team_details',
  getTeamPlayersList: 'sfa_online.v1.registrations.team.list_players',
  postCreateTeam: 'sfa_online.v1.registrations.team.create_team',
  postDeleteTeam: 'sfa_online.v1.registrations.team.delete_team',
  postDeleteParticipant: 'sfa_online.v1.registrations.team.delete_participant',
  getTeamMedalWinners: 'sfa_online.v1.registrations.team.medal_winners',
  getTeamMatchFixtures: 'sfa_online.v1.registrations.team.team_match_fixtures',
  getTeamConfimationForm: 'sfa_online.v1.registrations.team.get_sport_form',
  getSubmittedFormData: 'sfa_online.v1.registrations.team.get_sport_filled_data',
  postTeamSubmitSportForm: 'sfa_online.v1.registrations.team.submit_sport_form',
  postConfirmTeam: 'sfa_online.v1.registrations.team.confirm_team',
  getCoachVerificationStatus: 'sfa_online.v1.registrations.team.get_coach_verfication_status',
  getBillingDetails: 'sfa_online.v1.registrations.athlete_billing_details.get_billing_details',
  applyRefund: 'sfa_online.v1.registrations.athlete_billing_details.apply_for_refund',
  getInstitutionVerificationStatus: 'sfa_online.v1.profile.check_institution_verification_status',
  getSportVideoId: 'sfa_online.v1.profile.get_sport_video_id',
  canShowDigitalScoring: 'sfa_online.v1.profile.can_show_scoring',
  getMatchAnalytics: 'sfa_online.v1.digital_scoring.get_analytics',
  getAchievements: 'sfa_online.v1.achievements.get_achievements',
  getAchievementFilters: 'sfa_online.v1.achievements.get_filters',
  getProfileVideos: 'sfa_online.v1.profile.get_profile_videos',
  getDashboardList: 'sfa_online.sfa_setup.doctype.zoho_dashboard_user.api.get_dashboards',
  getAdvanceDs: 'sfa_online.v1.advance_ds.league_ds.get_league_ds_record',
  getProfileDetailsId: 'sfa_act.sfa_act.doctype.attendee.attendee.attendee_details',
  approveVerification: 'sfa_act.sfa_act.doctype.attendee.attendee.pv_status',
  fetchCatering: 'sfa_act.sfa_act.doctype.catering_checkin.catering_checkin.get_catering_log',
  approveCateringReq: 'sfa_act.sfa_act.doctype.catering_checkin.catering_checkin.catering_entry',
  tournamentRankStats: 'sfa_online.v1.profile.get_tournament_stats_rank',
  tournamentTeamStats: 'sfa_online.v1.profile.get_tournament_stats_teams',
  tournamentAthleteStats: 'sfa_online.v1.profile.get_tournament_stats_athletes',
};

export async function getTournamentList(active) {
  try {
    const res = await Http.fetch({
      apiEndpoint: apiEndPoints.getTournamentList,
    });
    return active ? res.message.participated_tournaments : res.message.upcomming_tournament;
  } catch (error) {
    console.log(error);
  }
}

export async function getNotificationsList() {
  try {
    const res = await Http.fetch({
      apiEndpoint: apiEndPoints.getNotificationsList,
    });
    return res.message.notifications;
  } catch (error) {
    console.log(error);
  }
}

export async function getTournamentEventsList(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getTournamentEventsList}?tournament_id=${params.tournament_id}`,
    });
    return res.message.events;
  } catch (error) {
    console.log(error);
  }
}

export async function getMatchDetailsBySportEvent(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getMatchDetailsBySportEvent}?sport_event=${params.sportEvent}`,
    });
    return res.message.match_details;
  } catch (error) {
    console.log(error);
  }
}

export async function getMedalWinnerBySportEvent(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getMedalWinnerBySportEvent}?sport_event=${params.sportEvent}`,
    });
    return res.message.winners;
  } catch (error) {
    console.log(error);
  }
}

export async function getTournamentDetails(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getTournamentDetails}?tournament_id=${params.tournamentId}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getAccommodationDetails(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getAccommodationDetails}?user_id=${params.user_id}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getSportListByTournamentId(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getSportListByTournamentId}?tournament_id=${params.tournamentId}`,
    });
    return res.message.sports;
  } catch (error) {
    console.log(error);
  }
}

export async function getSportEventList(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getSportEventList}?tournament_id=${params.tournamentId}&sport=${params.sport}`,
    });
    return res.message.events;
  } catch (error) {
    console.log(error);
  }
}

export async function getMatchFixtureDates(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getMatchFixtureDates}?tournament_id=${params.tournamentId}&institution=${params.institution}`,
    });
    return res.message.dates;
  } catch (error) {
    console.log(error);
  }
}

export async function getMatchFixturesList(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getMatchFixturesList}?tournament_id=${params.tournamentId}&institution=${params.institution}&date=${params.date}`,
    });
    return res.message.match_fixtures;
  } catch (error) {
    console.log(error);
  }
}

export async function getContingentList(params, offset) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getContingentList}?tournament_id=${params?.tournamentId}&gender=${params?.gender}&age_group=${params?.age_group}&type=${params?.type}&sport=${params?.sport}&verification_status=${params?.verification_status}&designation=${params?.designation}&sub_category=${params?.sub_category}&limit=${params?.limit}&offset=${offset}&sfa_id=${params?.sfa_id}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getContingentDetails(params) {
  try {
    const res = await Http.fetch({
      method: 'POST',
      apiEndpoint: `${apiEndPoints.getContingentDetails}`,
      options: {
        tournament_participant_id: params?.id,
      },
    });
    return res.message.sport_events;
  } catch (error) {
    console.log(error);
  }
}

export async function getAllSportEventsUnderTournamentList(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getAllSportEventsUnderTournamentList}?tournament_id=${params.tournament_id}&gender=${params.gender}&age_category=${params.age_category}&sport=${params.sport}&sport_category=${params.sport_category}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getProfileOverview(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getProfileOverview}?tournament_id=${params.id}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getTeamUnderSportEventList(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getTeamUnderSportEventList}?sport_event=${params.sport_event}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getProfileRank(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getProfileRank}?tournament_id=${params.id}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getTeamDetails(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getTeamDetails}?team_id=${params.team_id}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getTeamPlayersList(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getTeamPlayersList}?sport_event=${params.sport_event}&verification_status=${params.verification_status}&team_id=${params.team_id}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function postCreateTeam(params) {
  try {
    return await Http.fetch({
      apiEndpoint: `${apiEndPoints.postCreateTeam}`,
      method: 'POST',
      options: params,
    });
  } catch (error) {
    throw error.message;
  }
}

export async function postDeleteTeam(params) {
  try {
    return await Http.fetch({
      apiEndpoint: `${apiEndPoints.postDeleteTeam}`,
      method: 'POST',
      options: params,
    });
  } catch (error) {
    throw error.message;
  }
}

export async function postDeleteParticipant(params) {
  try {
    return await Http.fetch({
      apiEndpoint: `${apiEndPoints.postDeleteParticipant}`,
      method: 'POST',
      options: params,
    });
  } catch (error) {
    throw error.message;
  }
}

export async function getTeamMedalWinners(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getTeamMedalWinners}?sport_event=${params.sport_event}`,
    });
    return res.message.winners;
  } catch (error) {
    console.log(error);
  }
}

export async function getTeamMatchFixtures(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getTeamMatchFixtures}?sport_event=${params.sport_event}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getTeamConfimationForm(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getTeamConfimationForm}?team_id=${params.team_id}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getSubmittedFormData(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getSubmittedFormData}?team_id=${params.team_id}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function postTeamSubmitSportForm(params) {
  try {
    return await Http.fetch({
      apiEndpoint: `${apiEndPoints.postTeamSubmitSportForm}`,
      method: 'POST',
      options: params,
    });
  } catch (error) {
    throw error.message;
  }
}

export async function postConfirmTeam(params) {
  try {
    return await Http.fetch({
      apiEndpoint: `${apiEndPoints.postConfirmTeam}`,
      method: 'POST',
      options: params,
    });
  } catch (error) {
    throw error.message;
  }
}

export async function getCoachVerificationStatus(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getCoachVerificationStatus}?tournament_id=${params.tournament_id}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}
export async function getInstitutionVerificationStatus() {
  try {
    return await Http.fetch({
      apiEndpoint: `${apiEndPoints.getInstitutionVerificationStatus}`,
    });
  } catch (error) {
    console.log(error);
  }
}

export async function getProfileBillingInformation(_params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getBillingDetails}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function submitRefundForm(params) {
  try {
    return await Http.fetch({
      apiEndpoint: `${apiEndPoints.applyRefund}`,
      method: 'POST',
      options: params,
    });
  } catch (error) {
    throw error.message;
  }
}

export async function getSportVideoId(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: apiEndPoints.getSportVideoId,
      method: 'POST',
      options: params,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function canShowDigitalScoring({ tournamentId }) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.canShowDigitalScoring}?tournament_id=${tournamentId}`,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function getMatchAnalytics({ matchId }) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getMatchAnalytics}?match_id=${matchId}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getAchievements(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getAchievements}?medal=${params.medal}&tournament=${params.tournament}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getAchievementFilters() {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getAchievementFilters}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getProfileVideos(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getProfileVideos}?sports=${params.sports}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getDashboardsList() {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getDashboardList}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getAdvanceDs(params) {
  try {
    const { message } = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getAdvanceDs}?match=${params.matchId}`,
    });
    const data = {
      Athlete1: {
        ...message.athletes[0],
        wonIn: message.athletes[0].won,
        isWon: message.athletes[0]?.is_won,
        color: '#5d5fef',
        sets: message.sets.map((item, i) => {
          return { ...item[0], name: `set ${i + 1}` };
        }),
      },
      Athlete2: {
        ...message.athletes[1],
        isWon: message.athletes[1]?.is_won,
        wonIn: message.athletes[1].won,
        color: '#f59e0b',
        sets: message.sets.map((item, i) => {
          return { ...item[1], name: `set ${i + 1}` };
        }),
      },
    };
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getContingentFilters(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getContingentFilters}?tournament_id=${params.tournamentId}&type=${params.type}`,
    });
    return res.message;
  } catch (e) {
    console.error(e.message);
  }
}

export async function getProfileDetailsId(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getProfileDetailsId}?id=${params.id}`,
    });
    return res.message;
  } catch (error) {
    console.error(error.message);
  }
}

export async function approveVerification(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.approveVerification}`,
      method: 'POST',
      options: { remarks: params.remarks, id: params.id, status: params.status },
    });
    if (res?.message?.error) throw new Error(res?.message?.error);
    return res.message;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function approveCateringReq({
  sfaId = null,
  type = null,
  date = null,
  location = null,
}) {
  const res = await Http.fetch({
    method: 'POST',
    apiEndpoint: `${apiEndPoints.approveCateringReq}`,
    options: {
      sfa_id: sfaId,
      type,
      date,
      location,
    },
  });
  if (res?.message?.error) {
    throw new Error(res?.message?.error);
  }
  return res?.message;
}

export async function getCateringLog({ id = null }) {
  try {
    const res = await Http.fetch({ apiEndpoint: `${apiEndPoints.fetchCatering}?id=${id}` });
    return res.message;
  } catch (e) {
    console.error(e.message);
  }
}
/**
 *
 * @param {{tournament:number,sport:string}} params
 * @returns
 */
export async function getTournamentRankStats(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.tournamentRankStats}${genQueryParams(params)}`,
    });
    return res.message;
  } catch (e) {
    console.error(e.message);
  }
}

/**
 *
 * @param {{tournament:number,sport:string}} params
 * @returns
 */
export async function getAthleteStats(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.tournamentAthleteStats}${genQueryParams(params)}`,
    });
    return res.message;
  } catch (e) {
    console.error(e.message);
  }
}

/**
 *
 * @param {{tournament:number,sport:string}} params
 * @returns
 */
export async function getTeamStats(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.tournamentTeamStats}${genQueryParams(params)}`,
    });
    return res.message;
  } catch (e) {
    console.error(e.message);
  }
}
