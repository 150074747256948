import Navbar from 'components/navbar/navbarWithNotificationandProfile';
// import LightNavBar from '../../micro-site/Components/Navbar';
// import { useRouteMatch } from 'react-router-dom';

export default function ProtectedLayout({ children, hideNav }) {
  // const match = useRouteMatch();
  // const renderNav = () => {
  //   const routeMatch = match.path.split('/')[1];
  //   switch (routeMatch) {
  //     case ':user':
  //       return <Navbar />;
  //     case 'micro-site':
  //       return <LightNavBar />;
  //     default:
  //       <Navbar />;
  //   }
  // };

  return (
    <div className="flex flex-column">
      {!hideNav && <Navbar side />}

      <div className="flex-1 d-flex flex-column overflow-auto">{children}</div>
    </div>
  );
}
