import React, { useMemo } from 'react';
// import Helmet from 'react-helmet';
// import kheloScheduleMobile from '../../../assets/img/Mobile_schedule.jpg';
import ScheduleCard from './components/ScheduleCard';
import { useScheduleBanners, useTournamentSportList } from '../../queries/hooks';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import khelpmpban1 from '../../../assets/img/mpbanners/khelpmpban1.jpg';
// import khelpmpban2 from '../../../assets/img/mpbanners/khelpmpban2.jpg';
// import khelpmpban3 from '../../../assets/img/mpbanners/khelpmpban3.jpg';
// import khelpmpban4 from '../../../assets/img/mpbanners/khelpmpban4.jpg';
// import khelpmpban5 from '../../../assets/img/mpbanners/khelpmpban5.jpg';
// import khelpmpban6 from '../../../assets/img/mpbanners/khelpmpban6.jpg';
// import khelpmpban7 from '../../../assets/img/mpbanners/khelpmpban7.jpg';
// import khelpmpban8 from '../../../assets/img/mpbanners/khelpmpban8.jpg';
// import khelpmpban9 from '../../../assets/img/mpbanners/khelpmpban9.jpg';
// import khelpmpban10 from '../../../assets/img/mpbanners/khelpmpban10.jpg';
// import khelpmpban11 from '../../../assets/img/mpbanners/khelpmpban11.jpg';
// import khelpmpban12 from '../../../assets/img/mpbanners/khelpmpban12.jpg';
import Loader from 'components/Loader';
import { useParams } from 'react-router-dom';

export default function index() {
  const params = useParams();
  const data = useTournamentSportList({ tournamentId: params.tournament_id });
  const banners = useScheduleBanners({ tournament: params.tournament_id, page: 'schedule' });
  const time = useMemo(() => {
    return banners?.data?.data?.[1]?.time || 3;
  }, [banners.data]);

  const settings = {
    initialSlide: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: time * 1000,
    arrows: false,
    pauseOnHover: false,
  };

  const sportsList = useMemo(() => {
    const hash = {};
    data?.data?.forEach((el) => {
      if (hash[el.venue_city]) hash[el.venue_city].push(el);
      else hash[el.venue_city] = [el];
    });
    return hash;
  }, [data.data]);

  return (
    <div className="w-full md:px-0  mb-16 flex flex-col gap-8 items-center  mx-auto">
      {/* <Helmet>
        <title>Schedule | KIYG Haryana 2021</title>
        <meta
          name="description"
          content="Check the SCHEDULE now! 25 Sports | LIVE from 4th - 13th June 2022. Watch India's finest U/18 athletes represent their respective states at #KIYG2021!"
        />
        <meta property="og:title" content="Schedule | KIYG Haryana 2021" />
        <meta
          property="og:description"
          content="Check the SCHEDULE now! 25 Sports | LIVE from 4th - 13th June 2022. Watch India's finest U/18 athletes represent their respective states at #KIYG2021!"
        />
      </Helmet> */}
      {/* <div className="py-2">
        <a target="_blank" rel="noreferrer" href={kheloSchedule} download>
          Click here to download Full Schedule
        </a>
      </div> */}
      {/* <img className="m-auto hidden md:block" src={kheloSchedule} />
      <img className="m-auto md:hidden" src={kheloScheduleMobile} /> */}
      {banners?.isFetching ? (
        <Loader className={'h-40-screen'} />
      ) : (
        <>
          <Slider
            {...settings}
            className="w-full scheduleSlider md:block hidden override-slide-margin"
          >
            {banners?.data?.data?.[0]?.schedule_page_banner_images?.map((image, i) => (
              <img className="m-auto  object-cover h-full " src={image?.image} key={i} />
            ))}
          </Slider>
          <Slider
            {...settings}
            className="w-full scheduleSlider block  md:hidden override-slide-margin"
          >
            {banners?.data?.data?.[0]?.schedule_page_banner_images?.map((image, i) => (
              <img className="m-auto  object-cover h-full" src={image?.image_mobile} key={i} />
            ))}
          </Slider>
        </>
      )}
      <div className="px-4 md:px-0 w-full" style={{ maxWidth: '1200px' }}>
        {Object.keys(sportsList).map((el, i) => (
          <ScheduleSection key={i} heading={el} data={sportsList[el]}></ScheduleSection>
        ))}
      </div>
    </div>
  );
}

function ScheduleSection({ heading, data }) {
  return (
    <div className="w-full ">
      <div className="text-2xl md:text-6xl text-center text-blue-kheloBlue md:my-8 my-4 font-semibold">
        {heading}
      </div>
      <div className="flex flex-col gap-6 md:px-4 w-full">
        {data.map((el, i) => (
          <ScheduleCard key={i} cardData={el} />
        ))}
      </div>
    </div>
  );
}
