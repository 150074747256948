import { useBreakPoint } from 'utils/HooksUtils';

const MarkDownSection = ({ markdown, backgroundImage, center, mobileBackgroundImage }) => {
  const isLg = useBreakPoint('lg');
  return (
    <section
      id="markdown-section"
      style={{
        backgroundImage: `url('${isLg ? backgroundImage : mobileBackgroundImage}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
      className={`min-h-30-screen p-4 ${center ? 'flex justify-center items-center' : ''}`}
    >
      <div dangerouslySetInnerHTML={{ __html: markdown }}></div>
    </section>
  );
};

export default MarkDownSection;
