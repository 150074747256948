import { format, formatDistanceToNow } from 'date-fns';

export function getDateAndTime(params) {
  try {
    if (!params) return params;
    const newDate = new Date(params);
    return format(newDate, 'dd MMM,hh:mm aa');
  } catch (e) {
    console.error(e.message);
  }
}

export function getDateInDateMonth(params) {
  if (!params) return params;
  try {
    const newDate = new Date(params);
    return format(newDate, 'dd MMM');
  } catch (e) {
    console.error(e.message);
  }
}

export function getTimeInHHMM(params) {
  try {
    if (!params) return params;
    const newDate = new Date(params);
    return format(newDate, 'hh:mm aa');
  } catch (e) {
    console.error(e.message);
  }
}

export function getDateInMonthDateYear(params) {
  try {
    if (!params) return params;
    if (params === 'null') return '';
    const newDate = new Date(params);
    return format(newDate, 'MMM dd, yyyy');
  } catch (e) {
    console.error(e.message);
  }
}

export function getDateInMonthDate(params) {
  try {
    if (!params) return params;
    const newDate = new Date(params);
    return format(newDate, 'MMM dd');
  } catch (e) {
    console.error(e.message);
  }
}

export function getDateInYear(params) {
  try {
    if (!params) return params;
    const newDate = new Date(params);
    return format(newDate, 'yyyy');
  } catch (e) {
    console.error(e.message);
  }
}

export function getTimeIn12HourFormat(params) {
  try {
    if (!params) return params;
    const newDate = new Date(params);
    if (newDate.getHours() > 12) {
      return format(newDate, 'hh:mm a');
    }
    return format(newDate, 'hh:mm a');
  } catch (e) {
    console.error(e.message);
  }
}

export function getDateInDDMMYYYY(params) {
  try {
    if (!params) return params;
    const newDate = new Date(params);
    return format(newDate, 'dd-MM-yyyy');
  } catch (e) {
    console.error(e.message);
  }
}

export function getDurationFromNow(params) {
  try {
    if (!params) return params;
    const newDate = new Date(params);
    return formatDistanceToNow(newDate, { addSuffix: true });
  } catch (e) {
    console.error(e.message);
    return params.trim();
  }
}

export function getStartAndEndDate(start, end, short) {
  if (!start && !end) return '';
  try {
    if (!start || !end)
      return (
        (start && format(new Date(start), short ? 'MMM dd' : 'MMM dd, yyyy')) ||
        (end && format(new Date(end), short ? 'MMM dd' : 'MMM dd, yyyy'))
      );
    const startDate = new Date(start);
    const endDate = new Date(end);
    if (startDate.getTime() === endDate.getTime())
      return format(startDate, short ? 'MMM dd' : 'MMM dd, yyyy');
    return (
      format(startDate, short ? 'MMM dd' : 'MMM dd, yyyy') +
      ' - ' +
      format(endDate, short ? 'MMM dd' : 'MMM dd, yyyy')
    );
  } catch (e) {
    return '';
  }
}
export function getStartAndEndDateWithTime(start, end) {
  if (!start && !end) return '';
  try {
    if (!start || !end)
      return (
        (start && format(new Date(start), 'dd MMM yyyy - h:mm aaa')) ||
        (end && format(new Date(end), 'dd MMM yyyy - h:mm aaa'))
      );
    const startDate = new Date(start);
    const endDate = new Date(end);
    if (startDate.getTime() === endDate.getTime())
      return format(startDate, 'dd MMM yyyy - h:mm aaa');
    return (
      format(startDate, 'dd MMM yyyy - h:mm aaa') +
      ' to ' +
      format(endDate, 'dd MMM yyyy - h:mm aaa')
    );
  } catch (e) {
    return '';
  }
}

export function getTime(params) {
  if (!params) return params;
  function pad(n, z) {
    z = z || 2;
    return ('00' + n).slice(-z);
  }
  const ms = params % 100;
  params = (params - ms) / 100;
  const secs = params % 60;
  // params = (params - secs) / 60;
  return secs + ' sec' + ' ' + pad(ms) + ' ms';
}

export function getCheckTimePassed(params) {
  if (!params) return false;
  const newDate = new Date(params);
  const currentDate = new Date();

  return newDate.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0);
}

export function getCheckIfEventisLiveWithRange(start, end) {
  if (!start && !end) return false;
  const startDate = new Date(start);
  const endDate = new Date(end);
  const currentDate = new Date();
  return (
    startDate.setHours(0, 0, 0, 0) <= currentDate.setHours(0, 0, 0, 0) &&
    endDate.setHours(0, 0, 0, 0) >= currentDate.setHours(0, 0, 0, 0)
  );
}
