import React from 'react';

const ShowMedia = ({ mediaData, divRef }) => {
  return mediaData?.media_type === 'Video(youtube)' ? (
    <iframe
      ref={divRef}
      className={'w-full h-auto md:w-auto md:h-full border-5 border-white'}
      style={{
        aspectRatio: '16/9',
      }}
      src={`https://www.youtube.com/embed/${mediaData?.media_id}?autoplay=1`}
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  ) : mediaData?.media_type === 'Video(vimeo)' ? (
    <iframe
      ref={divRef}
      src={`https://player.vimeo.com/video/${mediaData?.media_id}?autoplay=1`}
      className={'w-full h-auto md:w-auto md:w-full border-5 border-white bg-white'}
      style={{
        aspectRatio: '16/9',
      }}
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
    />
  ) : null;
  /*
      return (
        <DetailItemLoader query={mediaDetailsQuery}>
          {(item) => {
            console.log({ item });
            // return data.media_details.map((media) => {
            // return <img src={data.image} alt={data.name} className={className} />;
            return <></>;
          }}
        </DetailItemLoader>
      );
    */
};

export default ShowMedia;
