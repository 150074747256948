import Header from './Header';
import gold from '../../../../assets/img/gold.svg';
import silver from '../../../../assets/img/silver.svg';
import bronze from '../../../../assets/img/bronze.svg';
import { useRankingTableList } from 'micro-site/queries/hooks';
import Loader from 'components/Loader';
import { Link, useParams } from 'react-router-dom';
import { useBreakPoint } from 'utils/HooksUtils';
const MedalTally = ({ rowCount, heading, data, backgroundImage, mobileBackgroundImage }) => {
  const params = useParams();
  const rankingQuery = useRankingTableList({
    tournament: params.tournament_id,
    limit: rowCount,
    sport: '',
    event: '',
    medal: '',
    round: '',
  });
  const isLg = useBreakPoint('lg');
  if (rankingQuery.isFetching) return <Loader />;
  return (
    <div
      className="flex flex-col items-center justify-center font-montserrat py-10 bg-blue-homeSection"
      style={{
        backgroundImage: isLg ? `url('${backgroundImage}')` : `url('${mobileBackgroundImage}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Header borderWidth="95%" {...heading} />
      <div className=" w-full px-2 lg:px-0 lg:w-3/4">
        <table className="text-center bg-white mt-10 w-full">
          <thead>
            <tr className="text-blue-navBg text-xs lg:text-base">
              <th
                className="py-5"
                style={{
                  borderWidth: '1px',

                  borderColor: 'rgb(209, 209, 209)',
                }}
              >
                POS
              </th>
              <th
                className="py-5"
                style={{
                  borderWidth: '1px',

                  borderColor: 'rgb(209, 209, 209)',
                }}
              >
                State
              </th>
              <th
                className="py-2"
                style={{
                  borderWidth: '1px',

                  borderColor: 'rgb(209, 209, 209)',
                }}
              >
                <img src={gold} className=" h-6 lg:h-12 mx-auto" />{' '}
              </th>
              <th
                className="py-2"
                style={{
                  borderWidth: '1px',

                  borderColor: 'rgb(209, 209, 209)',
                }}
              >
                <img src={silver} className="h-6 lg:h-12 mx-auto" />{' '}
              </th>
              <th
                className="py-2"
                style={{
                  borderWidth: '1px',

                  borderColor: 'rgb(209, 209, 209)',
                }}
              >
                <img src={bronze} className="h-6 lg:h-12 mx-auto" />{' '}
              </th>
              <th
                className="py-2"
                style={{
                  borderWidth: '1px',

                  borderColor: 'rgb(209, 209, 209)',
                }}
              >
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {rankingQuery.data.pages?.map((el) =>
              el.data.map((row, i) => {
                return (
                  <tr key={i} className="text-xs lg:text-base">
                    <td
                      className="py-2 font-medium"
                      style={{
                        borderWidth: '1px',
                        color: '#777',
                        borderColor: 'rgb(209, 209, 209)',
                      }}
                    >
                      {row.rank}
                    </td>
                    <td
                      className="py-2 font-medium"
                      style={{
                        borderWidth: '1px',
                        color: '#777',
                        borderColor: 'rgb(209, 209, 209)',
                      }}
                    >
                      {row?.institution?.name}
                    </td>
                    <td
                      className="py-2 font-medium"
                      style={{
                        borderWidth: '1px',
                        color: '#777',
                        borderColor: 'rgb(209, 209, 209)',
                      }}
                    >
                      {row?.points?.gold}
                    </td>
                    <td
                      className="py-2 font-medium"
                      style={{
                        borderWidth: '1px',
                        color: '#777',
                        borderColor: 'rgb(209, 209, 209)',
                      }}
                    >
                      {row?.points?.silver}
                    </td>
                    <td
                      className="py-2 font-medium"
                      style={{
                        borderWidth: '1px',
                        color: '#777',
                        borderColor: 'rgb(209, 209, 209)',
                      }}
                    >
                      {row?.points?.bronze}
                    </td>
                    <td
                      className="py-2 font-medium"
                      style={{
                        borderWidth: '1px',
                        color: '#777',
                        borderColor: 'rgb(209, 209, 209)',
                      }}
                    >
                      {row?.points?.points}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        <Link to={`/micro-site/${params?.tournament_id}/landing/results`}>
          <div className="underline flex justify-end w-full mt-2">View All</div>
        </Link>
      </div>
    </div>
  );
};

export default MedalTally;
