import { faFacebookF, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
// import { faRunning, faBasketballBall, faTableTennis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import khelologo from '../../assets/img/logo/TN_1.png';
import KheloIndiaLogo from '../../assets/img/logo/TN_2.png';

import { useState, useEffect } from 'react';
// import poweredBy from '../../assets/img/logo/powered-by-sfa.png';
import { Link } from 'react-router-dom';
// import { getMobileOperatingSystem } from 'utils/helpers';
// import { Capacitor } from '@capacitor/core';
// import { footerVenueLinks } from 'micro-site/Landing/Venues/data';
// import { useShowKhelo } from 'utils/HooksUtils';
// import { isKhelo } from '../../utils/kheloConditions';

const KheloFooter = () => {
  // const match = useRouteMatch();
  // const routes = {
  //   Home: 'overview',
  //   Sports: 'sports',
  //   Schedule: 'schedule',
  //   Venues: 'venues',
  //   'About Us': 'about-us',
  //   // 'Draws and Schedules': 'draws-schedules',
  //   // Attractions: 'attractions',
  //   // Gallery: 'gallery',feat: RM-1544 : footer

  //   // Feedback: 'feedback',
  //   // '"How To" Guide': 'gallery/%22How%20To%22%20Guide',
  // };
  // Hard coding for now not possible in current flow to get dynamic tournament id every where for a generic microsite
  const [currentImg, setCurrentImg] = useState(KheloIndiaLogo);
  // const isLg = useBreakPoint('lg');
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImg((currentImg) => (currentImg === KheloIndiaLogo ? khelologo : KheloIndiaLogo));
    }, 4000);
    return () => clearInterval(timer);
  }, [currentImg]);
  return (
    <div className="md:pt-0 pt-4 bg-gradient-to-r from-blue-NGGoaH1  to-blue-NGGoaH3">
      <div className="w-full h-full flex flex-col py-3 px-3 min-h-60 text-sm md:text-base max-w-screen-xl text-white items-center gap-4 md:gap-0 mx-auto">
        {/* <div className="w-full flex md:justify-start justify-center md:py-7 py-0">
          <div className="md:flex w-full justify-evenly items-start">
            <div className="flex w-full gap-4 justify-center flex-wrap px-2 md:px-48">
              {Object.keys(routes).map((route, i) => (
                <Link key={i} to={`/micro-site/${tournamentId}/landing/${routes[route]}`}>
                  <h1 className="text-orange-kheloOrange font-bold font-xs">{route}</h1>
                </Link>
              ))}
            </div>
          </div>
        </div> */}
        <div className="flex mb-2 pt-6">
          <Link
            className="text-white ignore-style flex flex-col gap-2 "
            // className="text-white ignore-style flex flex-col gap-2 border-r-2"
            to={`/`}
          >
            <img src={currentImg} className="h-14 md:h-20 mr-2" />
          </Link>
          {/* <Link
            className="text-white ignore-style flex flex-col gap-2"
            to={`/micro-site/${tournamentId}/landing/overview`}
          >
            <img src={poweredBy} className="h-14 md:h-20 ml-2" />
          </Link> */}
        </div>
        <div className="flex justify-center items-center gap-4 flex-col w-full h-full md:mt-2">
          <div className={`flex gap-8 md:mt-4 md:pt-0.5 justify-center`}>
            <a
              href="javascript:void(0)"
              // target={'_blank'}
              className="text-white ignore-style"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} className="h-4 md:h-6" />
            </a>
            <a
              href="javascript:void(0)"
              // target={'_blank'}
              className="text-white ignore-style"
              rel="noreferrer"
            >
              {' '}
              <FontAwesomeIcon icon={faFacebookF} className="h-4 md:h-6" />
            </a>
            <a
              href="javascript:void(0)"
              // target={'_blank'}
              rel="noreferrer"
              className="text-white ignore-style"
            >
              {' '}
              <FontAwesomeIcon icon={faYoutube} className="h-4 md:h-6" />
            </a>
            <a
              href="javascript:void(0)"
              // target={'_blank'}
              rel="noreferrer"
              className="text-white ignore-style"
            >
              {' '}
              <FontAwesomeIcon icon={faTwitter} className="h-4 md:h-6" />
            </a>
          </div>

          <div className="w-fit flex gap-3 text-xxs md:text-sm text-center md:text-left">
            <a
              className="text-white underline font-bold ignore-style"
              href="privacy-policy"
              // target="_blank"
              rel="noreferrer"
            >
              PRIVACY POLICY
            </a>
          </div>

          <div className="text-xxs md:text-sm text-center">
            <p>All rights reserved by Sports Development Authority of Tamil Nadu</p>
            <p>Powered by SFAPLAY.COM</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KheloFooter;
