import React from 'react';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import Landing from './Landing';
import 'index.css';
import Header from './Components/Header';
// import IndividualEvent from './Landing/Gallery/components/IndividualEvent';
// import SportEventList from './Landing/Sports/SportEventList';
import microSiteLogo from '../assets/img/logo/uttarakhand_logo.jpg';
import microSiteBg from '../assets/img/uttarakhand_desktop.jpg';
import Navbar from './Components/Navbar';
import DetailItemLoader from 'components/DetailItemLoader';
import { useTournamentDetails } from './queries/hooks';
import ScrollToTop from './Components/ScrollToTop';
// import FoldersInSection from './Landing/Gallery/components/FoldersInSection';
import Footer from './Components/Footer';
import { useShowKhelo } from 'utils/HooksUtils';
// import { isKhelo } from '../utils/kheloConditions';
import KheloHeader from './Components/KheloHeader/KheloHeader';
import KheloFooter from './Components/KheloFooter';
// import Embed from './Landing/Embed';
// import { MainPage, VenuePage } from './Landing/Venues';
import { useUserProfile } from 'profiles/queries/profileHooks';
// import { News, DetailedNews } from './Landing/News';
import ScrollToTopRouterHelper from 'ScrollToTopRouterHelper';
import CustomSwitch from 'components/routes/CustomSwitch';
// import { nanoid } from 'nanoid';

export default function MicroSite() {
  const match = useRouteMatch('');
  const tournamentDetailsQuery = useTournamentDetails({
    tournament_id: match.params.tournament_id,
  });
  const isKhelo = useShowKhelo();
  const userData = useUserProfile();
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <div className={'bg-white'}>
        {isKhelo ? (
          <KheloHeader fromMicroSite />
        ) : (
          <>
            <Navbar />
            <DetailItemLoader queryData={tournamentDetailsQuery} queryKey="id">
              {({ data }) => {
                return (
                  <Header
                    userData={userData}
                    eventName={data?.name}
                    city={data?.city}
                    eventImage={microSiteLogo}
                    banner={microSiteBg}
                    // price={data.price}
                    remarks={`This is one time tournament fees`}
                    // remarks={data.remarks}
                    startDate={data?.start_date}
                    endDate={data?.end_date}
                    hideOnRoutes={[
                      `${match.path}/landing/draws-schedules`,
                      `${match.path}/landing/sports/:sport`,
                    ]}
                    removePaddingOnRoutes={[`${match.path}/landing/overview`]}
                  />
                );
              }}
            </DetailItemLoader>
          </>
        )}
        <div className={'bg-white'}>
          <CustomSwitch>
            <Route exact path={`${match?.path}/landing`}>
              <Redirect to={`${match?.path}/landing/overview`} />
            </Route>{' '}
            {/* Default route */}
            <Route path={`${match?.path}/landing`} component={Landing} />
          </CustomSwitch>
          <ScrollToTop />
        </div>
      </div>
      {isKhelo ? <KheloFooter /> : <Footer />}
      <ScrollToTopRouterHelper />
    </div>
  );
}
