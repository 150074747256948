import { useDetailedNews } from 'micro-site/queries/hooks';
import { getDateInDateMonth, getDateInYear } from 'constants/DateFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import Loader from 'components/Loader';
// import Helmet from 'react-helmet';

const DetailedNews = () => {
  const params = useParams();
  const news = useDetailedNews({
    newsTitle: params.mediaId,
  });
  if (news.isFetching) return <Loader />;
  return (
    <div className="bg-gray-100 md:bg-white p-6 md:p-0">
      {/* <Helmet>
        <title>Latest News & Updates | KIYG Haryana 2021</title>
        <meta
          name="description"
          content="Check this page for LATEST NEWS & UPDATES from the #KIYG2021. Stay connected with the Games here through daily Match Records, Media Articles and lots more!"
        />
        <meta property="og:title" content="Latest News & Updates | KIYG Haryana 2021" />
        <meta
          property="og:description"
          content="Check this page for LATEST NEWS & UPDATES from the #KIYG2021. Stay connected with the Games here through daily Match Records, Media Articles and lots more!"
        />
      </Helmet> */}
      <div
        className="md:flex items-center cursor-pointer py-4 ml-8 hidden"
        onClick={() => history.back()}
      >
        <FontAwesomeIcon icon={faArrowLeft} className="fa-xs text-gray-750 mr-3" />
        <p className="text-gray-750 font-bold my-3 md:my-0 text-md font-montserrat">Media</p>
      </div>
      <div className="py-4 md:hidden">
        <div
          className="flex gap-2 md:gap-4 items-center text-gray-750 font-semibold cursor-pointer md:shadow-shadowBottom md:mb-6 md:pt-8 md:pb-2 pt-2"
          onClick={() => history.back()}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="fa-xs text-gray-750 mr-3" />
          <h3 className="text-lg md:text-2xl font-bold text-gray-750 font-poppins">Media</h3>
        </div>
        <hr className="w-16 md:hidden mx-0 my-0 md:w-60 bg-gray-750 h-0.5 md:h-1 mt-1 md:mt-6"></hr>
      </div>
      {news && news.data && (
        <div className="p-6 md:p-16 border-solid border-slate-700 rounded-lg border bg-white">
          <div className="w-9/10 md:w-full flex flex-col text-left lg:px-20">
            <div
              className="font-montserrat text-md md:text-3.5xl font-bold md:font-extrabold"
              style={{ color: '#3D3D3D' }}
            >
              <h1>{news?.data?.title}</h1>
            </div>
            <div className="md:flex md:justify-between mt-1 md:mt-2">
              <div className="font-poppins md:text-sm text-xs">
                {`${news?.data?.date ? getDateInDateMonth(news?.data?.date).split(' ')[0] : ''}th ${
                  news?.data?.date ? getDateInDateMonth(news?.data?.date).split(' ')[1] : ''
                }, ${news?.data?.date ? getDateInYear(news?.data?.date) : ''}`}
              </div>
              {/* <div className="flex mt-3 md:mt-0">
                        <a className="mr-3">
                           <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M4.82404 0H13.6791C16.1244 0 18.1067 1.90886 18.1067 4.26357V12.7907C18.1067 15.1454 16.1244 17.0543 13.6791 17.0543H4.82404C2.37877 17.0543 0.396484 15.1454 0.396484 12.7907V4.26357C0.396484 1.90886 2.37877 0 4.82404 0ZM12.5472 3.22717C12.5017 3.53424 12.5541 3.84784 12.6971 4.12338C12.8401 4.39892 13.0663 4.62237 13.3436 4.76193C13.6209 4.90149 13.9351 4.95007 14.2416 4.90076C14.5481 4.85144 14.8312 4.70674 15.0507 4.48723C15.2702 4.26773 15.4149 3.9846 15.4642 3.67812C15.5135 3.37164 15.4649 3.05741 15.3254 2.78012C15.1858 2.50284 14.9624 2.27663 14.6868 2.13365C14.4113 1.99068 14.0977 1.93823 13.7906 1.98377C13.4774 2.03021 13.1874 2.17617 12.9635 2.40007C12.7396 2.62397 12.5937 2.91395 12.5472 3.22717ZM12.8202 8.32592C12.9315 9.07653 12.8033 9.84313 12.4538 10.5167C12.1043 11.1902 11.5513 11.7364 10.8735 12.0776C10.1957 12.4187 9.42762 12.5375 8.67844 12.4169C7.92926 12.2964 7.23717 11.9426 6.70061 11.4061C6.16404 10.8695 5.81033 10.1774 5.68977 9.42825C5.56922 8.67907 5.68797 7.91095 6.02912 7.23315C6.37028 6.55535 6.91647 6.00237 7.59002 5.65289C8.26356 5.3034 9.03016 5.17519 9.78077 5.28649C10.5464 5.40003 11.2552 5.75681 11.8026 6.30412C12.3499 6.85144 12.7067 7.56027 12.8202 8.32592Z" fill="#0038FF"/>
                           </svg>
                        </a>
                        <a className="mr-3">
                           <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9.28951 0H6.78504C5.67799 0 4.61629 0.449196 3.83349 1.24877C3.05069 2.04834 2.61092 3.1328 2.61092 4.26357V6.82171H0.106445V10.2326H2.61092V17.0543H5.95022V10.2326H8.45469L9.28951 6.82171H5.95022V4.26357C5.95022 4.03741 6.03817 3.82052 6.19473 3.66061C6.35129 3.50069 6.56363 3.41085 6.78504 3.41085H9.28951V0Z" fill="#0038FF"/>
                           </svg>
                        </a>
                        <a className="mr-3">
                           <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M4.71759 2.21378C4.71759 3.43641 3.72645 4.42755 2.50381 4.42755C1.28118 4.42755 0.290039 3.43641 0.290039 2.21378C0.290039 0.991141 1.28118 0 2.50381 0C3.72645 0 4.71759 0.991141 4.71759 2.21378ZM12.8344 5.90332C14.2044 5.90332 15.5183 6.44156 16.487 7.39963C17.4557 8.3577 17.9999 9.65712 17.9999 11.012V16.9722H14.5562V11.012C14.5562 10.5604 14.3748 10.1273 14.0519 9.8079C13.729 9.48854 13.2911 9.30913 12.8344 9.30913C12.3778 9.30913 11.9398 9.48854 11.6169 9.8079C11.294 10.1273 11.1126 10.5604 11.1126 11.012V16.9722H7.66895V11.012C7.66895 9.65712 8.21316 8.3577 9.18188 7.39963C10.1506 6.44156 11.4645 5.90332 12.8344 5.90332ZM4.71765 6.64133H0.290096V16.9723H4.71765V6.64133Z" fill="#0038FF"/>
                           </svg>
                        </a>
                     </div> */}
            </div>
          </div>
          <div>
            <div className="flex justify-center my-10">
              <img
                className="md:h-96"
                style={{ width: '782px' }}
                src={news?.data?.cover_image}
              ></img>
            </div>
            <div
              className="md:text-smd text-sm font-montserrat leading-5 md:p-8 flex flex-col text-left justify-center md:border-solid md:border-slate-100 md:rounded md:border"
              style={{ color: '#606060' }}
            >
              <div
                className="whitespace-pre-wrap"
                dangerouslySetInnerHTML={{ __html: news?.data?.description }}
              ></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailedNews;
<div>Welcome to the news</div>;
