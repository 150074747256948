import React from 'react';
import PropTypes from 'prop-types';
import CardDetail from './HorizontalInformationDetail';
import { useShowKhelo } from 'utils/HooksUtils';

const HorizontalInformationCard = ({ len, title, content, index }) => {
  const isKhelo = useShowKhelo();
  return (
    <div className={`md:py-9 pt-4 md:pt-9 ${len - 1 === index ? '' : 'shadow-shadowBottom'}`}>
      <div className="mb-10">
        <div className={'md:mb-9'}>
          <h1
            className={`md:text-4xl text-lg font-bold font-poppins ${
              isKhelo ? 'text-blue-kheloBlue' : 'text-purple-500'
            }`}
          >
            {title}
          </h1>
          <div
            className={`w-16 h-0.5 mt-2 md:mt-3 md:h-2 ${
              isKhelo ? 'bg-blue-kheloBlue text-blue-kheloBlue' : 'bg-purple-500 text-purple-500'
            }`}
          />
        </div>
        <div className={'flex flex-col md:flex-row md:items-center'}>
          {content.map((item, index) => (
            <CardDetail key={index} index={index} subContent={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

HorizontalInformationCard.propTypes = {
  len: PropTypes.number,
  title: PropTypes.string,
  index: PropTypes.number,
  content: PropTypes.array,
};

export default HorizontalInformationCard;
