const Header = ({
  heading,
  bordercolor = '#FF9000',
  color = 'rgb(27, 49, 134)',
  borderWidth = '80%',
  align = 'center',
}) => {
  const alignment = {
    left: 'items-start',
    center: 'items-center',
    right: 'items-end',
  };
  return (
    <div className="inline-block">
      <div
        className={`flex flex-col text-2.5xl lg:text-3.5xl font-montserrat font-extrabold ${
          alignment[align] || 'center'
        }`}
      >
        <h1 style={{ color }}>{heading}</h1>
        <div
          className="hidden lg:block mt-3"
          style={{ height: '6px', width: borderWidth, backgroundColor: bordercolor }}
        ></div>
        <div
          className="block lg:hidden mt-1"
          style={{ height: '4px', width: borderWidth, backgroundColor: bordercolor }}
        ></div>
      </div>
    </div>
  );
};

export default Header;
// headingProps:{
//     heading:'Sports',
//     borderColor:'#fff',
//     color:'#fff',
